/* eslint-disable react-hooks/exhaustive-deps */
/**
 * DineInMiniCartProvider
 * Contain most logic mini cart
 */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useSkus, useOrderMethod } from 'react-omnitech-api'
import { useAlert } from '../use-alert'
import useCart from '../use-cart'
import { useLink } from '../use-link'
import useSku from '../use-sku'
import DineInMiniCartContext from './dine-in-mini-cart-context'
import usePriceTemplate from '../use-price-template'
import { trackEvent } from '../../helpers'

// TODO: get from config
const CART_INCLUDES = [
  // 'cart_lines',
  // 'cart_lines.price_details',
  // 'cart_lines.sku',
  'cart_line_properties',
  'cart_line_properties.color_option',
  'cart_line_properties.price_details',
  'cart_line_properties.sku',
  'cart_line_properties.product',
  'cart_line_properties.product_addon',

  'cart_line_properties.sku_id',
  'meta',
  'cart_shipments',
  'cart_shipments.inventory_store',
  'cart_shipments.pickup_store',

  'price_details',

  'products.color_option_variant_type',
  'products.size_option_variant_type',

  'skus.active_custom_labels',
  'skus.color_option',
  'skus.product',
  'skus.size_option',
  'skus.stock_level',
].join(',')

export default function DineInMiniCartProvider({ children }) {
  const CART_PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')
  // prepare hook
  const alert = useAlert()
  const { navigate } = useLink()
  const { isAllowToCheckoutFnb } = useSku()
  const { fetchSkus } = useSkus()
  const {
    fetchCart,
    stagingCart,
    resetStagingCart,
    totalStagingItems,
    updateCart,
    updateStagingCart,
  } = useCart()
  const {
    store,
  } = useOrderMethod()
  // internal state
  // const [cart, setCart] = useState({})
  const [loading, setLoading] = useState(false)
  const [miniCartOpen, setMiniCartOpen] = useState(false)
  const [skusUnavailableToCheckout, setSkusUnavailableToCheckout] = useState([])

  /**
   * apiFetchCart
   * get cart data from API
   */
  function apiFetchCart() {
    const options = {
      params: {
        includes: CART_INCLUDES,
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        refresh_cart: true,
      },
    }
    return fetchCart(options)
  }

  /**
   * apiUpdateCart
   * call API to update cart
   * @param {*} actions
   */
  function apiUpdateCart(actions) {
    const option = {
      payload: {
        data: {
          actions,
        },
      },
      params: {
        includes: CART_INCLUDES,
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        refreshCart: true,
      },
    }
    return updateCart(option)
  }

  /**
   * handleError
   * @param {*} error
   */
  function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    const batchActionError = _.find(error.batchActionErrors, 'message') || {}
    // if batch action is not provided, use the general error message
    const errorMessage = _.isEmpty(batchActionError.message)
      ? generalError.message
      : batchActionError.message
    alert.show(errorMessage)
  }

  /**
   * function handleCloseMiniCart() {
   * close mini cart
   */
  function handleCloseMiniCart() {
    setMiniCartOpen(false)
  }

  /**
   * function handleOpenMiniCart() {
   * open mini cart
   */
  function handleOpenMiniCart() {
    setLoading(true)
    setMiniCartOpen(true)
  }

  /**
   * function handleOpenPlaceOrderDialog() {
   * open place order dialog
   */
  function handleOpenPlaceOrderDialog() {
    // setLoading(true)
    // setMiniCartOpen(true)
  }

  /**
   * function handlePlaceOrder() {
   * Add staging cart items to dine in cart
   */
  function handlePlaceOrder() {
    const includes = [
      'cart_line_properties',
    ]
    const actions = _.map(
      _.get(stagingCart, 'actions', []),
      (action) => (
        _.pick(action, [
          'actionType',
          'groupUuid',
          'identifierUuid',
          'parentIdentifierUuid',
          'productAddonId',
          'quantity',
          'quantityMode',
          'skuId',
        ])
      ),
    )
    setLoading(true)
    return updateCart({
      payload: {
        data: { actions },
        batchUpdateMode: 2,
      },
      params: {
        includes,
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        priceStoreCode: _.get(store, 'code', null),
        refreshCart: true,
      },
    })
      .then(() => {
        resetStagingCart()
        navigate('/dine-in-order-placed/')
      })
      .catch((error) => {
        handleError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /**
   * handleRemoveCartLine
   */
  function handleRemoveCartLine(item) {
    const actions = [
      {
        ...item,
        quantity: 0,
      },
    ]
    updateStagingCart({ actions })
    // handleUpdateCart(actions)
    // onTrackRemoveFromCart(cart.id)
  }

  async function checkSkuAllowToCheckout(skuIds) {
    if (_.isEmpty(skuIds)) return
    try {
      const id = _.isArray(skuIds) ? _.join(skuIds, ',') : skuIds
      const option = {
        id,
        includes: [
          'skus.meta',
          'skus.stock_level',
        ].join(','),
      }
      // call api
      const { skus = [] } = await fetchSkus(option)
      setSkusUnavailableToCheckout(_.reject(skus, isAllowToCheckoutFnb))
    } catch (error) {
      handleError(error)
    }
    setLoading(false)
  }

  /**
   * check cartLines once mini cart is open
   */
  useEffect(() => {
    if (miniCartOpen && loading) {
      checkSkuAllowToCheckout(
        _.uniq(_.map(_.get(stagingCart, 'actions', []), 'skuId')),
      )
    }
  }, [miniCartOpen, loading, stagingCart])

  useEffect(() => {
    if (totalStagingItems > 0) return
    handleCloseMiniCart()
  }, [totalStagingItems])

  const state = {
    loading,
    miniCartOpen,
    totalItems: _.get(stagingCart, 'totalItems', 0),
    skusUnavailableToCheckout,
    openMiniCart: handleOpenMiniCart,
    closeMiniCart: handleCloseMiniCart,
    openPlaceOrderDialog: handleOpenPlaceOrderDialog,
    onRemoveCartLine: handleRemoveCartLine,
    onPlaceOrder: handlePlaceOrder,
  }

  return (
    <DineInMiniCartContext.Provider value={state}>
      {children}
    </DineInMiniCartContext.Provider>
  )
}
