import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  label: (props) => {
    const colors = {
      confirmed: theme.colors.confirm,
      disabled: theme.colors.disabled,
      error: theme.colors.alert,
      pending: theme.colors.active,
      reserved: theme.colors.active,
      completed: theme.colors.active,
      cancelled: theme.colors.alert,
      declined: theme.colors.alert,
      success: theme.colors.success,
    }
    const color = colors[props.state] || theme.colors.confirm
    return {
      paddingLeft: '0.9rem',
      position: 'relative',
      fontStyle: 'inherit',
      color,
      '&::before': {
        content: '""',
        display: 'block',
        width: '0.5rem',
        height: '0.5rem',
        border: '0.1rem solid currentColor',
        borderRadius: '50% 50%',
        position: 'absolute',
        left: 0,
        top: 7,
        backgroundColor: hexToRgba(color, 0.2),
      },
    }
  },
}))

export default useStyles
