import { useContext } from 'react'
import AlertContext from './alert-context'

/**
 * useAlert
 * The custom hook which provided to external use via context
 */
const useAlert = () => {
  const context = useContext(AlertContext)
  return context
}

export default useAlert
