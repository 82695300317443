/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
import _ from 'lodash'
import ReactGA from 'react-ga';
// RJ TODO: supported omnitech event type with customerButtonClick

let impressionTrackTimer

function trackEventGA(eventName, options, meta = {}) {
  // skip when ga lib is not existed
  if (_.isUndefined(window.ga)) {
    // CP: execute any callbacks before returning
    // (e.g. ga scripts not installed or blocked by adblockers)
    if (_.isFunction(meta.callback)) meta.callback()
    return
  }
  if (!ga.loaded) {
    // CP: execute any callbacks before returning
    // (e.g. ga blocked by adblockers)
    if (_.isFunction(meta.callback)) meta.callback()
    return
  }

  let title
  let order
  let products
  let product
  let orderItems
  let skus
  const gaCustomerProductClickCategory = 'UX';
  const gaCustomerProductClickLabel = 'Results'

  const customerCheckoutSteps = {
    customerCheckoutViewCart: 1,
    customerCheckoutDeliveryAddress: 2,
    customerCheckoutConfirm: 3,
  }

  // CP: Chunking is required since GA has a 8192 payload size restriction.
  // This function basically splits `obj` into chunks of length `size`
  // and timeout value of 200ms to avoid hitting GA's rate limit of
  // 10 requests per second.
  const chunkIt = (obj, size, fn) => {
    _.each(_.chunk(obj, size), (chunk) => {
      window.setTimeout(() => {
        fn(chunk)
      }, 200)
    })
  }

  switch (eventName) {
    case 'viewProductDetail':
      product = meta.product || {}
      title = meta.title || ''
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: _.isEmpty(meta.skuCode) ? product.code : meta.skuCode,
        name: product.title,
        price: meta.price,
        quantity: meta.quantity,
      })
      ReactGA.plugin.execute('ec', 'setAction', 'detail')
      ReactGA.set({ location: window.location.href.split('#')[0] });
      ReactGA.ga('send', 'pageview', { title });
      break
    case 'viewProductImpression':
      products = _.get(meta, 'products', [])
      title = _.get(meta, 'title', '')
      chunkIt(products, 10, (chunk) => {
        chunk.map((product) => {
          ReactGA.plugin.execute('ec', 'addImpression', {
            id: product.code,
            name: product.name,
            category: product.category,
            brand: product.brand,
            variant: product.variant,
            list: product.list,
            position: product.position,
          })
        })
        clearTimeout(impressionTrackTimer)
        impressionTrackTimer = setTimeout(() => {
          ReactGA.set({ location: window.location.href.split('#')[0] });
          ReactGA.ga('send', 'pageview', { title });
        }, 200)
      })
      if (_.isFunction(meta.callback)) meta.callback()
      break
    case 'customerAddToCart':
      // add to cart
      product = _.get(meta, 'product', {})
      title = _.get(meta, 'title', '')
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: meta.skuCode,
        name: product.title,
        price: meta.price,
        quantity: options.eventValue,
      });
      ReactGA.plugin.execute('ec', 'setAction', 'add')
      ReactGA.set({ location: window.location.href.split('#')[0] })
      ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart')
      ReactGA.ga('send', 'pageview', { title });
      break
    case 'customerRemoveFromCart':
      // add to cart
      product = _.get(meta, 'product', {})
      title = _.get(meta, 'title', '')
      ReactGA.plugin.execute('ec', 'removeFromCart', {
        id: meta.skuCode,
        name: meta.name,
        price: meta.price,
        quantity: meta.quantity,
      });
      ReactGA.plugin.execute('ec', 'setAction', 'remove')
      ReactGA.set({ location: window.location.href.split('#')[0] })
      ReactGA.ga('send', 'event', 'UX', 'click', 'remove to cart')
      ReactGA.ga('send', 'pageview', { title });
      break
    case 'customerButtonClick': {
      const category = meta.gaSendEventCategory || 'Button'
      const action = meta.gaSendEventAction || 'click';
      const label = meta.gaSendEventLabel || ''
      ReactGA.event({
        category,
        action,
        label,
      });
      ReactGA.ga('send', 'event', 'UX', 'click', 'Results', {
        hitCallback() {
          document.location = `${window.location.href.split('#')[0]}`
        },
      });
      break
    }
    case 'customerCheckoutViewCart':
    case 'customerCheckoutDeliveryAddress':
    case 'customerCheckoutConfirm': {
      const gaEcSetActionOptions = {
        step: customerCheckoutSteps[eventName],
        options,
      }
      const { cartLineProperties, title } = meta
      chunkIt(cartLineProperties, 10, (chunk) => {
        chunk.map((item) => {
          ReactGA.plugin.execute('ec', 'addProduct', {
            id: _.get(item, 'sku.code'),
            name: _.get(item, 'sku.product.title'),
            price: _.get(item, 'priceDetails.mainPropertyUnitPrice'),
            quantity: _.get(item, 'quantity'),
            variant: _.get(item, 'sku.colorOption.name', ''),
          })
        })
        ReactGA.plugin.execute('ec', 'setAction', 'checkout', gaEcSetActionOptions)
        clearTimeout(impressionTrackTimer)
        impressionTrackTimer = setTimeout(() => {
          ReactGA.set({ location: window.location.href.split('#')[0] });
          ReactGA.ga('send', 'pageview', { title });
        }, 200)
      })
      break
    }
    case 'customerCheckoutCompleteOrder': {
      order = meta.order || {}
      orderItems = meta.orderItems || []
      skus = meta.skus || []
      title = meta.title || ''
      chunkIt(orderItems, 10, (chunk) => {
        chunk.map((item) => {
          const sku = _.find(skus, { id: item.sku.id })
          ReactGA.plugin.execute('ec', 'addProduct', {
            id: _.get(sku, 'code'),
            name: _.get(sku, 'product.title'),
            price: _.get(item, 'priceDetails.propertyUnitPrice'),
            category: _.get(sku, 'product.categories[0].name', ''),
            brand: '',
            variant: _.get(sku, 'colorOption.name', ''),
            quantity: _.get(item, 'quantity'),
          })
        })
        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
          id: options.orderReferenceNumber,
          revenue: options.totalPrice,
          coupon: options.coupon,
        })
        clearTimeout(impressionTrackTimer)
        impressionTrackTimer = setTimeout(() => {
          ReactGA.set({ location: window.location.href.split('#')[0] });
          ReactGA.ga('send', 'pageview', { title });
        }, 200)
      })
      break
    }
    case 'customerProductClick':
      product = _.get(meta, 'product', {})
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: product.code, // Product details are provided in an impressionFieldObject.
        name: product.name,
        category: product.category, // product category
        brand: product.brand, // product brand
        variant: product.variant, // product color
        position: product.position, // 'position' indicates the product position in the list.
      })
      ReactGA.ga('ec:setAction', 'click', { list: product.list })
      if (_.isFunction(meta.callback)) {
        // Send click with an event, then send user to product page.
        ReactGA.ga('send', 'event', gaCustomerProductClickCategory, 'click', gaCustomerProductClickLabel, {
          hitCallback() {
            meta.callback()
          },
        });
      }
      break
    default:
      break;
  }
}

export default trackEventGA
