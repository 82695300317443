/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useSystemSettings, useSmsToken } from 'react-omnitech-api'
import SmsTokenButtonView from './sms-token-button-view'

function SmsTokenButtonController(props, ref) {
  const { getSystemSetting } = useSystemSettings()
  const smsResendTokenInterval = getSystemSetting('account.sms_token.resend_interval')
  const {
    sendSmsToken,
    smsTokenCountDown,
    smsTokenDisable,
    resetCounterSmsToken,
  } = useSmsToken(smsResendTokenInterval)

  const {
    phone,
    onSuccess,
    onError,
    ...rest
  } = props

  useImperativeHandle(ref, () => ({
    resetCounter: resetCounterSmsToken,
  }))

  useEffect(() => (() => {
    resetCounterSmsToken()
  }), [])

  const onClick = async () => {
    if (_.isEmpty(phone)) return
    try {
      await sendSmsToken(phone)
      if (_.isFunction(onSuccess)) onSuccess()
    } catch (error) {
      if (_.isFunction(onError)) onError(error)
    }
  }

  const viewProps = {
    smsTokenCountDown,
    smsTokenDisable,
    onClick,
    ...rest,
  }

  return (
    <SmsTokenButtonView {...viewProps} />
  )
}

export default forwardRef(SmsTokenButtonController)
