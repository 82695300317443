/* eslint-disable new-cap */
import moment from 'moment';

export default function generateTimeslots({
  start, // start time
  end, // end time
  interval, // in minutes
} = {}) {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');
  const timeslots = [];

  while (startTime <= endTime) {
    timeslots.push(new moment(startTime).format('HH:mm'));
    startTime.add(interval, 'minutes');
  }
  return timeslots;
}
