import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  linkStyle: {
    position: 'relative',
    width: 'calc(100% - 20px)',
    margin: '0 -20px',
    height: '49px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 2rem',
  },
}))

export default useStyles
