/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React from 'react'
import {
  useOrderMethod,
} from 'react-omnitech-api'
import {
  useDineInMiniCart,
  useMiniCart,
  useUser,
  useCouponMarketplace,
  useThemeConfig,
} from '../../hook'
import HeaderView from './header-view'

const Header = ({
  cartButtonDisabled,
  openMenuMobile,
  onLanguageChange,
  availableLocales,
  currentLocale,
  isOpenSearch,
  handelOpenSearch,
  onLayoutChange,
  ...viewProps
}) => {
  // prepare hook
  const { getConfig, getContentGroup } = useThemeConfig()
  const topBarContentGroupProps = {
    code: getContentGroup('config.ui.header.topBar.contentGroup'),
    template: getConfig('config.ui.header.topBar.template', 'ContentGroup'),
  }
  const {
    onCouponMarketplaceOpen,
  } = useCouponMarketplace()
  const {
    orderMethod,
  } = useOrderMethod()
  const {
    totalItems,
    openMiniCart,
  } = (_.get(orderMethod, 'commerceType') === 'dineIn' ? useDineInMiniCart : useMiniCart)()
  const { user = {} } = useUser()
  const { alias } = user
  const mergedCartButtonDisabled = totalItems === 0 || _.get(orderMethod, 'code') === 'dineInMenu' || cartButtonDisabled

  return (
    <HeaderView
      availableLocales={availableLocales}
      currentLocale={currentLocale}
      cartButtonDisabled={mergedCartButtonDisabled}
      cartTotalItem={totalItems}
      onOpenMiniCart={openMiniCart}
      topBarContentGroupProps={topBarContentGroupProps}
      userAlias={alias}
      isOpenSearch={isOpenSearch}
      onSearch={handelOpenSearch}
      onLanguageChange={onLanguageChange}
      openMenuMobile={openMenuMobile}
      onLayoutChange={onLayoutChange}
      onCouponMarketplaceOpen={onCouponMarketplaceOpen}
      {...viewProps}
    />
  )
}

export default Header
