import _ from 'lodash'

// we need to massage data due a two way to get oderLines/orderlinesProperties

function mapPriceDetailsPropertyOne(orderLineProperty) {
  const orderLines = _.map(orderLineProperty, (orderLine) => ({
    ...orderLine,
    priceDetails: {
      // ...orderLine.priceDetails,
      ..._.omit(orderLine.priceDetails, ['mainPropertySubtotalPrice', 'propertyUnitPrice']),
      orderLineSubtotalPrice: _.get(orderLine, 'priceDetails.mainPropertySubtotalPrice'),
      orderLineUnitPrice: _.get(orderLine, 'priceDetails.propertyUnitPrice'),
    },
  }))
  return orderLines
}

function mapPriceDetailsBackwrad(orderLines) {
  const orderLinesData = _.map(orderLines, (orderLine) => ({
    ...orderLine,
    priceDetails: {
      // ROKU: backward_compatible_line_v1 is order line with order cart
      //        -> use order line attribute like unit_price
      orderLineSubtotalPrice: _.get(orderLine, 'subtotal'),
      orderLineUnitPrice: _.get(orderLine, 'unitPrice'),
      orderLineTax: _.get(orderLine, 'taxAmount'),
      // ROKU: line_v1 is new cart with cart transaction -> use price template
      ..._.get(orderLine, 'priceDetails', {}),
      mainPropertyDiscountGroup: _.reject(_.get(orderLine, 'priceDetails.orderLineSubtotalPriceBreakdown', []), { targetType: 'Sku' }),
    },
    isMainProperty: true,
  }))
  return orderLinesData
}

export default function getOrderLines(order) {
  const shipmentsArray = _.get(order, 'shipments')
  const viewTemplate = _.get(order, 'viewTemplate')
  let shipments = {}
  switch (viewTemplate) {
    case 'property_v1':
      shipments = _.map(shipmentsArray, (shipment) => ({
        ...shipment,
        orderLines: mapPriceDetailsPropertyOne(_.get(shipment, 'orderLineProperties')),
      }))
      break;
    case 'backward_compatible_line_v1':
    case 'line_v1':
      shipments = _.map(shipmentsArray, (shipment) => ({
        ...shipment,
        orderLines: mapPriceDetailsBackwrad(_.get(shipment, 'orderLines')),
      }))
      break;
    default:
      shipments = shipmentsArray
      break;
  }

  return shipments
}
