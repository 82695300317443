import { useContext } from 'react'
import MobileMenuContext from './mobile-menu-context'

/**
 * useMobileMenu
 * The custom hook which provided to external use via context
 */
const useMobileMenu = () => {
  const context = useContext(MobileMenuContext)
  return context
};

export default useMobileMenu
