import _ from 'lodash'
import * as Yup from 'yup'
import i18next from 'i18next'

// TODO: customize error message with custom field label
export default function transformAddressFormValidationSchema(
  formConfig = {},
) {
  const yupShapeObj = {}

  // Personal Informaiton Fields
  yupShapeObj.firstName = Yup.string()
    .required(i18next.t('ui.addressForm.errors.firstName', { context: 'required' }))

  yupShapeObj.lastName = Yup.string()
    .required(i18next.t('ui.addressForm.errors.lastName', { context: 'required' }))

  yupShapeObj.phone = Yup.string()
    .required(i18next.t('ui.addressForm.errors.phone', { context: 'required' }))

  // Address Fields
  yupShapeObj.country = Yup.string()
  if (formConfig.countryRequired) {
    yupShapeObj.country = yupShapeObj.country
      .required(i18next.t('ui.addressForm.errors.country', {
        context: 'required',
        label: _.get(formConfig, 'labels.country'),
      }))
  }

  yupShapeObj.state = Yup.string()
  if (formConfig.stateRequired) {
    yupShapeObj.state = yupShapeObj.state
      .required(i18next.t('ui.addressForm.errors.state', {
        context: 'required',
        label: _.get(formConfig, 'labels.state'),
      }))
  }
  yupShapeObj.state = yupShapeObj.state
    .max(_.get(formConfig, 'fieldsMaxLength.state'), i18next.t('ui.addressForm.errors.state', {
      context: 'maxLength',
      count: _.get(formConfig, 'fieldsMaxLength.state'),
    }))

  yupShapeObj.city = Yup.string()
  if (formConfig.cityRequired) {
    yupShapeObj.city = yupShapeObj.city
      .required(i18next.t('ui.addressForm.errors.city', {
        context: 'required',
        label: _.get(formConfig, 'labels.city'),
      }))
  }
  yupShapeObj.city = yupShapeObj.city
    .max(_.get(formConfig, 'fieldsMaxLength.city'), i18next.t('ui.addressForm.errors.city', {
      context: 'maxLength',
      count: _.get(formConfig, 'fieldsMaxLength.city'),
    }))

  yupShapeObj.district = Yup.string()
  if (formConfig.districtRequired) {
    yupShapeObj.district = yupShapeObj.district
      .required(i18next.t('ui.addressForm.errors.district', {
        context: 'required',
        label: _.get(formConfig, 'labels.district'),
      }))
  }
  yupShapeObj.district = yupShapeObj.district
    .max(_.get(formConfig, 'fieldsMaxLength.district'), i18next.t('ui.addressForm.errors.district', {
      context: 'maxLength',
      count: _.get(formConfig, 'fieldsMaxLength.district'),
    }))

  yupShapeObj.streetLine1 = Yup.string()
  if (formConfig.streetLine1Required) {
    yupShapeObj.streetLine1 = yupShapeObj.streetLine1
      .required(i18next.t('ui.addressForm.errors.streetLine1', {
        context: 'required',
        label: _.get(formConfig, 'labels.streetLine1'),
      }))
  }
  yupShapeObj.streetLine1 = yupShapeObj.streetLine1
    .max(_.get(formConfig, 'fieldsMaxLength.streetLine1'), i18next.t('ui.addressForm.errors.streetLine1', {
      context: 'maxLength',
      count: _.get(formConfig, 'fieldsMaxLength.streetLine1'),
    }))

  yupShapeObj.streetLine2 = Yup.string()
  if (formConfig.streetLine2Required) {
    yupShapeObj.streetLine2 = yupShapeObj.streetLine2
      .required(i18next.t('ui.addressForm.errors.streetLine2', {
        context: 'required',
        label: _.get(formConfig, 'labels.streetLine2'),
      }))
  }
  yupShapeObj.streetLine2 = yupShapeObj.streetLine2
    .max(_.get(formConfig, 'fieldsMaxLength.streetLine2'), i18next.t('ui.addressForm.errors.streetLine2', {
      context: 'maxLength',
      count: _.get(formConfig, 'fieldsMaxLength.streetLine2'),
    }))

  // zip_regex
  yupShapeObj.zip = Yup.string()
  if (formConfig.zipRequired) {
    yupShapeObj.zip = yupShapeObj.zip
      .required(i18next.t('ui.addressForm.errors.zip', {
        context: 'required',
        label: _.get(formConfig, 'labels.zip'),
      }))
  }
  yupShapeObj.zip = yupShapeObj.zip
    .max(_.get(formConfig, 'fieldsMaxLength.zip'), i18next.t('ui.addressForm.errors.zip', {
      context: 'maxLength',
      count: _.get(formConfig, 'fieldsMaxLength.zip'),
    }))

  if (!_.isNull(formConfig.zipRegex)) {
    const re = new RegExp(formConfig.zipRegex)
    yupShapeObj.zip = yupShapeObj.zip
      .matches(
        re,
        i18next.t('ui.addressForm.errors.zip', {
          context: 'pattern',
          label: _.get(formConfig, 'labels.zip'),
        }),
      )
  }

  return Yup.object().shape(yupShapeObj)
}
