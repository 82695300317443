/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    'body.modal-open': {
      overflow: 'hidden',
      height: '100vh',
      width: '100%',
      position: 'fixed',
    },
  },
  overlap: ({ isCouponMarketplaceOpen }) => ({
    position: 'fixed',
    display: isCouponMarketplaceOpen ? 'block' : 'none',
    zIndex: 99,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.7)',
  }),
  miniCard: {
    position: 'absolute',
    bottom: '0',
    zIndex: '100',
    right: '0',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.colors.background,
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    '&.mini-cart-enter': {
      bottom: '-100vh',
      top: '100vh',

    },
    '&.mini-cart-enter-active': {
      bottom: 0,
      top: 0,
      transition: 'all 300ms',
    },
    '&.mini-cart-enter-done': {
      bottom: 0,
      top: 0,
    },
    '&.mini-cart-exit': {
      bottom: 0,
      top: 0,
    },
    '&.mini-cart-exit-active': {
      bottom: '-100vh',
      top: '100vh',
      transition: 'all 300ms',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      position: 'fixed',
      top: 0,
      zIndex: '100',
      right: '0',
      height: '100vh',
      width: '50rem',
      backgroundColor: theme.colors.background,
      // padding: '3rem 2.1rem 4rem 3rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      '&.mini-cart-enter': {
        right: '-50rem',
      },
      '&.mini-cart-enter-active': {
        right: '0',
        transition: 'right 300ms',
      },
      '&.mini-cart-enter-done': {
        right: '0',
      },
      '&.mini-cart-exit': {
        right: '0',
      },
      '&.mini-cart-exit-active': {
        right: '-50rem',
        transition: 'right 300ms',
      },
    },
  },
  popup: {
    color: theme.colors.background,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 335,
    height: 100,
    borderRadius: 5,
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    '& span': {
      fontSize: '2rem',
      fontWeight: 600,
    },
  },
}))

export default useStyles
