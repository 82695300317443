/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash'
import React, {
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'
import Search from '../search'
import Link from '../link'
import Alert from '../alert'
import ContentGroup from '../content-group'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './header-style'
import TofugearLogo from '../../assets/icons/tofugear-logo.svg'
import ArrowDownIconInline from '../../assets/icons/icon_dropdown_arrow.inline.svg'
import CartIconInline from '../../assets/icons/icon_cart.inline.svg'
import SearchIconInline from '../../assets/icons/icon_search.inline.svg'
import AccountIconInline from '../../assets/icons/icon_account.inline.svg'
import MenuIconInline from '../../assets/icons/icon_menu.inline.svg'
import DropdownMenu from './dropdown-menu/dropdown-menu-view'
import NavBar from '../navbar'
import Button from '../button'

const HeaderView = ({
  availableLocales,
  cartTotalItem,
  cartButtonDisabled,
  currentLocale,
  onOpenMiniCart,
  onSearch,
  onLanguageChange,
  onLayoutChange,
  isOpenSearch,
  openMenuMobile,
  className,
  onCouponMarketplaceOpen,
  onMemberCodeOpen,
  qrCode,
  breakpoints,
  topBarContentGroupProps,
}) => {
  // prepare hook
  const { t, i18n } = useTranslation()
  const headerRef = useRef()
  const theme = useTheme()
  const siteLogo = breakpoints.sm
    ? theme.config.logoImageMobile || TofugearLogo : theme.config.logoImageDesktop || TofugearLogo

  // local variagne
  const currentLocaleCode = _.get(currentLocale, 'path', '')
  const isLanguageEnabled = availableLocales.length > 1
  const {
    addEventListener,
    removeEventListener,
  } = window || {}

  // style
  const styles = useStyles({ siteLogo })
  const handleResize = () => {
    const headerElem = _.get(headerRef, 'current')
    if (headerElem) {
      const rect = headerElem.getBoundingClientRect() || {}
      onLayoutChange(rect)
    }
  }

  useLayoutEffect(handleResize)

  useEffect(() => {
    addEventListener('resize', _.throttle(handleResize, 50))
    return () => {
      removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <header
      ref={headerRef}
      className={combineClassNames([styles.containerFluid, className])}
    >
      {
        topBarContentGroupProps.code && (
          <div className={styles.topBar}>
            <ContentGroup
              {...topBarContentGroupProps}
              lineStyle={styles.topBarContent}
            />
          </div>
        )
      }
      <div className={styles.container}>
        <Button
          inline
          className={styles.boxMenuMobile}
          onClick={openMenuMobile}
          iconImage={MenuIconInline}
          iconInline
          iconClassName={styles.menuIcon}
        />
        <h1 className={styles.logo}>
          <Link to="/" className={styles.logoImage} />
        </h1>
        <div className={styles.boxLogin}>
          <ul className={styles.boxLoginList}>
            <li
              className={combineClassNames([
                styles.boxLoginItem,
                isLanguageEnabled && styles.languageMemuAvailable,
              ])}
            >
              {
                isLanguageEnabled && (
                  <>
                    {t('locale', { context: currentLocaleCode })}
                    <ArrowDownIconInline className={styles.languageMemuAvailableIcon} />
                    <div className={styles.languageList}>
                      {
                      _.map(availableLocales, (availableLocale) => (
                        <button
                          key={`language_item_${availableLocale}`}
                          className={combineClassNames([
                            styles.languageListItem,
                            currentLocaleCode === availableLocale && styles.currentLanguage,
                          ])}
                          type="button"
                          onClick={onLanguageChange.bind(this, availableLocale)}
                        >
                          {t('locale', { context: availableLocale })}
                        </button>
                      ))
                    }
                    </div>
                  </>
                )
              }
            </li>
            <li className={combineClassNames([styles.boxLoginItem, styles.dropdownMenu])}>
              <Button
                inline
                className={styles.searchIconButton}
                iconInline
                iconImage={AccountIconInline}
                iconClassName={styles.searchIcon}
              />
              <DropdownMenu
                stylesDropdown={styles.containerDropdown}
                onCouponMarketplaceOpen={onCouponMarketplaceOpen}
                onMemberCodeOpen={onMemberCodeOpen}
                qrCode={qrCode}
              />
            </li>
            <li className={combineClassNames([styles.boxLoginItem, styles.cart])}>
              <Button
                inline
                className={combineClassNames([
                  styles.cartIconButton,
                  cartButtonDisabled && styles.cartIconButtonDisabled,
                ])}
                disabled={cartButtonDisabled}
                onClick={onOpenMiniCart}
                iconInline
                iconImage={CartIconInline}
                iconClassName={
                  combineClassNames([
                    styles.cartIcon,
                    cartButtonDisabled && styles.cartIconDisabled,
                  ])
                }
                data-count={cartTotalItem >= 100 ? '99+' : cartTotalItem}
              />
            </li>
            <li
              className={combineClassNames([styles.boxLoginItem, styles.search])}
            >
              <Button
                inline
                className={styles.searchIconButton}
                onClick={onSearch}
                iconInline
                iconImage={SearchIconInline}
                iconClassName={styles.searchIcon}
              />
            </li>
          </ul>
        </div>
      </div>
      <Search locale={i18n.language} isOpenSearch={isOpenSearch} className={styles.searchBar} />
      <NavBar />
      <Alert />
    </header>
  )
}

export default withBreakpoints(HeaderView)
