import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  addressCard: {
    minHeight: '26rem',
    display: 'flex',
    marginBottom: '3rem',
    alignItems: 'center',
  },
  boxInput: {
    display: 'flex',
    minWidth: '3rem',
    padding: '0 2rem 0 0.5rem',
    alignItems: 'center',
  },
  contentAddress: {
    '& p': {
      marginBottom: 0,
      lineHeight: 1.43,
      fontSize: '1.4rem',
    },
  },
  button: {
    margin: 0,
    width: 'auto',
    marginRight: '2rem',
    padding: '0',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    '&:last-child': {
      margin: 0,
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    addressCardStyle: {
    },
  },
}))

export default useStyles
