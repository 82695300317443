/* eslint-disable import/no-cycle */
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useContentGroups } from 'react-omnitech-api'
import ContentGroupView from './content-group-view'

const ContentGroupController = (props) => {
  const {
    code,
    fallback = null,
    id,
    template,
  } = props

  // prepare omnitech api hook
  const { fetchContentGroupByCode, fetchContentGroup } = useContentGroups()

  // internal states
  const [contentGroup, setContentGroup] = useState({})
  const [ready, setReady] = useState(false)
  const [contentGroupNotFound, setContentGroupNotFound] = useState(false)

  /**
   * getContentGroup
   * get home page content group data from API
   */

  const getContentGroupByCode = useCallback(async () => {
    try {
      // api call option
      const option = {
        code,
        includes: [
          'content_group_lines',
        ],
      }
      const { contentGroup: data } = await fetchContentGroupByCode(option)
      setContentGroup(data)

      setReady(true)
    } catch (error) {
      // when fail to load content group, what should be shown?
      setContentGroupNotFound(true)
      console.warn('fetchContentGroupByCode error: ', error)
    }
  }, [fetchContentGroupByCode, code])

  const getContentGroupById = useCallback(async () => {
    try {
      // api call option
      const option = {
        id,
        includes: [
          'content_group_lines',
        ],
      }
      const { contentGroup: data } = await fetchContentGroup(option)
      setContentGroup(data)
      setReady(true)
    } catch (error) {
      // when fail to load content group, what should be shown?
      setContentGroupNotFound(true)
      console.warn('fetchContentGroup error: ', error)
    }
  }, [fetchContentGroup, id])

  /**
   * load home page content group when page loaded
   */

  useEffect(() => {
    // Prevent Product group use content group endpoint
    if (template === 'ProductGroup') {
      setReady(true)
      return
    }
    if (_.isNumber(id)) {
      getContentGroupById()
    } else {
      getContentGroupByCode()
    }
    // TODO Ramon J 01/04/2021 - relate coda-1254
    // Can not clean as Main Menu and many on
    // the footer is call the same time, and cancel one of then
    // return function getContentGroupCleanUp() {
    //   cancelRequest.cancelAll([
    //     'fetchContentGroup',
    //     'fetchContentGroupByCode',
    //   ])
    // }
  }, [getContentGroupByCode, getContentGroupById, id, template])

  const viewProps = {
    ...props,
    contentGroup,
    ready,
  }

  return (
    contentGroupNotFound
      ? fallback
      : <ContentGroupView {...viewProps} />
  )
}

export default ContentGroupController
