import React from 'react'
import useStyles from './coupon-month-block-style'
import combineClassNames from '../../helpers/combineClassNames'

const CouponMonthBlockView = ({
  children,
  miniCartCoupon,
  sectionHeaderText,
}) => {
  // styles
  const styles = useStyles()
  const sectionHeaderStyle = miniCartCoupon ? styles.expire : styles.month

  return (
    <div className={styles.container}>
      <div
        className={
          combineClassNames([
            styles.titleContainer,
            miniCartCoupon && styles.titleMinicart,
          ])
        }
      >
        <p className={sectionHeaderStyle}>{sectionHeaderText}</p>
      </div>
      <div className={miniCartCoupon && styles.contentCointainer}>
        {children}
      </div>
    </div>
  )
}

export default CouponMonthBlockView
