import React from 'react'
import Carousel from '../../carousel'
import useStyles from './content-group-style'

function TemplateBanner3Col(props) {
  const { contentGroup, onClickTrackEvent, template } = props
  const { contentGroupLines } = contentGroup
  const { row, col, imageBoxThreeImage } = useStyles()
  const onClick = (label) => {
    onClickTrackEvent(template, label)
  }
  return (
    <div className={row}>
      <div className={col}>
        <Carousel
          autoplay
          images={contentGroupLines}
          slidesToShowDesktop={3}
          slidesToShowMovil={1}
          imageBoxClassName={imageBoxThreeImage}
          marginCarousel="0"
          marginCarouselDesktop="0"
          postionDots={-15}
          darkDots
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default TemplateBanner3Col
