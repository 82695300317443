/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from 'react-omnitech-api'

const WithAuth = ({ isAuth, isNotAuth }) => {
  const { auth } = useAuth()

  const WithAuthComponent = useMemo(() => (
    auth.userId ? isAuth : isNotAuth), [isAuth, isNotAuth, auth])
  return (
    <WithAuthComponent />
  )
}

WithAuth.propTypes = {
  isAuth: PropTypes.any.isRequired,
  isNotAuth: PropTypes.any.isRequired,

}

export default WithAuth
