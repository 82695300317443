import { createUseStyles } from 'react-jss'
import Color from 'color';

const useStyles = createUseStyles((theme) => ({
  couponCard: {
    position: 'relative',
    border: `3px solid ${Color(theme.colors.bgMediumDark).alpha(0.6).string()}`,
    padding: '1.6rem',
    margin: '1rem 0',
    '& p': {
      marginBottom: 0,
      lineHeight: 1.45,
      fontSize: '1.4rem',
      '&:first-child': {
        fontSize: '1.6rem',
        fontWeight: 600,
      },
      '&:nth-child(3)': {
        color: theme.colors.alert,
      },
    },
    '& img': {
      margin: 0,
      position: 'absolute',
      right: '1.6rem',
      top: '1.6rem',
    },
  },
  couponCardError: {
    border: `3px solid ${theme.colors.alert}`,
  },
  errorMessage: {
    fontSize: '1.2rem',
    color: theme.colors.alert,
    lineHeight: 1,
    marginBottom: '2rem',
  },
}))

export default useStyles
