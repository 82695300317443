/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { Field, useFormikContext } from 'formik'
import { isSelectable } from '../../helpers'
import Input from '../input'
import InputPhone from '../input-phone'
import Dropdown from '../dropdown'
import { customStylesOnlyText } from '../dropdown/custom-styles'
import useStyles from './address-form-style'

// Example only, should be override in theme project
export default function AddressFormField(props) {
  const {
    formik,
    hide,
    label,
    name,
    options,
    selectableFields,
    type,
    placeholder,
    onChange,
    widthDropdown,
    required,
    onGetPlaceholder,
    countryCallingCodeOptions,
    ...otherProps
  } = props
  const {
    values,
  } = useFormikContext()
  const isSelectableFields = isSelectable(selectableFields, name)
  const disabled = useMemo(() => _.isEmpty(options), [options]) || _.get(props, 'disabled', false)

  const handleDropdownChange = ({ value }) => {
    onChange({ name, value })
  }

  const handleInputChange = (e) => {
    const value = _.get(e, 'target.value', e)
    onChange({ name, value })
  }

  const handlePhoneChange = ({ countryCallingCode, localPhoneNumber }) => {
    const phone = !_.isEmpty(countryCallingCode) && !_.isEmpty(localPhoneNumber)
      ? `${countryCallingCode}${localPhoneNumber}`
      : ''
    onChange({ name: 'phone', value: phone })
  }

  const phoneDefaultValue = useMemo(() => {
    const countryCallingCode = _.get(values, 'phoneData.countryCallingCode') || _.get(_.head(countryCallingCodeOptions), 'value')
    const phone = _.get(values, 'phone', '') || ''
    const localPhoneNumber = phone.replace(/^\+/, '').replace(countryCallingCode, '').replace(/[ \-()]/g, '')
    return {
      countryCallingCode,
      localPhoneNumber,
    }
  }, [countryCallingCodeOptions])

  const styles = useStyles()

  // const InputComponent = Input
  // when the field should be hide base on country, does not render the field
  if (hide) {
    return null
  }
  return isSelectableFields ? (
    <Field
      as={Dropdown}
      className={styles.dropdownStyle}
      disabled={disabled}
      name={name}
      type={type}
      label={label}
      onChange={handleDropdownChange}
      options={options}
      widthDropdown={widthDropdown}
      placeholder={_.startCase(_.toLower(placeholder)) || ''}
      customStyles={customStylesOnlyText}
    />
  ) : name === 'phone'
    ? (
      <InputPhone
        {...props}
        defaultValue={phoneDefaultValue}
        onChange={handlePhoneChange}
        className={styles.inputPhone}
        labelClassName={styles.inputPhoneLabel}
      />
    )
    : (
      <Input
        name={name}
        type={type}
        label={label}
        required={required}
        onChange={handleInputChange}
        uppercase
        formik
        {...otherProps}
      />
    )
}
