/**
 * Create modal context for custom hook
 */
import React, { createContext } from 'react'

const ModalContext = createContext({
  isOpen: false,
  title: '',
  message: '',
  contentComponent: <></>,
  buttons: [],
  open: () => {},
  close: () => {},
})

export default ModalContext
