import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'block',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  zoom: {
    touchAction: 'none',
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
    background: 'transparent',
    border: 'none',
    padding: 0,
    height: '6rem',
    margin: 0,
    '& img': {
      margin: 0,
      transform: 'scale(0.75) translate(10px, -10px)',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    button: {
      '& img': {
        transform: 'scale(1) translate(0,0)',
      },
    },
  },
  imageWrapper: (props) => ({
    width: '100%',
    overflow: 'hidden',
    transform: `scale(${props.scale})`,
    transition: 'transform 0.3s',
  }),
  image: (props) => ({
    // pointerEvents: 'none',
    userSelect: 'none',
    userDrag: 'none',
    width: '100%',
    transform: `translate(${props.x}px, ${props.y}px)`,
  }),
  panEnd: () => ({
    transition: 'transform 0.3s',
  }),
}))

export default useStyles
