/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import Dropdown from '../dropdown'
import Loading from '../loading'
import { customStylesOnlyText } from '../dropdown/custom-styles'
import Modal from '../modal'
import AddressManager from '../address-manager'
import useStyles from './order-method-modal-style'
import Cross from '../../assets/icons/icon_cross.svg'

export default function OrderMethodModalView({
  confirmButtonDisabled,
  deliveryAddress,
  deliveryAddresses,
  deliveryAddressesLoading,
  handleDeleteAddress,
  isAddressManagerOpen,
  isBlocker,
  isChangeStoreDialogOpen,
  isLoggedIn,
  isNoStoreDialogOpen,
  isOrderMethodDialogOpen,
  onAddressManagerRequestClose,
  onAddressSelectorClick,
  onChange,
  onChangeStoreConfirm,
  onChangeStoreDialogRequestClose,
  onConfirm,
  onFetchDeliveryAddresses,
  onNoStoreDialogRequestClose,
  onRequestClose,
  onSelectDeliveryAddress,
  onStoreChange,
  onUpdateDeliveryAddress,
  orderMethod,
  orderMethodOptions,
  selectedItem,
  selectedStoreItem,
  showAddressSelector,
  storeOptions,
  storesLoading,
  themeConfig,
  updateOrderMethodInProgress,
}) {
  const { t } = useTranslation()

  const orderMethodBackgroundUrls = useMemo(() => (
    _.get(themeConfig, 'config.orderMethodBackgroundUrls', {})
  ), [themeConfig])
  const styles = useStyles({ orderMethodBackgroundUrls })

  return (
    <>
      <Modal
        dialog
        isOpen={isOrderMethodDialogOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={!isBlocker}
        shouldCloseOnEsc={!isBlocker}
        overlayClassName={styles.overlay}
      >
        <div className={styles.dialogContainer}>
          {
            !isBlocker && (
              <Button
                className={styles.closeButton}
                iconClassName={styles.closeButtonIcon}
                inline
                iconImage={Cross}
                onClick={onRequestClose}
              />
            )
          }
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('ui.orderMethodModal.title')}</h1>
            {
              _.size(orderMethodOptions) > 1 && (
                <Dropdown
                  className={styles.dropdownStyle}
                  customStyles={customStylesOnlyText}
                  options={orderMethodOptions}
                  values={selectedItem}
                  onChange={onChange}
                />
              )
            }
            <div className={styles.dropdownContainer}>
              {
                showAddressSelector
                  ? (
                    <>
                      <Dropdown
                        className={styles.dropdownStyle}
                        customStyles={customStylesOnlyText}
                        options={[]}
                        values={!_.isEmpty(deliveryAddress) && {
                          label: _.get(deliveryAddress, 'name') || _.join(_.get(deliveryAddress, 'formattedAddressLines', []), ', '),
                          value: _.get(deliveryAddress, 'id'),
                        }}
                        placeholder={t(
                          'ui.orderMethodModal.placeholder',
                          {
                            context: isLoggedIn
                              ? _.get(selectedItem, 'value')
                              : 'loginRequired',
                          },
                        )}
                      />
                      <button
                        className={styles.addressSelectorTrigger}
                        onClick={onAddressSelectorClick}
                        type="button"
                      >
                        Select Delivery Address
                      </button>
                    </>
                  )
                  : (
                    <Dropdown
                      className={styles.dropdownStyle}
                      customStyles={customStylesOnlyText}
                      options={storeOptions}
                      values={selectedStoreItem}
                      onChange={onStoreChange}
                      placeholder={
                        t('ui.orderMethodModal.placeholder', {
                          context: storesLoading ? 'loading' : _.get(selectedItem, 'value'),
                        })
                      }
                    />
                  )
              }
            </div>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.buttons.confirm', { context: _.get(selectedItem, 'value') })}
              onClick={onConfirm}
              disabled={confirmButtonDisabled || updateOrderMethodInProgress}
            />
          </div>
        </div>
      </Modal>
      <Modal
        dialog
        isOpen={isChangeStoreDialogOpen}
        onRequestClose={onChangeStoreDialogRequestClose}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('ui.orderMethodModal.modalDialog.changeStore.title')}</h1>
            <p className={styles.dialogDescription}>{t('ui.orderMethodModal.modalDialog.changeStore.description')}</p>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.modalDialog.changeStore.buttons.confirm')}
              onClick={onChangeStoreConfirm}
            />
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.modalDialog.changeStore.buttons.dismiss')}
              onClick={onChangeStoreDialogRequestClose}
            />
          </div>
        </div>
      </Modal>
      <Modal
        dialog
        isOpen={isNoStoreDialogOpen}
        onRequestClose={onNoStoreDialogRequestClose}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('ui.orderMethodModal.modalDialog.noStore.title')}</h1>
            <p className={styles.dialogDescription}>{t('ui.orderMethodModal.modalDialog.noStore.description')}</p>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.modalDialog.noStore.buttons.dismiss')}
              onClick={onNoStoreDialogRequestClose}
            />
          </div>
        </div>
      </Modal>
      <Modal
        dialog
        innerScroll
        isOpen={isAddressManagerOpen}
        onRequestClose={onAddressManagerRequestClose}
        style={{
          content: {
            position: 'relative',
            minHeight: '15rem',
            paddingTop: 0,
          },
        }}
      >
        {
          deliveryAddressesLoading && <Loading />
        }
        {
          !deliveryAddressesLoading && (
            <Button
              className={styles.closeButton}
              iconClassName={styles.closeButtonIcon}
              inline
              iconImage={Cross}
              onClick={onAddressManagerRequestClose}
            />
          )
        }
        <AddressManager
          addresses={deliveryAddresses}
          addressSelectable
          addressType="delivery"
          closeAfterSelectAddress
          defaultAddress={
            _.find(deliveryAddresses, { isPrimary: true })
            || _.first(deliveryAddresses)
          }
          onAddress
          onClose={onAddressManagerRequestClose}
          onDeleteAddress={handleDeleteAddress}
          onFetchDeliveryAddresses={onFetchDeliveryAddresses}
          onSelectAddress={onSelectDeliveryAddress}
          onUpdateDeliveryAddress={onUpdateDeliveryAddress}
          selectedAddress={_.find(deliveryAddresses, { id: _.get(deliveryAddress, 'id') })}
        />

      </Modal>
    </>
  )
}
