/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import _ from 'lodash'
import { useTheme } from 'react-jss'
import { Trans } from 'react-i18next'
import useStyles from './coupon-card-style'

import { combineClassNames, removeHtmlTag } from '../../helpers'
import IconTime from '../../assets/icons/icon_time.svg'
import placeholderImage from '../../assets/images/icon_image_placeholder.png'

const CouponCardView = (props) => {
  const {
    type,
    miniCartCoupon,
    id,
    displayImage,
    imageUrl,
    title,
    description,
    displayTimeLabel,
    displayTime,
    state,
    stateLabel,
    disabled,
    requiredPoints,
    onSelect,
  } = props
  // prepare hook
  const theme = useTheme()

  const descriptionText = removeHtmlTag(description)
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  const noImage = _.isEmpty(imageUrl)
  const couponImageUrl = imageUrl || placeholder

  // style
  const styles = useStyles({ displayImage, miniCartCoupon })

  function classStatus(couponStatus) {
    switch (couponStatus) {
      case 'success':
        return styles.add
      case 'error':
        return styles.redeem
      default:
        return styles.used
    }
  }

  function handleSelect() {
    if (_.isFunction(onSelect) && !disabled) {
      onSelect({ id, type })
    }
  }

  return (
    <div
      className={combineClassNames([styles.couponCard,
        miniCartCoupon && styles.couponCardMinicart,
        (!disabled && _.isFunction(onSelect)) && styles.selectable,
        disabled && styles.disabled,
      ])}
      onClick={handleSelect}
    >
      <div className={styles.couponContent}>
        {
          displayImage && (
            <div className={combineClassNames([styles.couponImage, noImage && styles.noImage])}>
              <div>
                <img src={couponImageUrl} alt="" />
              </div>
            </div>
          )
        }
        <div className={styles.coupontText}>
          <div className={styles.titleBox}>
            {
              !_.isEmpty(title) && (
                <p className={combineClassNames([styles.titleStyle,
                  miniCartCoupon && styles.titleStyleMinicart,
                ])}
                >
                  {title}
                </p>
              )
            }
            {
              !_.isEmpty(stateLabel) && (
                <p className={classStatus(state)}>
                  {stateLabel}
                </p>
              )
            }
          </div>

          { description && <p className={styles.clauseStyle}>{descriptionText}</p> }
        </div>
      </div>
      <div className={styles.couponCardFooter}>
        <div
          className={
            combineClassNames([
              styles.couponCardFooterColumn,
              styles.couponCardFooterDateColumn,
            ])
          }
        >
          {
            displayTimeLabel && (
              <div className={styles.dateLabel}>{displayTimeLabel}</div>
            )
          }
          <div className={styles.couponCardFooterDate}>
            <img src={IconTime} alt="icon time" />
            {' '}
            <span>{displayTime}</span>
          </div>
        </div>
        {
          !_.isUndefined(requiredPoints) && (
            <div
              className={
                combineClassNames([
                  styles.couponCardFooterColumn,
                  styles.couponCardFooterPointColumn,
                ])
              }
            >
              <span className={styles.points}>
                <Trans
                  i18nKey="ui.couponCard.pointToRedeem"
                  values={{ points: requiredPoints }}
                />
              </span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CouponCardView
