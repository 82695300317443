import i18n from 'i18next'

export default () => {
  i18n.init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en-HK',
    keySeparator: '.', // we use keys in format messages.welcome
    contextSeparator: '__',
    pluralSeparator: '__',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { useSuspense: false },
    resources: {},
  })

  return i18n
}
