/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import useStyles from './currency-format-style'

const CurrencyFormatView = (props) => {
  const {
    value, currency, dark, size, ...others
  } = props
  const { currencyStyle } = useStyles({ dark, size })
  const symbol = _.get(currency, 'formatData.disambiguateSymbol') || _.get(currency, 'formatData.symbol')
  const formatProps = {
    thousandSeparator: _.get(currency, 'formatData.thousandsSeparator'),
    decimalSeparator: _.get(currency, 'formatData.decimalMark'),
    decimalScale: _.get(currency, 'formatData.decimalPlaces'),
    fixedDecimalScale: true,
    allowNegative: true,
    prefix: _.get(currency, 'formatData.symbolFirst') ? symbol : '',
    suffix: _.get(currency, 'formatData.symbolFirst') ? '' : symbol,
    isNumericString: true,
    displayType: 'text',
  }
  return (
    <NumberFormat
      className={currencyStyle}
      value={value}
      {...{
        ...formatProps,
        ...others,
      }}
    />
  )
}

CurrencyFormatView.propTypes = {
  value: PropTypes.string,
  currency: PropTypes.object,
  others: PropTypes.object,
  dark: PropTypes.bool,
  size: PropTypes.string,
}

CurrencyFormatView.defaultProps = {
  value: '0.0',
  currency: {},
  others: {},
  dark: false,
  size: '1.6rem',

}
export default CurrencyFormatView
