import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import ThemeConfigContext from './theme-config-context'

/**
 * ThemeConfigProvider
 * Contain most logic handing navigate
 */
export default function ThemeConfigProvider({ children }) {
  const { i18n } = useTranslation()
  const theme = useTheme()

  function getContentGroup(key, fallback) {
    const currentLocale = i18n.language
    const code = _.get(theme, key, fallback)
    return _.isPlainObject(code)
      ? _.get(code, _.camelCase(currentLocale), _.get(code, 'default', fallback))
      : code
  }

  const getConfig = useCallback((key, fallback) => (
    _.get(theme, key, fallback)
  ), [theme])

  const availableOrderMethod = useMemo(() => (
    getConfig('config.availableOrderMethod', [])
  ), [getConfig])

  const selectableOrderMethod = useMemo(() => (
    _.filter(
      availableOrderMethod,
      ({ selectStoreBy }) => _.includes(['user', 'deliveryAddress'], selectStoreBy),
    )
  ), [availableOrderMethod])

  const state = {
    availableOrderMethod,
    getConfig,
    getContentGroup,
    selectableOrderMethod,
  };

  return (
    <ThemeConfigContext.Provider value={state}>
      {children}
    </ThemeConfigContext.Provider>
  )
}
