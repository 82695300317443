import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  formAddress: {
    textAlign: 'left',
    '& h4': {
      margin: '4.8rem 0 4.4rem',
      fontSize: '1.6rem',
      textTransform: 'uppercase',
    },
  },
  mapContainer: {
    position: 'relative',
  },
  mapSearchBox: {
    appearance: 'none',
    display: 'inline-block',
    width: '100%',
    padding: [['1rem', '0.75em', '1.1rem']],
    marginBottom: '2.4rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.bgDark,
    borderRadius: '0',
    color: theme.colors.text,
    backgroundColor: 'white',
    fontSize: '1.4rem',
    lineHeight: 1.143,
    textAlign: 'left',
    outline: 'none',
  },
  '@global': {
    '.pac-container': {
      zIndex: 9999,
      fontFamily: [`${theme.typo.fontFamilyBody}`, 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
      '&:after': {
        display: 'none',
      },
    },
    '.pac-item': {
      padding: '1rem 1.6rem',
      '& .pac-icon': {
        display: 'none',
      },
    },
  },
  mapElementContainer: {
    maxHeight: '40rem',
    height: '75vw',
  },
  mapLoading: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mapMarker: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  mapMarkerIcon: {
    transform: 'translateY(-50%)',
    color: theme.colors.secondary,
  },
  fieldsets: ({ latLongAreMandatory } = {}) => ({
    display: 'flex',
    flexDirection: latLongAreMandatory ? 'column-reverse' : 'column',
  }),
  fieldset: {
    position: 'relative',
    padding: 0,
  },
  legend: {
    position: 'absolute',
    top: '-99999rem',
    left: '-99999rem',
    visibility: 'hidden',
  },
  row: {
    marginRight: `-${theme.gap}rem`,
    maxWidth: '70rem',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  col: {
    width: '100%',
    marginRight: `${theme.gap}rem`,
    '&:last-child': {
      marginRight: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: '32rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: '50%',
    },
  },
  colSpan2: {
    width: '100%',
    maxWidth: '67.1rem',
    marginRight: `${theme.gap}rem`,
    '&:last-child': {
      marginRight: 0,
    },
  },
  inputBoxStyle: {
    marginTop: '2.4rem',
    '& label': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    '& input': {
      minHeight: '4rem',
      '&:not([class*="locale"])': {
        minHeight: 0,
      },
    },
  },
  inputLongStyle: {
    '& label': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
  dropdownStyle: {
    width: '100%',
    paddingTop: '3rem',
    '& label': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    // width: 'calc(50% - 1rem)',
    marginBottom: '1rem',
  },
  inputPhone: {
    marginTop: '2.4rem',
  },
  inputPhoneLabel: {
    fontSize: '1.2rem',
    fontWeight: '600',
  },

  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    formAddress: {
      '& h4': {
        margin: '4.8rem 0 1.8rem',
      },
    },
  },
}))

export default useStyles
