import _ from 'lodash'

/**
 *
 * @param {array} addons an array of addons
 */
export default function groupAddonsWithQuantity(addons = []) {
  const uniqAddons = _.uniqWith(addons, (a, b) => (
    _.isEqual(_.get(a, 'addonId'), _.get(b, 'addonId'))
      && _.isEqual(_.get(a, 'productAddonId'), _.get(b, 'productAddonId'))
      && _.isEqual(_.get(a, 'skuId'), _.get(b, 'skuId'))
      && _.isEqual(_.get(a, 'parentIdentifierUuid'), _.get(b, 'parentIdentifierUuid'))
  ))
  const addonsWithQuantity = _.map(uniqAddons, (addon) => {
    const items = _.filter(addons, _.pick(addon, [
      'addonId',
      'productAddonId',
      'skuId',
      'parentIdentifierUuid',
    ]))
    const itemCount = _.size(items)
    return {
      ...addon,
      quantity: itemCount,
      groupedIdentifierUuid: _.map(items, 'identifierUuid'), // Keep `identifierUuid`s for finding parents later
    }
  })
  return addonsWithQuantity
}
