/**
 * Create mobile side nav context for custom hook
 */

import { createContext } from 'react'

const MobileMenuContext = createContext({
  mobileMenuOpen: false,
  openMobileMenu: () => {},
  closeMobileMenu: () => {},
  clearUp: () => {},
})

export default MobileMenuContext
