/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../../link'
import WithAuth from '../../withAuth'

import useStyles from './dropdown-menu-style'
import combineClassNames from '../../../helpers/combineClassNames'

const DropdownMenuView = ({
  stylesDropdown,
  onCouponMarketplaceOpen,
  onMemberCodeOpen,
  qrCode,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <div
      className={stylesDropdown}
    >
      <WithAuth
        isAuth={() => (
          <ul>
            <li className={styles.listItem}>
              <Link to="/account/">{t('ui.header.account')}</Link>
            </li>
            <li className={styles.listItem}>
              <Link to="/account/orders/">{t('ui.header.orders')}</Link>
            </li>
            <li
              className={combineClassNames([styles.listItem, styles.listCoupon])}
              onClick={onCouponMarketplaceOpen}
              onKeyDown={onCouponMarketplaceOpen}
            >
              <span>{t('ui.header.coupons')}</span>
            </li>
            {
              !_.isEmpty(qrCode) && (

                <li
                  className={combineClassNames([styles.listItem, styles.listCoupon])}
                  onClick={onMemberCodeOpen}
                >
                  <span>{t('ui.header.member')}</span>
                </li>

              )
            }
            <li className={styles.listItem}>
              <Link to="/account/favourites/">{t('ui.header.favourites')}</Link>
            </li>
            <li className={styles.listItem}>
              <Link to="/logout/">{t('ui.header.logout')}</Link>
            </li>
          </ul>
        )}
        isNotAuth={() => (
          <ul>
            <li className={combineClassNames([styles.listItem, styles.login])}>
              <Link to="/login/">{t('ui.header.login')}</Link>
            </li>
            <li className={combineClassNames([styles.listItem, styles.register])}>
              <Link to="/registration/">{t('ui.header.register')}</Link>
            </li>
          </ul>
        )}
      />
    </div>
  )
}

export default DropdownMenuView
