import { createContext } from 'react'

/**
 * CompareContext
 * Create Menu context for custom hook
 */
const CompareContext = createContext({
  isOpen: false,
  compareData: [],
  hasMoreThan: false,
  enableComparisonEcom: false,
  maxNumberComparisonEcom: 0,
  addItemToCompare: () => {},
  addItemsToCompare: () => {},
  clearItemToCompare: () => {},

})

export default CompareContext
