// import _ from 'lodash'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import useStyles from './suggestions-carousel-style'
import Carousel from '../carousel'
import combineClassNames from '../../helpers/combineClassNames';
import {
  useAnalytics,
} from '../../hook'

const SuggestionsCarouselView = ({ relatedProducts, onClickTrackEvent }) => {
  const { trackEvent } = useAnalytics()
  const carouselRef = useRef(null)
  const [slideCount, setSlideCount] = useState(0)
  const carouselMaxWidth = `${20 * Math.min(5, relatedProducts.length)}rem`
  const styles = useStyles({ carouselMaxWidth })

  const updateSlideCount = () => {
    setSlideCount(_.get(carouselRef, 'current.innerSlider.state.slideCount', 0))
  }

  useEffect(() => {
    const products = _.compact(_.map(relatedProducts, 'trackEventParams'))
    if (_.isEmpty(products)) return
    trackEvent('viewProductImpression', {}, { products })
  }, [relatedProducts])

  return (
    <div
      className={combineClassNames([styles.list, slideCount > 1 && styles.carouselList])}
    >
      <Carousel
        ref={carouselRef}
        images={relatedProducts}
        infinite={false}
        hasArrows
        product
        onClick={onClickTrackEvent}
        postionDots={0}
        arrowsClassName={styles.arrows}
        nextArrowClassName={styles.nextArrow}
        prevArrowClassName={styles.prevArrow}
        carouselSettings={{
          slidesToShow: Math.min(5, relatedProducts.length),
          slidesToScroll: 5,
          infinite: false,
          onReInit: updateSlideCount,
          responsive: [
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: Math.min(4, relatedProducts.length),
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: Math.min(3, relatedProducts.length),
                slidesToScroll: 3,
                arrows: false,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: Math.min(2, relatedProducts.length),
                slidesToScroll: 2,
                arrows: false,
              },
            },
          ],
        }}
      />
    </div>
  )
}

export default SuggestionsCarouselView
