import React from 'react'
import Link from '../../../link'
import useStyles from './header-style'

const CustomHeader = ({
  node, style,
}) => {
  const { url } = node
  const { linkStyle } = useStyles()

  return (
    <Link to={url} className={linkStyle}>
      <div style={style.base}>
        <div style={{
          ...style.title,
          color: '#282828',
          fontSize: '14px',
        }}
        >
          {node.name}
        </div>

      </div>
    </Link>

  )
}

export default CustomHeader
