import { useContext } from 'react'
import ModalContext from './modal-context'

/**
 * useModal
 * The custom hook which provided to external use via context
 */
const useModal = () => {
  const context = useContext(ModalContext)
  return context
};

export default useModal
