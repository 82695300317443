import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  container: () => ({
    fontSize: '1rem',
    userSelect: 'none',
    '-webkit-touch-callout': 'none',
  }),
  '@keyframes load': {
    '0%': {
      backgroundColor: hexToRgba(theme.colors.text),
      boxShadow: `2em 0 ${hexToRgba(theme.colors.text)}, -2em 0 ${hexToRgba(theme.colors.text, 0.25)}`,
    },
    '33%': {
      backgroundColor: hexToRgba(theme.colors.text, 0.25),
      boxShadow: `2em 0 ${hexToRgba(theme.colors.text)}, -2em 0 ${hexToRgba(theme.colors.text, 0.25)}`,
    },
    '66%': {
      backgroundColor: hexToRgba(theme.colors.text, 0.25),
      boxShadow: `2em 0 ${hexToRgba(theme.colors.text, 0.25)}, -2em 0 ${hexToRgba(theme.colors.text)}`,
    },
  },
  loader: {
    borderRadius: '50%',
    width: '1em',
    height: '1em',
    marginLeft: '2em',
    backgroundColor: hexToRgba(theme.colors.text),
    boxShadow: `2em 0 ${hexToRgba(theme.colors.text, 0.25)}, -2em 0 ${hexToRgba(theme.colors.text)}`,
    animation: '$load 1.1s infinite linear alternate',
  },
}))

export default useStyles
