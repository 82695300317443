const typo = {
  textBodySize: '1.6rem',
  h1Size: '4.8rem',
  h2Size: '3.6rem',
  h3Size: '2.8rem',
  h4Size: '2.4rem',
  h5Size: '1.8rem',
  h6Size: '1.6rem',
  fontFamilyBody: 'Lato, sans-serif',
  fontFamilyHeaders: 'Lato, sans-serif',
  fontFamilyUrl: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap',
}
export default typo
