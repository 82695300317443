import { createContext } from 'react'

/*
 * AlertContext
 * Create Alert context for custom hook
 */
const AlertContext = createContext({
  isOpen: false,
  alertMessage: '',
  remove: () => { },
  show: (mgs) => mgs,
})

export default AlertContext
