export default function fileUrl(file) {
  return new Promise((resolve) => {
    // Instantiate a reader
    const reader = new FileReader();

    // What to do when we gets data?
    reader.onload = function hashCallback(e) {
      resolve(e.target.result);
    }

    reader.readAsDataURL(file);
  })
}
