/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    display: 'block',
    position: 'fixed',
    bottom: -210,
    height: 210,
    width: '100%',
    zIndex: 100,
    left: 0,
    transition: 'bottom 150ms',
    borderTop: '1px solid grey',
    background: theme.colors.background,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      height: 135,
      bottom: -135,

    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      height: 100,
      bottom: -100,
    },
  },
  containerFluidOpen: {
    bottom: 0,
    transition: 'bottom 150ms',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      bottom: 0,
      transition: 'bottom 150ms',
    },
  },
  hasBackgroundUrl: {
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: '121rem',
      left: '50%',
      transform: ' translateX(-50%)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0 auto',
    padding: '16px 20px 10px',
    height: 210,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      justifyContent: 'space-between',
      height: 135,
      flexDirection: 'row',
      padding: '16px 30px 10px',

    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: 10,
      width: '121rem',
      height: 100,
      alignItems: 'center',
    },
  },
  boxFramesText: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginLeft: 12,
      flex: 1,
      flexDirection: 'row',
    },
  },
  containerFrames: {
    display: 'flex',
  },
  containerBtns: {
    display: 'flex',
    width: '100%',
    marginTop: 0,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      marginTop: 10,
      width: 'auto',
    },
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      justifyContent: 'center',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  compareText: {
    fontSize: 16,
    color: theme.colors.text,
    margin: '0.5rem 0 0 0',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      flexDirection: 'row',
      margin: 0,
      marginLeft: '5rem',
    },
  },
  buttonAll: {
    display: 'none',
    margin: 0,
    width: 'auto',
    marginRight: '2rem',
    padding: '0',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      display: 'block',
      '&:last-child': {
        margin: 0,
      },
    },
  },
  buttonAllMobile: {
    display: 'block',
    marginLeft: '3rem',
    marginRight: '0',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      display: 'block',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      minWidth: 220,
    },
  },
  frame: {
    position: 'relative',
    width: 70,
    height: 70,
    margin: '0 8.5px',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      margin: 0,
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      margin: '0 10px',
    },
  },
  removeItem: {
    position: 'absolute',
    right: -7,
    top: -3,
    '& button': {
      backgroundColor: 'transparent',
    },
  },
  placeholder: {
    width: '35%',
  },
}))

export default useStyles
