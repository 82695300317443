import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    background: theme.colors.background,
    borderTop: `2px solid ${theme.colors.primary}`,
    borderBottom: `2px solid ${theme.colors.primary}`,
    minHeight: '24rem',
    position: 'absolute',
    left: 0,
    width: '100%',
    zIndex: 1000,
    '&.sub-menu-enter': {
      opacity: 0,
    },
    '&.sub-menu-enter-active': {
      opacity: 1,
      transition: 'opacity 150ms',
    },
    '&.sub-menu-exit': {
      opacity: 1,
    },
    '&.sub-menu-exit-active': {
      opacity: 0,
      transition: 'opacity 150ms',
    },
  },
  container: {
    marginTop: '8px',
    width: 1170,
    margin: [[0, 'auto']],
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '1.2rem 2rem 2rem',
  },
  boxCategory: {
    flex: '0 1 33.3333333%',
    padding: '0 1rem',
    '& h4': {
      display: 'block',
      padding: '1.5rem 0',
      textTransform: 'uppercase',
      fontSize: '1.4rem',
      fontWeight: 300,
      width: '100%',
      borderBottom: `1px solid ${theme.colors.text}`,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.colors.text,
      fontSize: '1.2rem',
      fontWeight: 400,
      '&:hover': {
        color: theme.colors.secondary,
      },
    },
    '& ul': {
      margin: 0,
    },
    '& li': {
      listStyle: 'none',
      lineHeight: 1.2,
      color: theme.colors.text,
    },
  },
  twoColumns: {
    columnCount: 2,
  },
}))

export default useStyles
