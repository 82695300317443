import * as Yup from 'yup'
import i18next from 'i18next'

/**
 * transformResetPasswordFormValidationSchema
 */

export default function transformResetPasswordFormValidationSchema(token) {
  const yupShapeObj = {}
  if (token) {
    yupShapeObj.password = Yup.string()
      .required(i18next.t('screens.resetPassword.errors.password', { context: 'required' }))
    yupShapeObj.passwordConfirmation = Yup.string()
      .required(i18next.t('screens.resetPassword.errors.passwordConfirmation', { context: 'required' }))
      .oneOf(
        [Yup.ref('password'), null],
        i18next.t('screens.resetPassword.errors.passwordConfirmation', { context: 'notMatch' }),
      )
  } else {
    yupShapeObj.email = Yup.string()
      .required(i18next.t('screens.resetPassword.errors.email', { context: 'required' }))
      .email(i18next.t('screens.resetPassword.errors.email', { context: 'format' }))
  }

  return Yup.object().shape(yupShapeObj)
}
