/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import PriceTag from '../price-tag'
import useStyles from './price-list-style'
import { combineClassNames } from '../../helpers';

const PriceView = ({
  items = [],
  align = 'left',
  currency,
  className,
  dividerStyle,
}) => {
  const styles = useStyles({ align })
  return (
    <div className={combineClassNames([styles.container, className])}>
      {
        _.map(_.compact(items), (item, index) => (
          <React.Fragment key={`price-${index}`}>
            {
              index !== 0
              && <i className={combineClassNames([styles.divider, dividerStyle])} />
            }
            <PriceTag
              {...{
                align,
                currency,
                ...item,
              }}
            />
          </React.Fragment>
        ))
      }
    </div>
  )
}

export default PriceView
