/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../link'
import WithAuth from '../withAuth'
import Search from '../search'
import combineClassNames from '../../helpers/combineClassNames'
import {
  useMobileMenu,
} from '../../hook'
import useStyles from './menu-mobil-style'
import Accordion from '../accordion'
import ButtonLanguage from './button-language'

const MenuMobileView = ({
  onMemberCodeOpen,
  onLanguageChange,
  availableLocales,
  currentLocale,
  isOpenSearch,
  onCouponMarketplaceOpen,
  // login,
  qrCode,
}) => {
  const { t, i18n } = useTranslation()
  const styles = useStyles()
  const {
    closeMobileMenu,
    mobileMenuOpen,
  } = useMobileMenu()
  const handleCouponMarketplaceOpen = (e) => {
    closeMobileMenu(e)
    onCouponMarketplaceOpen()
  }

  const handelOpenMemberCode = (e) => {
    closeMobileMenu(e)
    onMemberCodeOpen()
  }

  return (

    <div
      className={combineClassNames([
        styles.container,
        mobileMenuOpen && styles.containerOpen,
      ])}
      data-open
      id="menu-mobile"
    >
      <div className={combineClassNames([
        styles.menuMobile,
        mobileMenuOpen && styles.menuMobileOpen,
      ])}
      >
        <>
          <WithAuth
            isAuth={() => (
              <div className={styles.containerButtons}>
                <Search locale={i18n.language} isOpenSearch={isOpenSearch} />
                <div className={styles.boxMenu}>
                  <Accordion t={t} />
                  {
                    availableLocales.length > 1 && (
                      <ButtonLanguage
                        onLanguageChange={onLanguageChange}
                        availableLocales={availableLocales}
                        currentLocale={currentLocale}
                      />
                    )
                  }
                  <div className={styles.boxButtons}>
                    <ul className={styles.listMenuAuth}>
                      <li className={styles.listItem}>
                        <Link to="/account/">{t('ui.header.account')}</Link>
                      </li>
                      <li className={styles.listItem}>
                        <Link to="/account/orders/">{t('ui.header.orders')}</Link>
                      </li>
                      <li
                        className={combineClassNames([styles.listItem, styles.listCoupon])}
                        onClick={(e) => handleCouponMarketplaceOpen(e)}
                      >
                        <span data-open>{t('ui.header.coupons')}</span>
                      </li>
                      {
                        !_.isEmpty(qrCode) && (
                          <li
                            className={combineClassNames([styles.listItem, styles.listCoupon])}
                            onClick={(e) => handelOpenMemberCode(e)}
                          >
                            <span data-open>{t('ui.header.member')}</span>
                          </li>

                        )
                      }
                      <li className={styles.listItem}>
                        <Link to="/account/favourites/">{t('ui.header.favourites')}</Link>
                      </li>
                      <li className={styles.listItem}>
                        <Link to="/logout/">{t('ui.header.logout')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            isNotAuth={() => (
              <>
                <div className={styles.containerButtons}>
                  <div className={styles.boxButtons}>
                    <Link to="/login/">{t('ui.header.login')}</Link>
                    <Link to="/registration/">{t('ui.header.register')}</Link>
                  </div>
                  <Search locale={i18n.language} isOpenSearch={isOpenSearch} />
                </div>
                <div className={styles.boxMenu}>
                  <Accordion t={t} />
                  {
                    availableLocales.length > 1 && (
                      <ButtonLanguage
                        onLanguageChange={onLanguageChange}
                        availableLocales={availableLocales}
                        currentLocale={currentLocale}
                      />
                    )
                  }
                </div>
              </>
            )}
          />
        </>

      </div>
      <div
        rola="button"
        className={styles.closeMenuTriggerArea}
        onClick={closeMobileMenu}
      />
    </div>
  )
}

export default MenuMobileView
