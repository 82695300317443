import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import useStyles from './review-card-style'
import Rating from '../rating'
import AvatarImage from '../avatar-image'

const ReviewCardView = ({
  rating,
  comment,
  user,
  createdAt,
}) => {
  const styles = useStyles()
  return (
    <div className={styles.reviewCard}>
      <div className={styles.reviewBoxImage}>
        <AvatarImage src={_.get(user, 'avatarImage.thumbnail')} alt={_.get(user, 'alias', '')} />
      </div>
      <div className={styles.reviewBoxContent}>
        <div className={styles.header}>
          <div className={styles.info}>
            <span className={styles.author}>{_.get(user, 'alias')}</span>
            {' - '}
            <span className={styles.createAt}>{moment(createdAt).format('MMMM D, YYYY')}</span>
          </div>
          <div className={styles.rating}>
            <div className={styles.ratingLine}>
              <span className={styles.ratingLabel}>Rating:</span>
              <div className={styles.ratingStars}>
                <Rating rate={rating} editing={false} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.reviewComment}>
          <p>{comment}</p>
        </div>
      </div>
    </div>
  )
}

export default ReviewCardView
