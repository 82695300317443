/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import InputQuantity from '../input-quantity'
import useStyles from './input-quantity-group-style'

const Item = (props) => {
  const {
    id,
    title,
    subtitle,
    content,
    value,
    onChange,
    ...others
  } = props
  const styles = useStyles({ disabled: _.get(others, 'disabled', false) })
  // const itemStyle = [
  //   styles.listItem,
  //   others.disabled && !isSubGroup && styles.listItemDisabled,
  //   isSubGroup && styles.listItemSub,
  // ]
  const handleChange = (qty) => { onChange({ id, qty }) }
  return (
    <div className={styles.listItem}>
      <div className={styles.listItemLeft}>
        <div className={styles.listItemHeadings}>
          {title && <p className={styles.listItemTitle}>{title}</p>}
          {subtitle && <p className={styles.listItemSubtitle}>{subtitle}</p>}
        </div>
        { !_.isEmpty(content)
            && (
              <div className={styles.listItemContent}>
                {content}
              </div>
            )}
      </div>
      <InputQuantity
        inline
        step={1}
        onChange={handleChange}
        value={value}
        {...others}
      />
    </div>
  )
}

const InputQuantityGroupView = ({
  maxNoOfItemCount = Infinity,
  maxNoOfPerItemCount = Infinity,
  maxNoOfTotalCount = Infinity,
  options = [],
  disabled = false,
  onChange,
  value,
  className,
  separatorClassName,
}) => {
  const styles = useStyles()

  const onItemChange = ({ id, qty }) => {
    if (_.isFunction(onChange)) {
      const result = _.omitBy({
        ...value,
        [id]: qty,
      }, (_qty) => _qty <= 0)
      onChange(result)
    }
  }

  const renderItem = (item) => {
    const currentValue = _.get(value, item.id, 0)
    const noOfItemCount = _.size(_.pickBy(value, (val) => val > 0))
    const noOfTotalCount = _.sum(_.values(value))
    const isOutOfRange = currentValue === 0 && noOfItemCount >= maxNoOfItemCount
    const max = _.min([
      maxNoOfPerItemCount,
      maxNoOfTotalCount - noOfTotalCount + currentValue,
      maxNoOfTotalCount,
      item.max,
    ])
    const itemProps = {
      ...item,
      onChange: onItemChange,
      disabled: disabled || _.get(item, 'disabled', false) || isOutOfRange,
      max,
      value: _.get(value, item.id),
    }
    return <Item {...itemProps} />
  }

  return (
    <div className={combineClassNames([styles.group, className])}>
      {
        _.map(options, (item, index) => {
          const itemId = _.get(item, 'id')
          return (
            <React.Fragment key={`InputQuantity_${_.uniqueId(itemId)}`}>
              { index !== 0
                ? <hr className={combineClassNames([styles.separator, separatorClassName])} />
                : <></> }
              {renderItem(item)}
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

export default InputQuantityGroupView
