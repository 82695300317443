import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import Modal from '../modal'
import useStyles from './address-delete-modal-style'
import IconCross from '../../assets/icons/icon_modal_cross.svg'

export default function AddressDeleteModalView({
  isOpen = false,
  isPrimary = false,
  onRequestClose,
  onConfirm,
}) {
  const styles = useStyles()
  const { t } = useTranslation()

  const description = t('ui.addressDeleteModal.description', {
    returnObjects: true,
    context: isPrimary && 'primary',
  })

  return (
    <Modal
      dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Button
        className={styles.closeButton}
        inline
        onClick={onRequestClose}
        iconImage={IconCross}
        iconClassName={styles.closeButtonIcon}
      />
      <div className={styles.dialogContainer}>
        <div className={styles.dialogBody}>
          <h1 className={styles.dialogTitle}>{t('ui.addressDeleteModal.title')}</h1>
          {
            _.isArray(description) ? _.map(description, (line, index) => (
              <p key={`desc_${index}`} className={styles.dialogDescription}>{line}</p>
            )) : (
              <p className={styles.dialogDescription}>{description}</p>
            )
          }
        </div>
        <div className={styles.dialogButtons}>
          <Button
            className={styles.button}
            dark
            text={t('ui.addressDeleteModal.buttons.confirm')}
            onClick={onConfirm}
          />
          <Button
            className={styles.button}
            border
            text={t('ui.addressDeleteModal.buttons.cancel')}
            onClick={onRequestClose}
          />
        </div>
      </div>
    </Modal>
  )
}
