/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import moment from 'moment'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  cancelRequest,
  useProductGroups,
  useSystemSettings,
  useOrderMethod,
  useSkus,
  useDineInCart,
} from 'react-omnitech-api'
import ProductGroupView from './product-group-view'
import { getParamsToTrack } from '../../helpers'
import { useAnalytics, useCart } from '../../hook'

function ProductGroupController(props) {
  const { code, title, onClick } = props
  // prepare omnitech api
  const { getSystemSetting } = useSystemSettings()
  const { fetchSkus } = useSkus()
  const {
    orderMethod,
    store,
  } = useOrderMethod()
  const {
    initCart,
  } = useCart()
  const {
    trackEvent,
    getProductParams,
  } = useAnalytics()
  // internal state
  const [productGroupLines, setProductGroupLines] = useState([])
  const pageSizeSettings = getSystemSetting('product_groups.page_size') || 4
  const paramsToTrack = getParamsToTrack()
  const fnbEnabled = getSystemSetting('features.fnb.enable')

  const inventoryStoreCode = useMemo(() => {
    if (_.get(orderMethod, 'code', '') === 'dineInMenu') {
      return _.get(store, 'code')
    }
    return _.get(initCart, 'cartShipments.0.inventoryStore.code')
  }, [initCart, orderMethod, store])

  // /**
  //  * fetchSkuByProductGroup
  //  * pass code as number for productGroupId, or as string to get skus filter by productGroupCode
  //  * get product group line from API by product group code
  //  */

  const fetchSkuByProductGroup = useCallback(async (id, pageSize) => {
    if (_.isEmpty(inventoryStoreCode)) return
    try {
      // api call option
      const extraParams = getSystemSetting('api.v2.skus.index.product_search.ecom.query', {})
      const fnbOptin = {
        distinct: 'p',
        metaMenuFilterExistAll: [
          _.get(store, 'code'),
          moment().format('dddd'),
        ],
        metaMenuNotFilterNotExistAll: [
          moment().format('YYYYMMDD'),
          'PLP',
        ],
      }
      const option = {
        schemaVersion: '2021-07-21',
        includes: [
          'product',
          'color_option',
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
          'products.color_option_variant_type',
          'products.category_ids',
          'products.brand_ids',
        ].join(','),
        distinct: 'pc',
        sortBy: 'product_group',
        productGroupId: _.isNumber(id) ? id : undefined,
        productGroupCode: !_.isNumber(id) ? id : undefined,
        inventoryStoreCodeEq: inventoryStoreCode,
        /* [FL] 20210507 Roku: For new format,
         * it should be product_type_eq, but backend not supported yet
         * please use product_types first
         */
        pageSize,
        productTypes: 'product',
        ...extraParams,
        // ...extraParams,
        ...(fnbEnabled && fnbOptin),
      }

      // call api
      const { skus: skusData, pagination: paginationData } = await fetchSkus(option)
      const items = _.map(skusData, (sku) => ({
        sku,
      }))
      setProductGroupLines(items)
    } catch (error) {
      // TODO: handle error
    }
  }, [inventoryStoreCode, store])

  function trackProductsImpression() {
    const products = _.map(productGroupLines, ({ id, sku }, idx) => ({
      ...getProductParams(sku),
      list: title,
      position: (idx + 1),
    }))
    trackEvent('viewProductImpression', {}, { products, title: paramsToTrack.title })
  }

  function handleClickTrackEvent(eventName, product) {
    trackEvent(eventName, {}, { product })
  }

  useEffect(() => {
    if (_.isEmpty(productGroupLines)) return
    trackProductsImpression()
  }, [productGroupLines])

  // call api to get product group line when component is mount
  useEffect(() => {
    fetchSkuByProductGroup(code, pageSizeSettings)

    return function cleanUp() {
      cancelRequest.cancelAll(['fetchProductGroupByCode', 'fetchProductGroupLine'])
    }
  }, [code, pageSizeSettings, fetchSkuByProductGroup])

  const viewProps = {
    productGroupCode: code,
    productGroupLines,
    title,
    onClick,
    onClickTrackEvent: handleClickTrackEvent,
  }

  return <ProductGroupView {...viewProps} />
}

export default ProductGroupController
