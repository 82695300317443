import _ from 'lodash'
import isNullOrUndefined from './is-null-or-undefined'

/**
 * validateCartShipment
 * validate cart for checkout
 *
 * @param {*} cart
 */
export default function validateCartShipment(cart) {
  const {
    cartShipments,
  } = cart
  const cartShipment = _.first(cartShipments)
  // return false if cartShipment not found
  if (_.isEmpty(cartShipment)) return false

  // if delivery type is collect_at_store, pickup store is selected
  if (
    _.get(cartShipment, 'deliveryType.requirePickupStore') === true
    && isNullOrUndefined(_.get(cartShipment, 'pickupStore'))
  ) {
    return false
  }

  // finally, the cart is valid
  return true
}
