let trackParms = {}

const getParamsToTrack = () => trackParms

const setParamsToTrack = (params) => {
  trackParms = params
}

export {
  getParamsToTrack,
  setParamsToTrack,
}
