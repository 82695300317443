import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    padding: '0rem',
    paddingBottom: 49,
    background: theme.white,
    '& ul': {
      margin: 0,
      paddingLeft: '0 !important',
    },
    '& a': {
      textDecoration: 'none !important',
    },
    '& li': {
      marginBottom: 0,
    },
  },

}))

export default useStyles
