import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  container: {
    padding: '1.5rem 0',
    textAlign: 'left',
  },
  titleStyle: {
    fontSize: '1.6rem',
    lineHeight: 1.5,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '0.6rem',
    color: ({ theme }) => theme.colors.primary,
  },
  listItemsStyle: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  imageIcon: {
    maxWidth: 130,
  },
  itemLinkStyle: (props) => ({

    fontWeight: 400,
    display: (props.type === 'tag') ? 'inline' : 'block',
    marginRight: (props.type === 'tag') ? 8 : 0,
    lineHeight: 1,
    '&:hover a': {
      color: ({ theme }) => theme.colors.secondary,
    },
    '& a': {
      color: ({ theme }) => theme.colors.disabled,
      textDecoration: 'none',
      display: (props.type === 'tag') ? 'inline' : 'block',
      '&:before': {
        content: (props.type === 'tag') ? '"#"' : '"-"',
        marginRight: (props.type === 'tag') ? 0 : 5,
        display: 'inline',
      },
    },
    ...(props.template
      ? {
        fontWeight: 300,
        display: 'block',
        lineHeight: 1.25,
        '& a': {
          color: ({ theme }) => theme.colors.disabled,
          textDecoration: 'none',
        },
        '&:hover a': {
          color: ({ theme }) => theme.colors.secondary,
        },
        'html[lang^=zh] &': {
          fontSize: 14,
        },
        ...props.template,
      }
      : {}),
  }),
  itemLinkInlineStyle: () => ({
    display: 'inline-block',
    margin: '0 .8rem',
    fontSize: 14,
    fontWeight: 400,
    color: ({ theme }) => theme.colors.text,
    '&:hover a': {
      color: ({ theme }) => theme.colors.secondary,
    },
    '& a': {
      color: ({ theme }) => theme.colors.text,
      textDecoration: 'none',
    },
  }),
  itemIconStyle: {
    display: 'inline-block',
    marginTop: '1rem',
    '& img': {
      width: '3rem',
      marginRight: '2rem',
    },
  },
  itemLinkIconStyle: {
    position: 'relative',
    display: 'block',
    width: 30,
    marginRight: 15,
  },
  icon: {
    position: 'absolute',
    left: 10,
    top: 10,
    transform: 'scale(2.3)',
    color: ({ theme }) => (
      theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.primary
    ),
  },
  itemButtonStyle: {
    marginLeft: 0,

    '&:hover a': {
      color: ({ theme }) => theme.colors.secondary,
    },
    '& a': {
      color: ({ theme }) => theme.colors.text,
      textDecoration: 'none',
      fontSize: 14,
      fontWeight: 600,
    },
    '& img': {
      margin: 0,
      marginLeft: 10,
    },
  },
  '@media (min-width: 1170px)': {
    itemButtonStyle: {
      marginLeft: -15,
    },
    container: {
      padding: '1.5rem 1.6rem',
    },
  },
})

export default useStyles
