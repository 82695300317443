/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-property-newline */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'

import combineClassNames from '../../helpers/combineClassNames';

import useStyles from './modal-style'

ReactModal.setAppElement('#___gatsby')

const getScrollTop = () => (
  Math.abs(window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop)
)

const Modal = ({
  title, className, overlayClassName, cart, isOpen, children, ...others
}) => {
  const modifiers = _.pickBy(others, _.isBoolean)

  const styles = useStyles({
    cart,
    ...modifiers,
  })
  const modalClassName = combineClassNames([styles.modal, className])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currenctScrollTop, setCurrentScrollTop] = useState(0)
  useEffect(() => {
    if (isOpen) {
      const scrollTop = getScrollTop()
      setCurrentScrollTop(scrollTop)
      document.getElementsByTagName('body')[0].setAttribute('style', `top: ${scrollTop * -1}px`)
    }
  }, [isOpen])
  useEffect(() => {
    if (!_.isEqual(isModalOpen, isOpen)) {
      document.body.classList[isOpen ? 'add' : 'remove']('modal-open')
      if (!isOpen) {
        document.body.scrollTop = currenctScrollTop // for safari
        document.documentElement.scrollTop = currenctScrollTop // for IE/Firefox/...others
      }
      setIsModalOpen(isOpen)
    }
  })
  useEffect(() => (() => {
    // remove modal-open class from body when componentDidMount
    document.body.classList.remove('modal-open')
    document.getElementsByTagName('body')[0].setAttribute('style', '')
  }), [])
  return (
    <ReactModal
      closeTimeoutMS={cart ? 500 : 0}
      className={modalClassName}
      overlayClassName={
        combineClassNames([
          styles.overlay,
          cart && styles.cartOverlay,
          overlayClassName,
        ])
      }
      isOpen={isModalOpen}
      {...others}
    >
      {!_.isEmpty(title) && (
        <h1 className={styles.title}>
          {title}
        </h1>
      )}
      {children}
    </ReactModal>
  )
}

Modal.defaultProps = {
  children: false,
  className: '',
  title: '',
  isOpen: false,
  loading: false,
}

export default Modal
