import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  input: (props) => ({
    marginTop: '2.4rem',
    width: '100%',
    '& input, & textarea': {
      appearance: 'none',
      display: 'inline-block',
      width: '100%',
      padding: [['1rem', '0.75em', '1.1rem']],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.bgDark,
      borderRadius: '0',
      color: theme.colors.text,
      backgroundColor: 'white',
      fontSize: props.isSafari ? '1.6rem' : '1.4rem',
      lineHeight: 1.143,
      textAlign: 'left',
      outline: 'none',
      '&::-webkit-date-and-time-value': {
        minHeight: '1.143em',
      },
      ...(props.error
        ? {
          color: theme.colors.alert,
          ...props.error,
        }
        : {}),
      ...(props.disabled
        ? {
          cursor: 'not-allowed',
          outline: 'none',
          opacity: 1,
          ...props.disabled,
        }
        : {}),
      ...(props.inputFile
        ? {
          display: 'none',
          ...props.fileUploadStyle,
        }
        : {}),
    },
    '& textarea': {
      resize: 'none',
    },
  }),
  label: {
    textAlign: 'left',
    textTransform: 'none',
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: '-0.03em',
    paddingBottom: '0.9rem',
    display: 'block',
    position: 'relative',
  },
  labelRequired: {
    '&:after': {
      content: '"*"',
      color: theme.colors.alert,
      marginLeft: '0.3rem',
    },
  },
  errorStyle: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '.8rem 0',
    marginBottom: 0,
  },
  fileUploadStyle: (props) => ({
    '& label': {
      textAlign: 'left',
      minWidth: 140,
      textTransform: props.uppercase ? 'uppercase' : 'capitalize',
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: 1.14,
      paddingBottom: '0.3rem',
      display: 'block',
      position: 'relative',
      '&:after': {
        content: props.required ? '"*"' : '',
        color: theme.colors.alert,
        marginLeft: '0.3rem',
      },
      '&:before': {
        position: 'absolute',
        overflow: 'hidden',
        content: props.nameFile ? `"${props.nameFile}"` : '"CHOOSE FILE"',
        color: theme.colors.text,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: [['1rem', '1em']],
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.colors.primary,
        borderRadius: '0',
        backgroundColor: 'white',
        fontSize: '1.4rem',
        lineHeight: '1rem',
        textAlign: 'center',
        fontWeight: 600,
        bottom: -37,
        left: 0,
        width: 140,
      },
    },
    '& input': {
      border: '1px solid #ccc',
      display: 'inline-block',
      padding: '0.8rem 1.5rem',
      cursor: 'pointer',
      lineHeight: 1.43,
      opacity: 0,
      width: 135,
    },
  }),
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    input: (props) => ({
      width: props.width,
    }),
  },
}))

export default useStyles
