import React from 'react'
import {
  useSystemSettings,
} from 'react-omnitech-api'
import {
  useLink,
  useMobileMenu,
} from '../../hook'
import SearchView from './search-view'

const SearchController = (props) => {
  const { getSystemSetting } = useSystemSettings()
  const { navigate } = useLink()
  const {
    closeMobileMenu,
  } = useMobileMenu()
  const minCharacter = getSystemSetting('search.wildcard.min_character')
  const onSearch = (query) => {
    if (query.length >= minCharacter) {
      closeMobileMenu()
      navigate(`/products/?q=${query}`, { state: { query } })
    }
  }

  const searchViewProps = {
    onSearch,
    ...props,
  }
  return (
    <SearchView {...searchViewProps} />

  )
}

export default SearchController
