import _ from 'lodash'

export default function isSelectable(selectableFields, fieldName) {
  if (fieldName === 'country') return true

  const fieldNameInSnakeCase = _.snakeCase(fieldName)
  return (
    _.includes(selectableFields, fieldName) || _.includes(selectableFields, fieldNameInSnakeCase)
  )
}
