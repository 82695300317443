import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  suggestions: {
    textAlign: 'left',
    maxWidth: '100%',
    marginTop: `${theme.doubleGap}rem`,
    display: 'flex',
    flexDirection: 'column',
    '& article': {
      marginTop: `${theme.gap}rem`,
    },
  },
  list: ({ carouselMaxWidth }) => ({
    marginTop: '1rem',
    marginLeft: '-1rem',
    marginRight: '-3rem',
    maxWidth: carouselMaxWidth,
  }),
  carouselList: () => ({
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginLeft: '6.25%',
      marginRight: '6.25%',
    },
  }),
  arrows: {
    top: '9.6rem',
    transform: 'none',
  },
  nextArrow: {
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      right: '-6.25%',
    },
  },
  prevArrow: {
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      left: '-6.25%',
    },
  },

}))

export default useStyles
