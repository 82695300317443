import React, { useMemo } from 'react'
import _ from 'lodash'
import { ThemeProvider } from 'react-jss'
import theme from '../../theme'

export default function ThemeProviderController({
  children,
  theme: themeConfig,
}) {
  const mergedTheme = useMemo(() => (
    _.merge(theme, themeConfig)
  ), [themeConfig])
  return <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
}
