import _ from 'lodash'
import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Link from '../../link'
import { combineClassNames } from '../../../helpers'
import useStyles from './content-group-style'

function TemplateBanner2Col(props) {
  const breakpoints = useBreakpoint()
  const { contentGroup, template, onClickTrackEvent } = props
  const { contentGroupLines } = contentGroup
  const {
    row, col, wrapImage, col50, row50,
  } = useStyles()
  const onClick = (label) => {
    onClickTrackEvent(template, label)
  }

  const variableNameImage = breakpoints.sm ? 'ecomMedium' : 'ecomLarge'
  return (
    <div className={combineClassNames([row, row50])}>
      {/* <p>Banner2Col Template in theme</p> */}
      {
        contentGroupLines.map((item) => {
          const {
            id, attachment, url, configOptions,
          } = item
          const label = _.get(configOptions, 'label', id)
          const renderImage = (
            <div className={wrapImage}>
              <img src={_.get(attachment, `versions.${variableNameImage}`)} alt="" />
            </div>
          )
          return (
            <div className={combineClassNames([col, col50])} key={`banner-2-col-${id}`}>
              {_.isEmpty(url) || url === '/' ? renderImage : <Link gatsbyLink onClick={() => onClick(label)} to={url}>{renderImage}</Link>}
            </div>
          )
        })
      }
    </div>
  )
}

export default TemplateBanner2Col
