import * as Yup from 'yup'
import i18next from 'i18next'

/**
 * transformUpdatePasswordFormValidationSchema
 */

export default function transformUpdatePasswordFormValidationSchema() {
  const yupShapeObj = {}
  yupShapeObj.currentPassword = Yup.string()
    .required(i18next.t('screens.account.profile.errors.currentPassword', { context: 'required' }))
  yupShapeObj.password = Yup.string()
    .required(i18next.t('screens.account.profile.errors.password', { context: 'required' }))
  yupShapeObj.passwordConfirmation = Yup.string()
    .required(i18next.t('screens.account.profile.errors.passwordConfirmation', { context: 'required' }))
    .oneOf(
      [Yup.ref('password'), null],
      i18next.t('screens.account.profile.errors.passwordConfirmation', { context: 'notMatch' }),
    )

  return Yup.object().shape(yupShapeObj)
}
