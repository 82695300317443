import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  infoBox: () => ({
    textAlign: 'left',
    maxWidth: '77rem',
    marginTop: `${theme.doubleGap}rem`,
    '& p': {
      marginTop: `${theme.gap}rem`,
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    },
  }),
}))

export default useStyles
