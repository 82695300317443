import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    body: {
      overflow: 'hidden',
    },
  },
  loadingScreen: ({ isFixed }) => ({
    position: isFixed ? 'fixed' : 'relative',
    zIndex: 1002,
    top: '50%',
    left: 0,
    width: '100%',
    height: '300%',
    transform: isFixed ? 'translateY(-50%)' : 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    flex: isFixed ? 'none' : '1 1 100%',
    '-webkit-touch-callout': 'none',
    '&::before': {
      display: isFixed ? 'block' : 'none',
      content: "' '",
      textIndent: '-999999rem',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: '#fff',
      opacity: 0.8,
    },
  }),
  '@keyframes load': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    borderRadius: '50%',
    width: '10em',
    height: '10em',
    margin: [[60, 'auto']],
    fontSize: 10,
    position: 'relative',
    textIndent: '-9999em',
    borderTop: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderRight: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderBottom: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderLeft: `1.1em solid ${theme.colors.secondary}`,
    transform: 'translateZ(0)',
    animation: '$load 1.1s infinite linear',
    '&:after': {
      borderRadius: '50%',
      width: '10em',
      height: '10em',
    },
  },
}))

export default useStyles
