/* eslint-disable default-case */
/* eslint-disable no-undef */
import _ from 'lodash'

function trackEventFBP(eventName, options, meta = {}) {
  // skip when fbq lib is not existed
  if (_.isUndefined(window.fbq)) {
    // CP: execute any callbacks before returning
    // (e.g. fbq scripts not installed or blocked by adblockers)
    if (_.isFunction(meta.callback)) meta.callback()
    return
  }

  if (!fbq.loaded) {
    if (_.isFunction(meta.callback)) meta.callback()
    return
  }

  let order;
  let product;
  let skus;

  switch (eventName) {
    case 'customerAddToCart': {
      fbq('track', 'AddToCart', {
        contents: [{
          name: product.title,
          id: meta.skuCode,
          value: meta.price,
          quantity: options.eventValue,
        }],
        contentType: 'product',
        value: _.get(meta, 'value', 0),
        currency: _.get(meta, 'currencyCode'),
      });
      break
    }
    case 'viewProductDetail': {
      product = meta.product || []
      title = meta.title || ''
      fbq('track', 'ViewContent', {
        contentName: product.title,
        contentCategory: _.first((document.title || '').split(/[||｜]/g)),
        contentIds: [_.isEmpty(meta.skuCode) ? product.code : meta.skuCode],
        contentType: 'product',
        value: meta.price,
      });
      if (_.isFunction(meta.callback)) meta.callback()
      break
    }
    case 'customerCheckoutCompleteOrder': {
      order = meta.order || {}
      orderItems = meta.orderItems || []
      skus = meta.skus || []
      title = meta.title || ''
      fbq('track', 'Purchase', {
        contents: _.map(orderItems, (item) => {
          const sku = _.find(skus, { id: item.skuId })
          return {
            id: sku.code,
            content_type: 'product',
            quantity: item.quantity,
          }
        }),
        contentType: 'product',
        value: order.totalPrice,
        currency: order.currencyCode,
      })
      break
    }
  }
}

export default trackEventFBP
