import { createContext } from 'react'

/**
 * GoogleMapsContext
 * Create GoogleMaps context for custom hook
 */
const GoogleMapsContext = createContext({
  cleanup: () => {},
  getGoogleMapURL: () => {},
  getLanguageCodeForGoogle: () => {},
  getPlaceSuggestions: () => {},
  getPreferedAddressFromSearch: () => {},
  getUserCurrentPosition: () => {},
  search: () => {},
})

export default GoogleMapsContext
