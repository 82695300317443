import _ from 'lodash'
import LogoApple from '../../assets/icons/icon_apple.svg'
import LogoLine from '../../assets/icons/icon_line.svg'
import LogoFacebook from '../../assets/icons/icon_facebook.svg'
import LogoFacebookRoundInline from '../../assets/icons/icon_facebook_round.svg'
import LogoInstagram from '../../assets/icons/icon_instagram.svg'
import LogoFacebookInline from '../../assets/icons/icon_facebook.inline.svg'
import LogoInstagramInline from '../../assets/icons/icon_instagram.inline.svg'
import IconAmericanExpress from '../../assets/icons/icon-payment-americanexpress.svg'
import IconMaestro from '../../assets/icons/icon-payment-maestro.svg'
import IconMasterCard from '../../assets/icons/icon-payment-mastercard.svg'
import IconMasterCardSecure from '../../assets/icons/icon_mc_securecode.svg'
import IconPayPal from '../../assets/icons/icon-payment-paypal.svg'
import IconUnionPay from '../../assets/icons/icon-payment-unionpay.svg'
import IconVisa from '../../assets/icons/icon-payment-visa.svg'
import IconVisaSecure from '../../assets/icons/icon_visa_secure.svg'
import IconEnvolope from '../../assets/icons/icon_envelope.svg'
import IconLinedin from '../../assets/icons/icon_inkedin.svg'
import IconPinterest from '../../assets/icons/icon_pinterest.svg'
import IconTumblr from '../../assets/icons/icon_tumblr.svg'
import IconTwitter from '../../assets/icons/icon_twitter.svg'
import IconWeibo from '../../assets/icons/icon_weibo.svg'

export default function iconBuilder(name) {
  const iconName = _.lowerCase(name)

  switch (iconName) {
    case 'americanexpress':
      return IconAmericanExpress
    case 'email':
      return IconEnvolope
    case 'facebook':
      return LogoFacebook
    case 'facebookinline':
      return LogoFacebookInline
    case 'apple':
      return LogoApple
    case 'facebookround':
      return LogoFacebookRoundInline
    case 'line':
      return LogoLine
    case 'instagram':
      return LogoInstagram
    case 'instagraminline':
      return LogoInstagramInline
    case 'linkedin':
      return IconLinedin
    case 'mastercard':
      return IconMasterCard
    case 'mastercardsecure':
      return IconMasterCardSecure
    case 'maestro':
      return IconMaestro
    case 'paypal':
      return IconPayPal
    case 'pinterest':
      return IconPinterest
    case 'twitter':
      return IconTwitter
    case 'tumblr':
      return IconTumblr
    case 'unionpay':
      return IconUnionPay
    case 'visa':
      return IconVisa
    case 'visasecure':
      return IconVisaSecure
    case 'weibo':
      return IconWeibo
    default:
      return null
  }
}
