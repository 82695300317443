import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  title: {
    fontSize: '3.8rem',
    lineHeight: 1.26,
    fontWeight: 600,
    marginBottom: '3rem',
  },
  content: {
    marginBottom: '5.2rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginBottom: '5.2rem',
    },
  },
  footer: {

  },
  message: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '0',
  },
  subMessage: {
    fontSize: '1.4rem',
    whiteSpace: 'pre-line',
    lineHeight: 1.14,
  },
  buttons: {
    marginBottom: '2rem',
  },
  button: {
    width: '100%',
    maxWidth: '37rem',
    height: '5rem',
    display: 'inline-block',
  },
  ctaButtonLink: {
    textDecoration: 'none',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    footer: {
      paddingTop: '2rem',
    },
  },
}))

export default useStyles
