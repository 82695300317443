import { createContext } from 'react'

/**
 * MenuContext
 * Create Menu context for custom hook
 */
const MenuContext = createContext({
  isOpen: false,
  menuData: [],
  getDepartment: () => {},

})

export default MenuContext
