/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect } from 'react'
import _ from 'lodash'

function TemplateFacebookPageWidget(props) {
  const { height, width } = props
  const url = _.get(props, 'contentGroup.contentGroupLines[0].url')

  const resizeWidget = _.throttle(() => {
    if (typeof FB !== 'undefined') {
      FB.XFBML.parse()
    }
  }, 500, { leading: false, trailing: true })

  // append fb sdk js
  useEffect(() => {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.defer = true
    s.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0'
    s.nonce = 'MWhjOGz9'
    const body = _.first(document.getElementsByTagName('body'))
    if (body) {
      body.appendChild(s)
    }
  }, [])

  useEffect(() => {
    resizeWidget()
  }, [height, width])

  return (
    <div>
      <div id="fb-root" />
      <div
        className="fb-page"
        data-href={url}
        data-width={width}
        data-height={height}
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
        style={{ width: '100%' }}
      />
    </div>
  )
}

export default TemplateFacebookPageWidget
