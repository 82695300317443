import { createUseStyles } from 'react-jss'
import LeftArrow from '../../assets/icons/icon_left_arrow.svg'
import RightArrow from '../../assets/icons/icon_right_arrow.svg'

const useStyles = createUseStyles((theme) => ({
  container: {
    width: '100%',
    margin: [[20, 'auto', 0]],
    display: 'flex',
    justifyContent: 'center',
  },
  paginationStyle: {
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    '& li': {
      textAlign: 'center',
      width: 30,
      margin: '0 0.8rem',
      display: 'inline-block',
      height: 30,
      cursor: 'pointer',
      '& a': {
        height: '3rem',
        width: '3rem',
        display: 'block',
        position: 'relative',
      },
      '&.active': {
        color: theme.colors.background,
        background: theme.colors.primary,
      },
      '&.icon a': {
        width: 30,
        display: 'block',
        height: 30,
      },
      '&.icon.icon-left a:before': {
        width: 44,
        height: 44,
        content: `url(${LeftArrow})`,
      },
      '&.icon.icon-right a:before': {
        width: 44,
        height: 44,
        content: `url(${RightArrow})`,
      },
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      width: '100%',
    },
  },
}))

export default useStyles
