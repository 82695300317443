import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  accountMenu: {
    width: '100%',
    height: 'auto',
    padding: '4rem 2rem 0',
    borderBottom: `1px solid ${theme.colors.disabled}`,
    maxWidth: 1210,
    margin: '0 auto 0',
    backgroundColor: theme.colors.background,
  },
  container: {
    width: '100%',
    margin: [[0, 'auto']],
    display: 'flex',
    flexDirection: 'column',
  },
  boxMenu: {
    position: 'relative',
    listStyle: 'none',
    display: 'block',
    width: 'auto',
    overflowX: 'hidden',
    marginBotton: '.5rem',
    height: 40,
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '4rem',
      height: '4rem',
      background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      zIndex: '10',
    },

  },
  scrollStyle: {
    display: 'flex',
    margin: 0,
    padding: 0,
    height: 40,
    textAlign: 'center',
    paddingRight: '3rem',
    '& li': {
      display: 'inline-block',
      position: 'relative',
      margin: '0 1rem',
      '& a': {
        color: theme.colors.disabled,
        fontSize: '1.6rem',
        fontWeight: 400,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        textDecoration: 'none',
      },
      '& a.active, & a:hover': {
        color: theme.colors.primary,
        textShadow: '1px 0 0 currentColor',
        textDecoration: 'underline',
      },
      '&:last-child': {
        marginLeft: '3rem',
        width: '20rem',
        flex: '0 0 5%',
      },
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    accountMenu: {
      borderBottom: 'none',
    },
    container: {
      width: '100%',
      margin: 0,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    scrollStyle: {
      paddingRight: 0,
    },
    boxMenu: {
      '&:after': {
        content: '',
        display: 'none',
      },
      '& li:last-child': {
        display: 'none',
      },
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {

    container: {
      width: `${theme.mediaQueries.desktop}px`,
      margin: [[0, 'auto']],
    },
  },
}))

export default useStyles
