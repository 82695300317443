import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  container: () => ({
    width: '100%',
    // height: '300%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    userSelect: 'none',
    '-webkit-touch-callout': 'none',
  }),
  '@keyframes load': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    borderRadius: '50%',
    width: '1em',
    height: '1em',
    margin: [[60, 'auto']],
    position: 'relative',
    textIndent: '-9999em',
    borderTop: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderRight: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderBottom: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderLeft: `1.1em solid ${theme.colors.secondary}`,
    transform: 'translateZ(0)',
    animation: '$load 1.1s infinite linear',
    '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '50%',
      width: '1.5em',
      height: '1.5em',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.colors.background,
    },
  },
}))

export default useStyles
