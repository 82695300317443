import React from 'react'
import Carousel from '../../carousel'
import useStyles from './content-group-style'

function TemplateCarousel(props) {
  const { contentGroup, onClickTrackEvent, template } = props
  const { contentGroupLines } = contentGroup
  const { row, col, imageBoxOneImage } = useStyles()
  const onClick = (label) => {
    onClickTrackEvent(template, label)
  }
  return (
    <div className={row}>
      <div className={col}>
        <Carousel
          images={contentGroupLines}
          slidesToShowDesktop={1}
          carouselSettings={{
            responsive: [],
          }}
          imageBoxClassName={imageBoxOneImage}
          autoplay
          darkDots
          classNaneCarousel="heroCarousel"
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default TemplateCarousel
