function getSocialMediaOptions(url, title, imageUrl, hashtags) {
  const shareOption = {
    facebook: {
      url: 'https://www.facebook.com/sharer.php',
      options: {
        u: url,
        title,
      },
    },
    line: {
      url: 'https://social-plugins.line.me/lineit/share',
      options: {
        url,
        title,
      },
    },
    twitter: {
      url: 'https://twitter.com/intent/tweet',
      options: {
        url,
        text: title,
      },
    },
    weibo: {
      url: 'http://service.weibo.com/share/share.php',
      options: {
        url,
        title,
      },
    },
    linkedin: {
      url: 'https://www.linkedin.com/shareArticle',
      options: {
        url,
        title,
      },
    },
    tumblr: {
      url: 'https://www.tumblr.com/widgets/share/tool',
      options: {
        canonicalUrl: url,
        title,
      },
    },
    pinterest: {
      url: 'http://pinterest.com/pin/create/button/',
      options: {
        u: url,
        media: imageUrl,
      },
    },
    email: {
      url: 'mailto:',
      options: {
        subject: title,
        body: `${title}\n${url || ''}\n${hashtags || ''}`,
      },
    },
  }

  return shareOption
}

export default getSocialMediaOptions

// Reference for sharing
// Share.providers = {
//   facebook: 'https://www.facebook.com/sharer.php'/*?u={url}&title={title}*/,
//   line: 'https://social-plugins.line.me/lineit/share?url=https%3A%2F%2Fline.me%2Fen',
//   //facebook: 'https://www.facebook.com/dialog/share?app_id={app_id}&display=page&href={url}&redirect_uri={redirect_url}',
//   twitter: 'https://twitter.com/intent/tweet'/*?url={url}&text={title}&hashtags={hashtags}&via={via}*/,
//   googleplus: 'https://plus.google.com/share'/*?url={url}*/,
//   pinterest: 'http://pinterest.com/pin/create/button/'/*?url={url}&media={post_image_url}&description={title}&is_video={is_video}*/,
//   qq: 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey'/*?url={url}&title={title}*/,
//   reddit: 'https://www.reddit.com/submit'/*?url={url}&title={title}*/,
//   renren: 'http://share.renren.com/share/buttonshare'/*?link={url}&title={title}*/,
//   weibo: 'http://service.weibo.com/share/share.php'/*?url={url}&title={title}*/,
//   tencent_weibo: 'http://v.t.qq.com/share/share.php'/*?url={url}&title={title}*/,
//   douban: 'http://www.douban.com/recommend/'/*?url={url}&title={title}*/,
//   email: 'mailto:'/*?subject={title}&body=*/,
//   buffer: 'https://buffer.com/add'/*?text={title}&url={url}'*/,
//   digg: 'http://digg.com/submit'/*?url={url}&title={title}'*/,
//   delicious: 'https://delicious.com/save'/*?v=5&provider={provider}&noui&jump=close&url={url}&title={title}'*/,
//   linkedin: 'https://www.linkedin.com/shareArticle'/*?url={url}&title={title}'*/,
//   stumble_upon: 'http://www.stumbleupon.com/submit'/*?url={url}&title={title}'*/,
//   tumblr: 'https://www.tumblr.com/widgets/share/tool'/*?canonicalUrl={url}&title={title}&caption={desc}'*/
// }
