import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: ({ inline } = {}) => ({
    display: inline ? 'inline-flex' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    fontSize: inline ? '2rem' : '3rem',
    lineHeight: inline ? '2rem' : '3rem',
  }),
  button: ({ valueZeroView } = {}) => ({
    color: valueZeroView ? theme.colors.bgMediumDark : theme.colors.text,
    appearance: 'none',
    border: 'none',
    background: 'none',
    padding: '1em',
    margin: '-1em',
    lineHeight: '1em',
    cursor: 'pointer',
    '&:disabled': {
      color: theme.colors.disabled,
      opacity: 0.3,
      cursor: 'not-allowed',
    },
  }),
  buttonIcon: {
    width: 'calc(1em - 0.2rem)',
    height: 'calc(1em - 0.2rem)',
    border: '0.1rem solid currentColor',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    padding: 0,
    display: 'block',
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      pointerEvents: 'none',
      display: 'block',
      width: '60%',
      height: '0.2rem',
      backgroundColor: 'currentColor',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  buttonIconDecrease: {
    '&::after': {
      display: 'none',
    },
  },
  buttonIconIncrease: {
    '&::after': {
      transform: 'translate(-50%, -50%) rotate(90deg)',
      transformOrigin: 'center',
    },
  },
  value: ({ inline } = {}) => ({
    fontSize: '1.6rem',
    lineHeight: '1em',
    textAlign: 'center',
    color: 'currentColor',
    minWidth: inline ? '3.4rem' : '6rem',
    padding: '0 0.3rem',
    pointerEvents: 'none',
  }),
}))

export default useStyles
