import { createUseStyles } from 'react-jss'
import ratioCalc from '../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  productCard: {
    padding: [[20, 10]],
    textAlign: 'left',
    flex: 1,
    position: 'relative',
    width: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  figure: {
    position: 'relative',
    margin: 0,
  },
  favouriteButton: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
  },
  icon: {
    position: 'absolute',
    right: '1.3rem',
    top: '1.3rem',
    cursor: 'pointer',
    maxWidth: '1.8rem',
  },
  image: ({ product } = {}) => ({
    height: 0,
    paddingBottom: product
      ? ratioCalc(theme.config.ratioMiniCarousel)
      : ratioCalc(theme.config.ratioPlp),
    position: 'relative',
    overflowY: 'hidden',
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
      margin: 0,
      width: '100%',
    },
  }),
  noImage: {
    backgroundColor: '#dddddd',
    '& img': {
      width: '40% !important',
      height: '40% !important',
    },
  },
  content: {
    textAlign: 'center',
    padding: '0 2rem 2rem',
    color: theme.colors.text,
    '& p': {
      marginBottom: '0',
      fontSize: '1.4rem',
      lineHeight: 1.20,
    },
  },
  customLabels: {
    minHeight: '2rem',
    marginBottom: '0.4rem',
    marginTop: '1rem',
  },
  colorSelect: {
    color: theme.colors.disabled,
  },
  originalPrice: {
    fontSize: '1.2rem',
    display: 'inline-block',
    textDecoration: 'line-through',
    color: theme.colors.disabled,
    lineHeight: 1.14,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginRight: '0.4rem',
      display: 'inline',
      lineHeight: 1.8,
    },
  },
  checkbox: {
    justifyContent: 'center',
    margin: '0',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    '& div:nth-child(3)': {
      marginLeft: '2.2rem',
    },
  },
  front: {
    '&::before': {
      width: 16,
      height: 16,
    },
    '&::after': {
      left: '0.2rem',
    },
  },
  textCheckbox: {
    textTransform: 'uppercase',
  },
  sellPriceOnSale: {
    lineHeight: 1,
    color: theme.colors.alert,
    display: 'inline-block',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      lineHeight: 1.8,
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    productCard: {
      padding: [[20, 15]],
    },
  },
}))

export default useStyles
