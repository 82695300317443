import { createUseStyles } from 'react-jss'
import Arrow from '../../assets/icons/icon_right_arrow.svg'

const useStyles = createUseStyles((theme) => {
  const text = {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    colot: theme.colors.text,
    margin: 0,
  }
  return {
    container: {
      display: 'flex',
      flex: 1,
      marginBottom: '2.5rem',
    },
    avatarContainer: {
      flexBasis: '7rem',
      marginRight: '1rem',
      padding: '0.5rem',
    },
    avatar: {
      width: '5.8rem',
      cursor: 'pointer',
    },
    avatarUploading: {
      opacity: 0.5,
      cursor: 'default',
    },
    info: {
      flex: '1 1 auto',
      minWidth: '20rem',
      paddingTop: '0.5rem',
    },
    name: {
      extend: text,
    },
    rank: ({ rankHexValue, rankBgHexValue }) => ({
      lineHeight: '2rem',
      marginLeft: '0.4rem',
      padding: '0.2rem .5rem',
      background: rankBgHexValue || theme.colors.disabled,
      color: rankHexValue || theme.colors.background,
      fontSize: '1.4rem',
      fontWeight: 300,
      borderRadius: '.2rem',
    }),
    alias: {
      extend: text,
      color: theme.colors.disabled,
    },
    points: {
      extend: text,
      '&::after': {
        content: '""',
        display: 'inline-block',
        backgroundImage: `url(${Arrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        width: '0.7rem',
        height: '1.3rem',
        marginLeft: '0.5rem',
        transform: 'translateY(0.1rem)',
      },
    },
    pointsLink: {
      color: 'currentColor',
      textDecoration: 'none',
    },
    qrContainer: {
      flex: '1 1 100%',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        cursor: 'pointer',
      },
    },
  }
})

export default useStyles
