/* eslint-disable no-param-reassign */
import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import useReactFontLoader from 'react-font-loader'
import { useTheme } from 'react-jss'
import 'reset-css'
import 'normalize.css'
import {
  useOrderMethod,
  useSystemSettings,
} from 'react-omnitech-api'
import {
  useCouponMarketplace,
  useI18n,
  useLink,
  useLocation,
  useThemeConfig,
} from '../../hook'
import LayoutView from './layout-view'

function LayoutController(props) {
  const [showOrderMethodBlocker, setShowOrderMethodBlocker] = useState(false)
  const { navigate } = useLink()
  const { location } = useLocation()
  const { orderMethod, setDeliveryAddress, setStore } = useOrderMethod()
  const theme = useTheme()
  const { getSystemSetting } = useSystemSettings()
  const { onCouponMarketplaceOpen } = useCouponMarketplace()
  const { availableLocales, currentLocale } = useI18n()
  const {
    selectableOrderMethod,
  } = useThemeConfig()
  const userRegistrationFieldsConfig = getSystemSetting('account.user_registration_fields')

  const onLanguageChange = (locale) => {
    const path = _.get(window, 'location.pathname', '/').replace(/^\/[a-zA-Z-]+/, '')
    const search = _.get(window, 'location.search', '')
    if (!_.isEmpty(locale) && locale !== _.get(currentLocale, 'locale')) {
      navigate(`${path}${search}`, { locale })
    }
  }
  // internal state
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const handelOpenSearch = () => {
    setIsOpenSearch(!isOpenSearch)
  }
  // set up typos
  useReactFontLoader({
    url: _.get(theme, 'typo.fontFamilyUrl'),
  })

  useEffect(() => {
    if (_.isEmpty(selectableOrderMethod)) return
    const pathsToCheck = [
      '',
      'account/favourites',
      'products',
      'products-all',
      'products-comparison',
      'product',
      'cart',
    ]
    const needToCheck = _.reduce(pathsToCheck, (result, pathToCheck) => {
      const regExp = new RegExp(`^/${pathToCheck}(/|$)`)
      if (result) return result
      // FL: Make sure location.page is not an empty string
      const page = _.isEmpty(_.get(location, 'page')) ? '/' : _.get(location, 'page')
      // eslint-disable-next-line no-param-reassign
      result = regExp.test(page)
      return result
    }, false)
    // Show/Hide order method dialog
    if (
      !_.has(orderMethod, 'code')
    ) {
      setDeliveryAddress({})
      setStore({})
      // FL: hide blocker if navigate to page that no need to block
      setShowOrderMethodBlocker(needToCheck)
    }
  }, [
    selectableOrderMethod,
    orderMethod,
    _.get(location, 'page', ''),
  ])

  const handleOrderMethodBlockerClose = () => {
    setShowOrderMethodBlocker(false)
  }

  const viewProps = {
    ...props,
    onLanguageChange,
    availableLocales,
    currentLocale,
    isOpenSearch,
    handelOpenSearch,
    showOrderMethodBlocker,
    handleOrderMethodBlockerClose,
    onCouponMarketplaceOpen,
    userRegistrationFieldsConfig,
  }
  return (
    <LayoutView {...viewProps} />
  )
}

export default LayoutController
