import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    marginTop: '10rem',
  },
  message: {
    marginBottom: '0',
    color: theme.colors.disabled,
  },
}))

export default useStyles
