import React from 'react'
import _ from 'lodash'
import URI from 'urijs'
import { navigate } from 'gatsby'
import useI18n from '../use-i18n'
import { isUrl } from '../../helpers'
import LinkContext from './link-context'

/**
 * LinkProvider
 * Contain most logic handing navigate
 */
export default function LinkProvider({ value: { locale }, children }) {
  const { getLocalePath } = useI18n()

  function getPath(to) {
    let url = `/${locale}${to}`
    if (isUrl(to)) {
      // when it is in development mode, will replace the origin of
      url = _.startsWith(to, process.env.GATSBY_WEBSITE_URL)
        ? removeUrlOrigin(to)
        : to
    }
    return url
  }

  function removeUrlOrigin(originUrl) {
    const url = URI(originUrl)
    return `${url.path()}${url.search()}`
  }

  function handleNavigate(to, options = {}) {
    const url = getPath(to)
    // if specific a locale to use, get the path from locale config
    if (!_.isEmpty(options.locale) && !isUrl(to)) {
      const localePath = getLocalePath(options.locale)
      window.location.href = `/${localePath}${to}`
    } else {
      navigate(url, options)
    }
  }

  function handleRecordNotFound() {
    navigate(`/${locale}/404/`)
  }

  const state = {
    getPath,
    navigate: handleNavigate,
    recordNotFound: handleRecordNotFound,
  };

  return (
    <LinkContext.Provider value={state}>
      {children}
    </LinkContext.Provider>
  )
}
