import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  '@keyframes listLoading': {
    from: {
      left: '-20%',
    },
    to: {
      left: '130%',
    },
  },
  container: {
    position: 'relative',
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0.7rem 1rem',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.5,
      position: 'absolute',
      left: '-20%',
      top: '50%',
      width: '1rem',
      height: '200%',
      backgroundColor: theme.colors.background,
      transform: 'rotate(20deg) translateY(-50%)',
      filter: 'blur(0.4rem)',
    },
  },
  listLoading: {
    '&::after': {
      animationName: '$listLoading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  listMoreThen4Items: {
    justifyContent: 'flex-start',
    overflowX: 'auto',
    scrollSnapType: 'both',
    scrollbarWidth: 'none',
  },
  item: {
    display: 'inline-block',
    overflow: 'hidden',
    minWidth: 'calc(20% - 0.6rem + 5% - 0.3rem)',
    cursor: 'pointer',
    position: 'relative',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    '&::after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.colors.secondary,
      opacity: 0,
    },
  },
  itemSelected: {
    '&::after': {
      opacity: 1,
    },
  },
  ratioControl: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: ratioCalc(theme.config.ratioPlpSwatch),
  },
  image: {
    margin: 0,
    objectFit: 'cover',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  moreButton: {
    position: 'absolute',
    right: '-0.3rem',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    width: '1.7rem',
    background: 'transparent',
    paddingLeft: '0.5rem',
  },
  moreIcon: {
    margin: 0,
  },
}))

export default useStyles
