import { useContext } from 'react'
import MenuContext from './menu-context'

/**
 * useLink
 * The custom hook which provided to external use via context
 */
const useMenu = () => {
  const context = useContext(MenuContext);
  return context
};

export default useMenu;
