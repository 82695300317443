import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import useStyles from './swatch-list-style'
import Button from '../../../button'
import combineClassNames from '../../../../helpers/combineClassNames'
import RightArrowIconInline from '../../../../assets/icons/icon_right_arrow.inline.svg'

const SwatchListView = (props) => {
  const {
    className,
    loading,
    list,
    itemCount,
    onItemClick,
    onMoreClick,
    selectedColorOptionId,
    skus,
  } = props// states
  const theme = useTheme()
  const styles = useStyles({ itemCount })
  return (
    <div className={styles.container}>
      <div
        className={combineClassNames([
          styles.list,
          loading && styles.listLoading,
          itemCount > 4 && styles.listMoreThen4Items,
          className,
        ])}
      >
        {_.times(itemCount, (index) => {
          const colorOption = _.nth(list, index)
          const sku = _.find(skus, { colorOptionId: _.get(colorOption, 'id') })
          const isSelected = selectedColorOptionId === _.get(colorOption, 'id', '--')
          const colorHex = _.replace(_.get(colorOption, 'hexValue', theme.colors.bgMedium), /^#?NO_HEX$/, theme.colors.bgMedium)
          const isColorHexTooLight = /^#(f[a-f]){3}/.test(colorHex.toLowerCase())
          const svgStroke = isColorHexTooLight ? "stroke='%23d7d7d7' stroke-width='1'" : "stroke='none'"
          const imageSrc = _.get(colorOption, 'swatch.versions.webThumbPortrait', `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 52'%3e%3crect width='48' height='52' x='0' y='0' fill='${encodeURIComponent(colorHex)}' ${svgStroke} /%3e%3c/svg%3e`)
          return (
            <div
              key={`swatch_${_.uniqueId(index)}`}
              className={combineClassNames([styles.item, isSelected && styles.itemSelected])}
              onClick={() => { onItemClick({ colorOption, sku }) }}
              role="button"
              aria-hidden="true"
              tabIndex={index}
            >
              <div className={styles.ratioControl}>
                <img alt={_.get(colorOption, 'name')} src={imageSrc} className={styles.image} />
              </div>
            </div>
          )
        })}
      </div>
      {
        itemCount > 4 && (
          <Button
            inline
            className={styles.moreButton}
            onClick={onMoreClick}
            iconInline
            iconImage={RightArrowIconInline}
            iconClassName={styles.moreIcon}
          />
        )
      }
    </div>
  )
}

export default SwatchListView
