/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import InputRadio from '../input-radio'
import useStyles from './input-radio-group-style'

const InpuRadioGroupView = ({
  className,
  itemClassName,
  labelClassName,
  radioClassName,
  textClassName,
  separatorClassName,
  options,
  formik,
  name,
  disabled,
  value,
  onChange,
}) => {
  const styles = useStyles()

  const onItemChange = (id) => {
    if (_.isFunction(onChange)) onChange(id)
  }

  return (
    <div className={combineClassNames([styles.group, className])}>
      {
        _.map(options, (item, index) => {
          const itemId = _.get(item, 'id')
          const itemProps = {
            ...item,
            className: itemClassName,
            labelClassName: combineClassNames([styles.label, labelClassName]),
            radioClassName: combineClassNames([styles.radio, radioClassName]),
            textClassName: combineClassNames([styles.text, textClassName]),
            formik,
            disabled: disabled || _.get(item, 'disabled', false),
            name,
            value: itemId,
            checked: value === itemId,
            onChange: () => onItemChange(itemId),
          }
          return (
            <React.Fragment key={`InputRadio_${_.uniqueId(itemId)}`}>
              { index !== 0
                ? <hr className={combineClassNames([styles.separator, separatorClassName])} />
                : <></> }
              <InputRadio
                {...itemProps}
              >
                <div>
                  <p className={combineClassNames([styles.text, textClassName])}>{_.get(item, 'title', '')}</p>
                  {_.get(item, 'content')}
                </div>
              </InputRadio>
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

export default InpuRadioGroupView
