const enHK = require('./en-HK.json')
const zhCN = require('./zh-CN.json')
const zhHK = require('./zh-HK.json')
const zhTW = require('./zh-TW.json')

module.exports = {
  'en-HK': {
    translation: enHK,
  },
  'zh-CN': {
    translation: zhCN,
  },
  'zh-HK': {
    translation: zhHK,
  },
  'zh-TW': {
    translation: zhTW,
  },
}
