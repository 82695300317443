import _ from 'lodash'
import URI from 'urijs'

function clusterBrands(brandData) {
  const collection = {}
  for (let i = 0; i < brandData.length; i += 1) {
    const firstLetter = brandData[i].text.charAt(0);
    if (collection[firstLetter] === undefined) {
      collection[firstLetter] = [];
    }
    collection[firstLetter].push(brandData[i]);
  }

  if (_.keys(collection).length > 1) {
    return Object.keys(collection).map((key) => ({
      children: collection[key],
      key: `brand-${key}`,
      text: key,
      type: 'brand',
      url: '',
    }))
  }
  return brandData
}

export default function getBrands(brands) {
  const brandsMenuData = _.map(brands, (brand) => {
    const { code, name } = brand
    const urlObj = new URI('/products/')
      .addSearch({
        brandCodeEq: code,
      })
    const url = `${urlObj.path()}${urlObj.search()}`
    return {
      code: _.lowerCase(code),
      children: [],
      key: `brand-${brand.id}`,
      text: name,
      type: 'brand',
      url,
    }
  })
  return clusterBrands(_.sortBy(brandsMenuData, ['text'], ['asc']))
}
