import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  ratingStyle: () => ({
    position: 'relative',
    width: '100px',
    height: '2.5rem',
    margin: '0rem',
    '& label.dv-star-rating-star': {
      marginRight: '0.2rem',
    },
  }),

})

export default useStyles
