import {
  createUseStyles,
} from 'react-jss'
import ArrowLeft from '../../assets/icons/icon_left_arrow.svg'
import ArrowRight from '../../assets/icons/icon_right_arrow.svg'

const useStyles = createUseStyles((theme) => ({
  carousel: (props) => ({
    margin: props.marginCarousel,
    '& .slick-track': {
      display: 'flex !important',
    },

    '& .slick-slide': {
      height: 'inherit !important',
    },
    '& .magic-dots.slick-dots': {
      position: 'absolute !important',
      left: '50%',
      transform: ' translateX(-50%)',
      bottom: props.postionDotsMovile,
      '& li button:before': {
        color: props.darkDots ? theme.colors.primary : theme.colors.bgMedium,
        fontSize: 10,
      },
      '& li button:hover:before, li button:focus:before': {
        color: theme.colors.secondary,
        opacity: 1,
      },
      '& li.small button:before': {
        fontSize: '0.6rem',
      },
      '& .slick-active button:before': {
        color: theme.colors.secondary,
      },
    },
  }),

  arrowNextStyle: {
    width: '3rem',
    height: '3rem',
    zIndex: 10,
    '&.slick-next:before': {
      top: '1.5rem',
      left: '1.5rem',
      content: '" "',
      position: 'absolute',
      backgroundImage: `url(${ArrowRight})`,
      backgroundRepeat: 'no-repeat',
      width: '1.5rem',
      height: '1.5rem',
      transform: ' scale(2, 2)',
    },
  },
  arrowPrevStyle: {
    width: '3rem',
    height: '3rem',
    pzIndex: 10,
    '&.slick-prev:before': {
      top: '1.5rem',
      left: '1.5rem',
      content: '" "',
      position: 'absolute',
      backgroundImage: `url(${ArrowLeft})`,
      backgroundRepeat: 'no-repeat',
      width: '1.5rem',
      height: '1.5rem',
      transform: ' scale(2, 2)',
    },
  },
  boxImg: (props) => ({
    padding: props.paddingImg,
    overflow: 'hidden',
    // eslint-disable-next-line quotes
    [`@media (min-width: 768px)`]: {
      padding: props.paddingImgDesktop,
    },
    '& img::before': {
      display: 'block',
      content: '""',
      paddingTop: '56,25%',
    },
  }),
  image: {
    margin: 0,
  },
  productItem: {
    maxWidth: '20rem',
  },
  [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
    carousel: () => ({
      '& .magic-dots.slick-dots': {
        bottom: 30,
      },
      '&.heroCarousel': {
        margin: '1.5rem',
      },
    }),
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    carousel: (props) => ({
      margin: props.marginCarouselDesktop,
      '& .magic-dots.slick-dots': {
        bottom: props.postionDots,
        '& li': {
          margin: '0 0.5rem',
        },
        '& li button:before': {
          color: theme.colors.bgMedium,
          fontSize: 14,
        },
        '& li button:hover:before, li button:focus:before': {
          color: theme.colors.background,
        },
        '& .slick-active button:before': {
          color: theme.colors.secondary,
        },
      },
      '&.heroCarousel': {
        margin: '1.5rem',
        maxWidth: 1200,
        '& .slick-dots': {
          bottom: '5.5rem',
        },
      },
    }),
  },
}))

export default useStyles
