/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-property-newline */
import _ from 'lodash'
import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  ErrorMessage,
  Field,
  useFormikContext,
} from 'formik'
import Select, { components } from 'react-select'
import { isSafari } from 'react-device-detect';
import combineClassNames from '../../helpers/combineClassNames';
import useStyles from './input-phone-style'
import arrowDownIcon from '../../assets/icons/icon_dropdown_arrow.svg'

const Input = ({
  className,
  countryCallingCodeOptions,
  defaultValue,
  error,
  formik,
  disabled,
  label,
  name,
  placeholder,
  required,
  onChange,
  uppercase,
  // styles
  rowStyle,
  labelStyle,
}) => {
  const { t } = useTranslation()
  // const { values, setFieldValue } = useFormikContext()
  const [countryCallingCode, setCountryCallingCode] = useState(_.get(defaultValue, 'countryCallingCode', ''))
  const [localPhoneNumber, setLocalPhoneNumber] = useState(_.get(defaultValue, 'localPhoneNumber', ''))
  const styles = useStyles({
    disabled, error, required, uppercase, isSafari,
  })

  const { errors, values, touched } = useFormikContext()

  useEffect(() => {
    if (_.isFunction(onChange)) {
      onChange({ countryCallingCode, localPhoneNumber })
    }
  }, [countryCallingCode, localPhoneNumber])

  const inputProps = {
    disabled: disabled ? 'disabled' : '',
    id: `input-${name}`,
    className: styles.localePhone,
    // defaultValue: _.get(defaultValue, 'localPhoneNumber', ''),
    name: 'localPhoneNumber',
    placeholder,
    type: 'tel',
    value: localPhoneNumber,
    onChange: (e) => {
      if (e.target.value.length > 1 && e.target.value.charAt(0) === '0') {
        const phone = e.target.value.substr(1, localPhoneNumber.length)
        setLocalPhoneNumber(phone)
      } else {
        setLocalPhoneNumber(e.target.value)
      }
    },
  }

  const selectProps = {
    name: 'countryCallingCode',
    options: countryCallingCodeOptions,
    disabled,
    className: styles.select,
    classNamePrefix: styles.select,
    placeholder: t('ui.dropdown.placeholder'),
    components: {
      DropdownIndicator: (componentProps) => (
        <components.DropdownIndicator {...componentProps}>
          <img src={arrowDownIcon} alt="" />
        </components.DropdownIndicator>
      ),
    },
    value: _.find(countryCallingCodeOptions, { value: `+${_.replace(countryCallingCode, '+', '')}` }),
    onChange: ({ value }) => {
      setCountryCallingCode(_.replace(value, '+', ''))
    },
  }

  const InputComponent = useMemo(() => (
    formik ? Field : 'input'
  ), [formik])

  const SelectComponent = (props) => (
    formik
      ? (
        <Field name="countryCallingCode" type="text">
          {({ field, form }) => (
            <Select
              {...props}
              onBlur={field.onBlur}
            />
          )}
        </Field>
      )
      : <Select {...props} />
  )

  return (
    <div className={combineClassNames([styles.container, className])}>
      {label && (
        <label
          className={combineClassNames([styles.label, labelStyle])}
          htmlFor={`input-${name}`}
        >
          {label}
        </label>
      )}
      <div className={combineClassNames([styles.row, rowStyle])}>
        <SelectComponent {...selectProps} />
        <InputComponent
          {...inputProps}
        />
      </div>
      { (errors.phone && values.phone) || (errors.phone && touched.localPhoneNumber) ? <p className={styles.errorStyle}>{errors.phone}</p> : '' }
    </div>
  )
}

Input.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
}

Input.defaultProps = {
  type: 'tel',
  name: '',
  disabled: false,
  placeholder: '',
  required: false,
}

export default Input
