import { useContext } from 'react'
import GoogleMapsContext from './google-maps-context'

/**
 * useGoogleMaps
 * The custom hook which provided to external use via context
 */
const useGoogleMaps = () => {
  const context = useContext(GoogleMapsContext);
  return context
};

export default useGoogleMaps;
