/* eslint-disable no-unused-vars */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSystemSettings } from 'react-omnitech-api'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { setParamsToTrack } from '../../helpers'
import { useI18n } from '../../hook'

function SEO({
  description,
  lang,
  meta,
  img,
  link = [],
  title,
  titleTemplate,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            version
            googleSiteVerification
          }
        }
      }
    `,
  )
  const { getSystemSetting } = useSystemSettings()
  const themeConfig = getSystemSetting('theme.ecom.config', {})
  const siteName = getSystemSetting('frontend.site_name') || site.siteMetadata.title
  const favIcon = _.get(themeConfig, 'config.favicon', '')
  const metaDescription = description || site.siteMetadata.description
  const { currentLocale } = useI18n()
  let mergedLink = []
  let mergedMeta = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      name: 'appVersion',
      content: site.siteMetadata.version,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: img,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: site.siteMetadata.author,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:image',
      content: img,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ]
  mergedLink = mergedLink.concat(link)
  mergedMeta = mergedMeta.concat(meta)

  // add google-site-verification if exist in config
  if (!_.isEmpty(site.siteMetadata.googleSiteVerification)) {
    mergedMeta = mergedMeta.concat({
      name: 'google-site-verification',
      content: site.siteMetadata.googleSiteVerification,
    })
  }

  const seoTitle = title || site.siteMetadata.title
  const seoTitleTemplate = titleTemplate || (title
    ? `%s | ${siteName}`
    : '%s')

  if (!_.isEmpty(favIcon)) {
    mergedLink = mergedLink.concat([
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: favIcon,
      },
    ])
  }
  useEffect(() => {
    setParamsToTrack({
      title: seoTitle,
    })
  }, [seoTitle])

  return (
    <Helmet
      htmlAttributes={{
        lang: currentLocale.locale,
      }}
      title={seoTitle}
      titleTemplate={seoTitleTemplate}
      meta={mergedMeta}
      link={mergedLink}
    />
  )
}

SEO.defaultProps = {
  lang: 'en-HK',
  meta: [],
  description: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
