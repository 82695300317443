import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { useCouponMarketplace } from '../../../../hook'
import useStyles from './coupon-marketplace-tabs-style'
import { orderMonths } from '../../../../helpers'
import MessageScreen from '../../../message-screen'
import CouponMarketplaceHeader from '../coupon-marketplace-header'
import CouponsTabPanel from './coupons-tab-panel'
import Spinner from '../../../spinner'

const CouponMarketplaceTabsView = (props) => {
  const {
    coupons,
    couponTokens,
    hasMoreCoupons,
    hasMoreCouponTokens,
    isCouponsReady,
    isCouponTokensReady,
    userCurrentPoints,
    onFetchCouponsNextPage,
    onFetchCouponTokensNextPage,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  const {
    onCouponMarketplaceClose,
    onCouponDetailOpen,
  } = useCouponMarketplace()

  const [tabIndex, setTabIndex] = useState(0);
  const [couponsOrdByMonths, setCouponsOrdByMonths] = useState([])
  const [couponTokensOrdByMonths, setCouponTokensOrdByMonths] = useState([])

  const getCouponToItem = (coupon = {}) => {
    const {
      description,
      discountType,
      discountValue,
      endAt,
      name,
      redeemableAvailableForUserRedeem,
      redeemableRequiredLoyaltyPoints,
      startAt,
    } = coupon
    const displayTime = `${moment(startAt).format('DD.MM.YYYY')} - ${moment(endAt).format('DD.MM.YYYY')}`

    return {
      imageUrl: _.get(coupon, 'defaultImage.versions.webSmall'),
      title: name,
      discountType,
      discountValue,
      description,
      displayTime,
      displayTimeLabel: t('ui.minicartCoupons.coupons.redemptionPeriod'),
      status: '',
      disabled: !redeemableAvailableForUserRedeem,
      requiredPoints: redeemableRequiredLoyaltyPoints,
    }
  }

  const getCouponTokenToItem = (couponToken = {}) => {
    const {
      coupon,
      expiresAt: endAt,
      isValid,
      startAt,
    } = couponToken
    const {
      description,
      discountType,
      discountValue,
      name,
    } = coupon || {}
    const displayTime = `${moment(startAt).format('DD.MM.YYYY')} - ${moment(endAt).format('DD.MM.YYYY')}`

    return {
      imageUrl: _.get(coupon, 'defaultImage.versions.webSmall'),
      title: name,
      discountType,
      discountValue,
      description,
      displayTime,
      displayTimeLabel: t('ui.minicartCoupons.myCoupons.validPeriod'),
      status: '',
      disabled: !isValid,
    }
  }

  const handleOpenCouponTab = () => {
    handleTabSelect(1)
  }

  const handleTabSelect = (index) => {
    setTabIndex(index)
  }

  /**
   * grouping coupons by month
   */
  useEffect(() => {
    if (_.isEmpty(coupons)) return

    const couponsByMonth = orderMonths(coupons, 'endAt', true)
    setCouponsOrdByMonths(couponsByMonth)
  }, [coupons])

  /**
   * grouping coupon tokens by month
   */
  useEffect(() => {
    if (_.isEmpty(couponTokens)) return

    const couponTokensByMonth = orderMonths(couponTokens, 'expiresAt', true)
    setCouponTokensOrdByMonths(couponTokensByMonth)
  }, [couponTokens])

  return (
    <div className={styles.container}>
      <CouponMarketplaceHeader
        title={t('ui.minicartCoupons.heading')}
        onCloseDetail={onCouponMarketplaceClose}
      />
      <Tabs
        selectedIndex={tabIndex}
        className={styles.tabContainer}
        onSelect={handleTabSelect}
      >
        <TabList className={styles.couponsTabs}>
          <Tab className={styles.couponsTab}>{t('ui.minicartCoupons.myCoupons.tab')}</Tab>
          <Tab className={styles.couponsTab}>{t('ui.minicartCoupons.coupons.tab')}</Tab>
        </TabList>
        <TabPanel className={styles.couponsPanel}>
          {
            isCouponTokensReady
              ? (
                <>
                  {
                    couponTokensOrdByMonths.length > 0
                      ? (
                        <CouponsTabPanel
                          groups={couponTokensOrdByMonths}
                          hasMore={hasMoreCouponTokens}
                          loadMore={onFetchCouponTokensNextPage}
                          getItemData={getCouponTokenToItem}
                          type="couponToken"
                          onSelect={onCouponDetailOpen}
                        />
                      )
                      : (
                        <div className={styles.noContent}>
                          <MessageScreen
                            message={t('ui.minicartCoupons.myCoupons.message')}
                            button={{
                              className: styles.button,
                              text: t('ui.minicartCoupons.myCoupons.button'),
                              onClick: handleOpenCouponTab,
                            }}
                          />
                        </div>
                      )
                  }
                </>
              )
              : (
                <div className={styles.loading}>
                  <Spinner />
                </div>
              )
          }
        </TabPanel>
        <TabPanel className={styles.couponsPanel}>
          <div className={styles.pointsCoupons}>
            <p>
              <Trans i18nKey="ui.minicartCoupons.coupons.points">
                My Points
                <span>{{ points: userCurrentPoints }}</span>
              </Trans>
            </p>
          </div>
          {
            isCouponsReady
              ? (
                <>
                  {
                    couponsOrdByMonths.length > 0
                      ? (
                        <CouponsTabPanel
                          groups={couponsOrdByMonths}
                          hasMore={hasMoreCoupons}
                          loadMore={onFetchCouponsNextPage}
                          getItemData={getCouponToItem}
                          type="coupon"
                          onSelect={onCouponDetailOpen}
                        />
                      )
                      : (
                        <div className={styles.noContent}>
                          <MessageScreen
                            message={t('ui.minicartCoupons.myCoupons.message')}
                            button={{
                              className: styles.button,
                              text: t('ui.minicartCoupons.myCoupons.button'),
                              navigate: '/',
                            }}
                          />
                        </div>
                      )
                  }
                </>
              )
              : (
                <div className={styles.loading}>
                  <Spinner />
                </div>
              )
          }
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default CouponMarketplaceTabsView
