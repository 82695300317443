/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import moment from 'moment'
import {
  useOrderMethod,
} from 'react-omnitech-api'
import useCurrentOrderingPeriod from '../use-current-ordering-period'

export default function useSku() {
  const {
    orderMethod,
    store,
  } = useOrderMethod()
  const currentSlotCode = useCurrentOrderingPeriod()

  const isSoldOut = (sku) => {
    if (!_.has(sku, 'stockLevel')) return false
    return (
      _.get(sku, 'notAllowedToCheckout', false)
      || _.isNumber(_.get(sku, 'stockLevel', null)) ? _.get(sku, 'stockLevel', 0) === 0 : false
    )
  }

  const isAllowToCheckoutFnb = (sku, exclusions = {}) => {
    const { menuFilter = [], menuNotFilter = [] } = _.get(sku, 'meta', {})
    const dayOfWeek = moment().format('dddd')
    const storeCode = _.get(store, 'code')
    const todayOpenTimeSlots = _.get(store, 'todayOpenTimeSlots', [])
    const disableTakeAwayOrdering = _.get(store, 'meta.disableTakeAwayOrdering', false)
    // check method, store, timeslot, dayOfWeek
    const filtersToCheck = _.compact([
      !_.get(exclusions, 'commerceChannel', false) && _.get(orderMethod, 'commerceChannel'),
      !_.get(exclusions, 'store', false) && storeCode,
      !_.get(exclusions, 'timeSlot', false) && currentSlotCode,
      !_.get(exclusions, 'dayOfWeek', false) && dayOfWeek,
    ])
    const isWithinOpenTimeSlots = !_.isEmpty(
      _.find(todayOpenTimeSlots, ({ start, end }) => moment().isBetween(start, end)),
    )

    // FOR TEST ONLY!!!
    // console.log('%c ALLOW ALL SKUS TO CHECKOUT - PLS COMMENT ME OUT!', 'color:red')
    // return true

    return (
      _.isEmpty(_.difference(filtersToCheck, menuFilter))
      && _.isEmpty(_.intersection(filtersToCheck, menuNotFilter))
      && (!isSoldOut(sku) || _.get(exclusions, 'stockLevel', false))
      && (isWithinOpenTimeSlots || _.get(exclusions, 'storeOpeningHours', false))
      && !disableTakeAwayOrdering
      // && !(disableTakeAwayOrdering && _.isEqual(method, 'take_away_hkd'))
    )
  }

  return {
    isAllowToCheckoutFnb,
    isSoldOut,
  }
}
