/* eslint-disable no-unused-expressions */
import _ from 'lodash'
import React, { useState } from 'react'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import {
  useLink,
  useMobileMenu,
  useModal,
  useUser,
} from '../../hook'
import combineClassNames from '../../helpers/combineClassNames'
import isUserProfileComplete from '../../helpers/is-user-profile-complete'
import Footer from '../footer'
import Header from '../header'
import useStyles from './layout-style'
import AccountMenu from '../account-menu'
import MiniCart from '../mini-cart'
import DineInMiniCart from '../dine-in-mini-cart'
import MenuMobile from '../menu-mobil'
import MiniCartCoupon from '../mini-cart-coupons'
import Modal from '../modal'
import Button from '../button'
import OrderMethodModal from '../order-method-modal'

function LayoutView({
  children,
  cartButtonDisabled,
  login,
  onLanguageChange,
  availableLocales,
  currentLocale,
  isOpenSearch,
  handelOpenSearch,
  showOrderMethodBlocker,
  handleOrderMethodBlockerClose,
  onCouponMarketplaceOpen,
  userRegistrationFieldsConfig,
}) {
  const {
    user,
  } = useUser()
  const {
    mobileMenuOpen,
    openMobileMenu,
    closeMobileMenu,
  } = useMobileMenu()
  const modal = useModal()
  const { navigate } = useLink()
  const [headerHeight, setHeaderHeight] = useState(0)
  const memberShipCode = _.get(user, 'membershipCode')
  const qrCode = _.get(user, 'qrCodeValue')
  const theme = useTheme()
  const { t } = useTranslation()
  const styles = useStyles({ headerHeight })
  const extraScripts = _.get(theme, 'config.pages.layout.extraScripts', [{}])
  const onHeaderLayoutChange = (rect) => {
    setHeaderHeight(_.get(rect, 'height', 0))
  }

  const onMemberCodeOpen = () => {
    // check user profile first
    if (
      isUserProfileComplete({
        user,
        config: userRegistrationFieldsConfig,
      })
    ) {
      modal.open({
        title: t('ui.memberModal.title'),
        contentComponent: (
          <>
            <QRCode
              value={qrCode}
              size={170}
            />
            {
              !_.isEmpty(memberShipCode) && (
                <p className={styles.memberNumber}>{memberShipCode}</p>
              )
            }
            <p className={styles.dialogDescription}>{t('ui.memberModal.info')}</p>
          </>
        ),
        buttons: [
          {
            text: t('ui.memberModal.buttons.close'),
            onClick: () => modal.close(),
          },
        ],
      })
    } else {
      modal.open({
        title: t('ui.incompleteUserProfileModal.title'),
        message: t('ui.incompleteUserProfileModal.description'),
        buttons: [
          {
            text: t('ui.incompleteUserProfileModal.buttons.editProfile'),
            onClick: () => {
              modal.close()
              navigate('/account/profile/')
            },
          },
          {
            text: t('ui.incompleteUserProfileModal.buttons.cancel'),
            onClick: () => modal.close(),
          },
        ],
      })
    }
  }

  return (
    <div className={combineClassNames([
      styles.containerFluid,
      mobileMenuOpen && styles.contianerFluidOpen,
    ])}
    >
      <div className={combineClassNames([
        styles.contianerWrapper,
        mobileMenuOpen && styles.contianerWrapperOpen,
      ])}
      >
        <Header
          className={
            combineClassNames([
              styles.header,
              mobileMenuOpen && styles.headerOpen,
              !_.isEmpty(theme.config.backgroundUrl) && styles.headerWithBackground,
            ])
          }
          cartButtonDisabled={cartButtonDisabled}
          openMenuMobile={openMobileMenu}
          onLanguageChange={onLanguageChange}
          availableLocales={availableLocales}
          currentLocale={currentLocale}
          isOpenSearch={isOpenSearch}
          handelOpenSearch={handelOpenSearch}
          onLayoutChange={onHeaderLayoutChange}
          onMemberCodeOpen={onMemberCodeOpen}
          qrCode={qrCode}
        />
        {login && <AccountMenu user={user} handelOpenMemberCode={onMemberCodeOpen} />}
        {
          !showOrderMethodBlocker && (
            <div className={styles.container}>{children}</div>
          )
        }
        <Footer
          className={!_.isEmpty(theme.config.backgroundUrl) && styles.footerWithBackground}
          useSuspense={false}
        />
        <MiniCart />
        <DineInMiniCart />
        <MenuMobile
          login={login}
          openMenuMobile={closeMobileMenu}
          onLanguageChange={onLanguageChange}
          availableLocales={availableLocales}
          currentLocale={currentLocale}
          isOpenSearch={isOpenSearch}
          handelOpenSearch={handelOpenSearch}
          onCouponMarketplaceOpen={onCouponMarketplaceOpen}
          onMemberCodeOpen={onMemberCodeOpen}
          qrCode={qrCode}
        />
        <MiniCartCoupon
          isOutsideCheckout
        />
        <OrderMethodModal
          isOpen={showOrderMethodBlocker}
          onRequestClose={handleOrderMethodBlockerClose}
        />
        <Modal
          dialog
          className={styles.dialogModal}
          overlayClassName={styles.dialogModalOverlay}
          isOpen={modal.isOpen}
        >
          <div className={styles.dialogContainer}>
            <div className={styles.dialogBody}>
              {
                !_.isEmpty(modal.title) && <h1 className={styles.dialogTitle}>{modal.title}</h1>
              }
              {
                !_.isEmpty(modal.message) && (
                  <p className={styles.dialogDescription}>{modal.message}</p>
                )
              }
              {modal.contentComponent}
            </div>
            <div className={styles.dialogButtons}>
              {
                _.map(modal.buttons, (button, index) => (
                  <Button
                    key={`modal_button_${index}`}
                    className={styles.button}
                    dark={index === 0}
                    border={index !== 0}
                    {...button}
                  />
                ))
              }
            </div>
          </div>
        </Modal>
      </div>
      {!_.isEmpty(extraScripts)
        && _.map(extraScripts, (script, idx) => <script key={idx} type={script.type}>{`${script.code}`}</script>)}
    </div>
  )
}

export default LayoutView
