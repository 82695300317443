import URI from 'urijs'
import _ from 'lodash'

/**
 * getProductUrl
 * @param {*} { productId, colorOptionId, sizeOptionId, url, }
 */
export default function getProductUrl({
  productId,
  colorOptionId,
  sizeOptionId,
  url,
}) {
  let productUrl = null
  if (url) {
    const cleanerUrl = new URI(url)
    // use to clean url from Admin panel any symbol [$&+,;=?@#|'<>^*(){}%![]] in Product Title
    productUrl = new URI(`${cleanerUrl.origin()}/${_.compact(_.map(cleanerUrl.segmentCoded(), (segment) => encodeURIComponent(segment.replace(/[$&+,;=?@#|'<>^*(){}%![\]]/g, '')))).join('/')}`)
  } else {
    productUrl = new URI(`/product/${productId}`)
  }
  productUrl.addSearch({
    color: colorOptionId,
    size: sizeOptionId,
  })
  return productUrl.href()
}
