/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { combineClassNames } from '../../helpers'
import HearthOutlinedIcon from '../../assets/icons/icon_heart_outlined.inline.svg'
import HearthSolidIcon from '../../assets/icons/icon_heart_solid.inline.svg'
import useStyles from './favourite-button-style'

function FavouriteButtonView(props) {
  const {
    icon,
    iconButton,
    label,
    like,
    onClick,
    className,
    iconClassName,
    loading,
  } = props
  const styles = useStyles({
    icon, iconButton, like,
  })

  const Icon = like ? HearthSolidIcon : HearthOutlinedIcon
  const classButtonDisabled = loading ? styles.buttonDisabled : ''
  const classIconDisabled = loading ? styles.iconDisabled : ''

  return (
    <button
      type="button"
      disabled={loading}
      onClick={onClick}
      className={combineClassNames([styles.button, className, classButtonDisabled])}
    >
      { (icon || iconButton)
        && (
        <Icon
          className={
            combineClassNames([styles.icon, iconClassName])
          }
        />
        )
        // <img src={favIcon} alt="" className={styles.icon} />
      }
      { !icon && label}
    </button>
  )
}

export default FavouriteButtonView
