/* eslint-disable import/no-cycle */
import React from 'react'
import ContentGroupLineView from './content-group-line-view'

function ContentGroupLineController(props) {
  return (
    <ContentGroupLineView {...props} />
  )
}

export default ContentGroupLineController
