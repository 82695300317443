/* eslint-disable import/prefer-default-export */
import _ from 'lodash'
import trackEventGA from './track-event-ga'
import trackEventTenMax from './track-event-tenmax'
import trackEventFBP from './track-event-fbp'
import trackEventHook from './track-event-hook'

function trackEvent(eventName, options = {}, meta) {
  const { getSystemSetting } = options

  if (_.isEmpty(eventName)) {
    console.warn('trackEvent error: ', 'event name empty.')
    return
  }

  trackEventHook(eventName, options, meta)

  if (!_.isFunction(getSystemSetting)) {
    return
  }

  if (getSystemSetting('analytics.google.enable') === true) {
    trackEventGA(eventName, options, meta)
  }
  if (getSystemSetting('analytics.tenmax.enable')) {
    trackEventTenMax(eventName, options, meta)
  }

  if (getSystemSetting('analytics.facebook.enable')) {
    trackEventFBP(eventName, options, meta)
  }
}

export default trackEvent
