/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect } from 'react'
import _ from 'lodash'
import useStyles from './coupon-marketplace-popup-style'

const CouponMarketplacePopupView = ({
  duration = 5000,
  isOpen,
  message,
  onClose,
}) => {
  const isShow = isOpen && !_.isEmpty(message)

  useEffect(() => {
    if (!isOpen) return

    const timer = setTimeout(() => {
      onClose()
    }, duration)

    return function cleanUp() {
      clearTimeout(timer)
    }
  }, [isOpen])

  // Styles
  const styles = useStyles()

  return isShow
    ? (
      <div
        className={styles.popup}
        onClick={onClose}
        role="presentation"
      >
        <span>{message}</span>
      </div>
    )
    : null
}

export default CouponMarketplacePopupView
