import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    borderTop: `1px solid ${theme.colors.bgDark}`,
    marginTop: '6rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1170,
    margin: [[0, 'auto']],
    minHeight: 132,
    textAlign: 'center',
    padding: '3rem 2rem',
    '& p': {
      margin: '0 -2rem',
      width: 'calc(100% + 4rem)',
      fontSize: '1.2rem',
      padding: '1.5rem',
      borderTop: `1px solid ${theme.colors.bgDark}`,
    },
  },
  checkoutLinks: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: 0,
    paddingBottom: '2rem',
    '& li': {
      margin: '1rem 0',
      lineHeight: 1.14,
      '& a': {
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: theme.colors.primary,
        lineHeight: 1.14,
        fontWeight: 600,

      },
    },
  },

  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    container: {
      flexDirection: 'column',
      '& p': {
        margin: 0,
        width: '100%',
        fontSize: '1.2rem',
        padding: '1.5rem',
        borderTop: 'none',
      },
    },
    checkoutLinks: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 0,
      margin: '0 1rem',
      '& ul': {
        display: 'flex',
        '& li': {
          margin: '0 1rem',
        },
      },
    },
  },
}))

export default useStyles
