/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: ({ align } = {}) => {
    const justifyContentMapper = {
      left: 'flex-start',
      right: 'flex-end',
    }
    return {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      justifyContent: _.get(justifyContentMapper, align, align),
    }
  },
  divider: {
    width: '0.1rem',
    height: '1em',
    margin: '0 1rem 0.16667em',
    backgroundColor: theme.colors.bgDark,
  },
}))

export default useStyles
