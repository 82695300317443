import _ from 'lodash'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import head from 'lodash/fp/head'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import includes from 'lodash/fp/includes'

export default function getLinkTargeyByID(type, id, data = {}) {
  switch (type) {
    case 'Department': {
      return flow(
        filter({ id }),
        head,
        get('code'),
      )(data.departments)
    }
    case 'Category': {
      return {
        category: flow(
          filter({ id }),
          head,
          get('code'),
        )(data.categories),
        departament: flow(
          map((item) => (includes(item.categoryIds, id) ? item.code : null)),
          head,
          get('code'),
        )(data.departments),
      }
    }

    case 'Brand': {
      return _.pick(_.head(_.filter(data.brands, { id })), 'code').code
    }

    default:
      break;
  }
}
