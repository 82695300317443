/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import useStyles from './pagination-style'

const PaginationView = (props) => {
  const styles = useStyles()
  const { pagination, handlePageClick } = props
  const initialPage = pagination.currentPage - 1
  const pageCount = pagination.totalPages
  const forcePage = pagination.currentPage - 1

  return (
    <div className={styles.container}>
      <ReactPaginate
        activeClassName="active"
        breakClassName="break-me"
        breakLabel="..."
        containerClassName={styles.paginationStyle}
        forcePage={forcePage}
        initialPage={initialPage}
        marginPagesDisplayed={0}
        nextClassName="icon icon-right"
        nextLabel={' '}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        pageRangeDisplayed={3}
        previousClassName="icon icon-left"
        previousLabel={' '}
        subContainerClassName="pages pagination"
      />
    </div>
  )
}

PaginationView.propTypes = {
  pagination: PropTypes.object.isRequired,
}

export default PaginationView
