import React from 'react'
import ContentGroup from '../content-group'
import {
  useThemeConfig,
} from '../../hook'
import useStyles from './payment-logos-style'

const PaymentLogosView = () => {
  const { paymentLogos, boxLogos } = useStyles()
  const { getConfig, getContentGroup } = useThemeConfig()

  // local variable
  const paymentLogosContentGroupProps = {
    code: getContentGroup('config.ui.paymentLogos.contentGroup', 'ecom_payment_logos'),
    template: getConfig('config.ui.paymentLogos.template', 'List'),
  }
  const isAvailable = getConfig('config.ui.paymentLogos.available', false)
  if (!isAvailable) return null
  return (
    <div className={paymentLogos}>
      <div className={boxLogos}>
        <ContentGroup {...paymentLogosContentGroupProps} />
      </div>
    </div>
  )
}

export default PaymentLogosView
