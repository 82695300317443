import _ from 'lodash'
import React, { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useI18n, useSystemSettings } from 'react-omnitech-api'

import LocaleContext from './locale-context'
import setupI18next from './setup-i18next'

const withI18next = () => (Comp) => {
  const i18n = setupI18next()

  function I18nHOC(props) {
    // prepare hook
    const { getSystemSetting } = useSystemSettings()

    // local variable
    const availableLocales = _.get(props, 'config.locale.availableLocales', ['en-HK'])
    const defaultLocale = _.get(props, 'config.locale.defaultLocale', 'en-HK')
    const localeConifg = _.get(props, 'config.locale.config', {})
    const locale = _.get(props, 'pageContext.locale', defaultLocale)
    const currentLocale = localeConifg[locale] || localeConifg[defaultLocale]
    const verdorKey = getSystemSetting('vendor_specific_system_setting_key', '')
    const translationOverride = _.get(
      getSystemSetting(`vendor_specific_settings.${verdorKey}.translation.override.ecom`, {}),
      _.camelCase(locale),
      {},
    )

    // get change language from for omnitech lib
    const { changeLanguage: changeOmnitechLanguage } = useI18n()

    // load translation resources base on website locale
    function addResources(pageContext) {
      if (pageContext && pageContext.localeResources && pageContext.localeResources.translation) {
        const {
          locale: lng,
          localeResources: { translation },
        } = pageContext
        const resourceBundle = i18n.getResourceBundle(lng, 'translation')
        if (!_.isEqual(_.merge(translation, translationOverride), resourceBundle)) {
          i18n.addResourceBundle(
            lng,
            'translation',
            _.merge(translation, translationOverride),
          )
        }
      }
    }

    function getLocaleConfig(localeCode) {
      return localeConifg[localeCode] || {}
    }

    function getLocalePath(localeCode) {
      const config = getLocaleConfig(localeCode)
      return config.path || 'en-HK'
    }

    function changeLanguage() {
      const { pageContext } = props
      addResources(pageContext)
      i18n.changeLanguage(pageContext.locale)
      changeOmnitechLanguage(pageContext.locale)
    }

    // sync language between website and omnitech lib
    useEffect(() => {
      changeLanguage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (!_.isEmpty(translationOverride)) {
        changeLanguage()
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translationOverride])

    return (
      <LocaleContext.Provider
        value={{
          availableLocales,
          currentLocale,
          locale,
          getLocalePath,
        }}
      >
        <I18nextProvider i18n={i18n}>
          <Comp {...props} />
        </I18nextProvider>
      </LocaleContext.Provider>
    )
  }

  return I18nHOC
}

export default withI18next
