import React from 'react'
import useStyles from './col-style'
import combineClassNames from '../../helpers/combineClassNames';

const ColView = ({
  children, className, ...others
}) => {
  const { col } = useStyles({
    ...others,
  })
  return (
    <div className={combineClassNames([col, className])}>
      {children}
    </div>
  )
}

export default ColView
