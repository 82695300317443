import _ from 'lodash'
import React, { useMemo } from 'react'
import CurrencyFormat from '../currency-format'
import useStyles from './price-tag-style'

const PriceTagView = ({
  label,
  originalPrice,
  sellPrice,
  currency,
  align = 'left',
  prefix,
  suffix,
  className,
}) => {
  const isPrice = (value) => _.isNumber(value) || _.isString(value)
  const withLabel = useMemo(() => !_.isEmpty(label), [label])
  const onSale = useMemo(
    () => _.toNumber(originalPrice) !== _.toNumber(sellPrice)
          && isPrice(originalPrice)
          && isPrice(sellPrice),
    [originalPrice, sellPrice],
  )
  const styles = useStyles({
    onSale,
    withLabel,
    align,
    prefix,
    suffix,
  })
  return (
    <div className={className}>
      <div className={styles.container}>
        {
          withLabel && (
            <p className={styles.label}>{label}</p>
          )
        }
        <p className={styles.price}>
          {onSale && (
            <CurrencyFormat
              value={_.toString(originalPrice)}
              currency={currency}
              className={styles.originalPrice}
            />
          )}
          <CurrencyFormat
            value={_.toString(sellPrice)}
            currency={currency}
            className={styles.sellPrice}
          />
        </p>
      </div>
    </div>
  )
}

export default PriceTagView
