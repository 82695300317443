/* eslint-disable react/no-danger */
import React from 'react'
import _ from 'lodash'
import { useTheme } from 'react-jss'
import QRCode from 'qrcode.react'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import useStyles from './coupon-marketplace-coupon-info-style'
import { combineClassNames } from '../../../../helpers'
import IconTime from '../../../../assets/icons/icon_time.svg'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'

const CouponMarketplaceCouponInfoView = ({
  coupon = {},
  isRedeem = false,
  startAt,
  endAt,
  validPeriod = false,
  qrCodeValue,
}) => {
  // prepare hook
  const theme = useTheme()
  const { t } = useTranslation()
  // local variables
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  const imageUrl = _.get(coupon, 'defaultImage.versions.galleryLarge')
  const noImage = _.isEmpty(imageUrl)
  const couponImageUrl = imageUrl || placeholder

  const requiredManualTrigger = _.get(coupon, 'cartDiscount.requiredManualTrigger', false)

  const {
    name: title,
    redeemableRequiredLoyaltyPoints = '',
    description,
  } = coupon || {}

  const styles = useStyles({})
  return (
    <div className={styles.containerCart}>

      {/* Coupon Image */}
      <figure className={combineClassNames([styles.photo, noImage && styles.noImage])}>
        <div>
          <img src={couponImageUrl} alt="coupon banner" />
        </div>
      </figure>

      <div>
        <div className={styles.info}>
          {/* Coupon Name */}
          <h4>{title}</h4>

          {/* Period */}
          {
            validPeriod && (
              <div className={styles.validPeriod}>
                <Trans i18nKey="ui.minicartCoupons.couponInfo.validPPeriod" />
              </div>
            )
          }
          <div className={styles.dateStyle}>
            <img className={styles.dateIcon} src={IconTime} alt="icon time" />
            <span>
              {moment(startAt).format('DD.MM.YYYY')}
              {' '}
              -
              {' '}
              {moment(endAt).format('DD.MM.YYYY')}
            </span>
          </div>
        </div>

        {/* Coupon required points for redeem */}
        {
          isRedeem && (
            <div className={styles.pointsBox}>
              <p>
                <Trans i18nKey="ui.minicartCoupons.couponDetail.redeemFor" />
              </p>
              <p>
                <Trans
                  i18nKey="ui.minicartCoupons.couponDetail.redeemableRequiredLoyaltyPoints"
                  values={{
                    points: redeemableRequiredLoyaltyPoints,
                  }}
                >
                  {{ points: redeemableRequiredLoyaltyPoints }}
                  <span>pts</span>
                </Trans>
              </p>
            </div>
          )
        }

        {/* Coupon description */}
        <div
          className={styles.conditions}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {/* Coupon QR */}
        <div className={styles.qrCouponStyle}>
          { !_.isEmpty(qrCodeValue)
          && !requiredManualTrigger
          && (
            <>
              <QRCode
                value={qrCodeValue}
                size={240}
              />
              <p className={styles.qrInfo}>
                {t('ui.minicartCoupons.couponDetail.textQR')}
              </p>
            </>

          )}

        </div>

      </div>
    </div>
  )
}

export default CouponMarketplaceCouponInfoView
