import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    margin: '0 2rem',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.text}`,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: 'calc(100% - 4.4rem)',
    height: '5rem',
    border: 'none',
    fontSize: '1.6rem',
    lineHeight: 1.5,
    paddingTop: '0.6rem',
    paddingLeft: '0.6rem',
    backgroundColor: 'transparent',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.text,
    '&:focus': {
      outline: 0,
    },
    '&::-moz-placeholder': {
      opacity: 0.3,
      color: theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.text,
    },
    '&::-webkit-input-placeholder': {
      opacity: 0.3,
      color: theme.config.headerColorText
        ? theme.config.headerColorText
        : theme.colors.text,
    },

  },
  searchButton: {
    minWidth: '4.4rem',
    padding: '1rem',
    // transform: 'translate(0.2rem, 0.6rem)',
    backgroundColor: 'transparent',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      minHeight: '5rem',
    },
  },
  searchIcon: {
    width: '1.6rem',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.text,
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    containerFluid: {
      margin: 0,
    },
  },
}))

export default useStyles
