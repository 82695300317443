import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: ({ withLabel, align } = {}) => ({
    fontSize: withLabel ? '0.6667em' : '1em',
    textAlign: align,
  }),
  label: {
    fontSize: 'max(0.6em, 1.1rem)',
    fallbacks: {
      fontSize: '0.6em',
    },
    lineHeight: 1.2,
    color: theme.colors.bgDark,
    margin: 0,
  },
  price: {
    fontSize: '1em',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  originalPrice: {
    fontSize: 'max(0.5em, 1rem)',
    fallbacks: {
      fontSize: '0.5em',
    },
    fontWeight: 500,
    textDecoration: 'line-through',
    color: theme.colors.bgDark,
    marginRight: '0.2rem',
  },
  sellPrice: ({ onSale, prefix, suffix } = {}) => ({
    color: onSale ? theme.colors.alert : 'inherit',
    ...prefix && {
      '&::before': {
        content: `"${prefix}"`,
      },
    },
    ...suffix && {
      '&::after': {
        content: `"${suffix}"`,
      },
    },
  }),
}))

export default useStyles
