/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react'
import _ from 'lodash'
import useStyles from './toggle-style'

const CustomToggle = (props) => {
  const {
    style, terminal, onClick, node,
  } = props
  const styles = useStyles({ node })
  return (
    <div>
      {!terminal && !_.isEmpty(node.children) ? (
        <div
          className={styles.container}
          onClick={onClick}
        >
          <div className={styles.iconStyle} />
        </div>
      ) : null}
    </div>

  )
}

export default CustomToggle
