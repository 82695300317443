/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import _ from 'lodash'

function trackEventTenMax(eventName, options, meta = {}) {
  if (_.isUndefined(window.tpq)) return

  let order;
  let orderItems;
  let product;
  let contentId;
  let tpqVar;
  let skus
  const currencyCode = document.getElementsByTagName('body')[0].data('currency-code')

  switch (eventName) {
    case 'viewCategory':
      tpqVar = {
        contentCategory: document.title,
      };
      tpq('track', 'category', tpqVar)
      break
    case 'viewProductDetail': {
      product = meta.product || {}
      const { brands = [] } = product
      const brandNames = brands.map((brand) => brand.name)

      tpqVar = {
        contentName: product.title,
        // CP: The TenMax api requires a string here.
        contentIds: _.isEmpty(meta.skuCode) ? product.code : meta.skuCode,
        contentType: 'product',
        contentCategory: document.title,
        price: meta.price,
        contentBrand: brandNames.join(', '),
        value: meta.price,
      }
      tpq('track', 'ViewContent', tpqVar)
      break
    }
    case 'customerAddToCart':
      // add to cart
      product = meta.product || {}
      tpqVar = {
        contentName: [
          product.title,
        ],
        // CP: The TenMax api requires an array here but we can only add 1 sku every time
        contentIds: [
          _.isEmpty(meta.sku_code) ? product.code : meta.skuCode,
        ],
        contentValues: [
          meta.price,
        ],
        contentNumItems: [
          options.eventValue,
        ],
        content_type: 'product',
        currency: currencyCode,
        value: meta.price,
      }
      tpq('track', 'AddToCart', tpqVar)
      break
    case 'customerCheckoutCompleteOrder':

      order = meta.order || {}
      orderItems = meta.orderItems || []
      skus = meta.skus || []
      title = meta.title || ''
      tpqVar = {
        contentName: _.map(orderItems, (n) => _.get(n, 'product.title')),
        contentIds: _.map(orderItems, (n) => _.find(skus, { id: n.skuId }).code),
        contentValues: _.map(orderItems, (n) => _.get(n, 'priceDetails.propertyUnitPrice')),
        contentNumItems: _.map(orderItems, (n) => _.get(n, 'quantity')),
        contentType: 'product',
        numItems: orderItems.length,
        currency: order.currencyCode,
        value: order.totalPrice,
        orderId: order.uuid,
      }
      tpq('track', 'Purchase', tpqVar)
      break
  }
}

export default trackEventTenMax
