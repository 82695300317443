import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './info-box-style'
import Title from '../title-detail'

const InfoBoxView = ({ title, content }) => {
  const { infoBox } = useStyles()
  return (
    <div className={infoBox}>
      <Title
        margin="0 0 1.2rem"
        text={title}
      />
      <div>
        <p>{content}</p>
      </div>
    </div>
  )
}
InfoBoxView.protoType = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

InfoBoxView.defaultProps = {
  content: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero, vitae commodo nunc quam et ligula. Ut nec ipsum sapien.',
}

export default InfoBoxView
