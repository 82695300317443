import _ from 'lodash'
import camelCaseKeys from 'camelcase-keys'

export default function isUserProfileComplete({
  config = {},
  user = {},
}) {
  const cfg = camelCaseKeys(config)
  const fields = _.keys(cfg)
  const requiredFields = _.filter(fields, (field) => _.get(cfg, `${field}.required`, false))
  const requiredFieldsInString = _.difference(
    _.filter(fields, (field) => _.get(cfg, `${field}.required`, false)),
    ['email', 'emailConfirmation', 'password', 'passwordConfirmation', 'meta'],
  )
  return !_.some(requiredFieldsInString, (requiredField) => _.isEmpty(_.get(user, requiredField)))
          && (
            _.includes(requiredFields, 'email')
              ? !_.get(user, 'isSysGeneratedEmail', false)
                && !_.isEmpty(_.get(user, 'email'))
              : true
          )
  // || !_.isEmpty(_.get(user, 'membershipCode'))
}
