/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyFormat from '../currency-format'
import CustomLables from '../custom-labels'
import FavouriteButton from '../favourite-button'
import Link from '../link'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './product-card-style'
// TODO add color carousel in Product List
// import ColorCarousel from '../color-carousel'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductCardView = (props) => {
  const styles = useStyles()

  return (
    <div className={styles.productCard}>
      <div className={styles.figure}>
        <div className={styles.image} />
      </div>
      <div className={styles.content}>
        <div className={styles.title} />
        <div className={styles.variant} />
        <div className={styles.price} />
      </div>
    </div>
  )
}

export default ProductCardView
