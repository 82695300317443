import _ from 'lodash'

/**
 * getAddressFormInitialValues
 * base on form config to generate a initial value for address form
 *
 * @param { defaultCountryCode, formConfig, isEdit, address, user }
 */
export default function getAddressFormInitialValues({
  defaultCountryCode = '',
  formConfig,
  isEdit,
  address,
  user,
}) {
  const userPhone = _.get(user, 'phone', 'NoPhoneNumber')
  const addressPhone = _.get(address, 'phone', userPhone)
  const countryCallingCode = _.get(address, 'countryCallingCode', _.get(formConfig, 'callingCode', '')).replace(/^\+/, '')
  const phoneRegExpObj = RegExp(`^(?:\\+*${countryCallingCode})(?<localPhoneNumber>[0-9_*#-]+)`)
  const localPhoneNumber = _.get(phoneRegExpObj.exec(addressPhone), 'groups.localPhoneNumber', '')
  const values = isEdit
    ? {
      ...address,
      country: address.countryCode,
    }
    : {
      firstName: _.get(user, 'firstName') || '',
      lastName: _.get(user, 'lastName') || '',
      phone: _.get(user, 'phone') || '',
      phoneData: _.get(user, 'phoneData', {}),
      country: defaultCountryCode,
      zip: '',
      state: '',
      city: '',
      district: '',
      streetLine1: '',
      streetLine2: '',
      isPrimary: false,
      isBilling: false,
    }
  // for phone dropdown
  values.countryCallingCode = countryCallingCode
  values.localPhoneNumber = localPhoneNumber
  return values
}
