/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
import _ from 'lodash'
import groupCartLineProperties from './group-cart-line-properties'

function trackEventHook(eventName, options, meta = {}) {
  let page
  let title
  let order
  let products
  let product
  let orderItems
  let skus
  let user

  const customerCheckoutSteps = {
    customerCheckoutViewCart: 1,
    customerCheckoutDeliveryAddress: 2,
    customerCheckoutConfirm: 3,
  }

  const trigger = (eventType, data) => {
    const event = new CustomEvent(eventType, { detail: data })
    document.dispatchEvent(event)
  }

  // CP: Chunking is required since GA has a 8192 payload size restriction.
  // This function basically splits `obj` into chunks of length `size`
  // and timeout value of 200ms to avoid hitting GA's rate limit of
  // 10 requests per second.
  const chunkIt = (obj, size, fn) => {
    _.each(_.chunk(obj, size), (chunk) => {
      window.setTimeout(() => {
        fn(chunk)
      }, 200)
    })
  }

  switch (eventName) {
    case 'routeUpdate':
      page = meta.pagePath;
      if (!_.isEmpty(page)) {
        trigger('omni:pageView', page)
      }
      break
    case 'customerLogin':
    case 'customerRegister':
      user = meta.user || {};
      if (!_.isEmpty(user)) {
        trigger('omni:addSession', {
          ...user,
        })
      }
      break
    case 'customerViewProfile':
      user = meta.user || {};
      if (!_.isEmpty(user)) {
        trigger('omni:viewProfile', {
          ...user,
        })
      }
      break
    case 'customerButtonClick':
      trigger('omni:buttonClick', {
        category: _.get(meta, 'gaSendEventCategory', 'Button'),
        action: _.get(meta, 'gaSendEventAction', 'click'),
        label: _.get(meta, 'gaSendEventLabel', ''),
      })
      break
    case 'viewProductDetail':
      product = meta.product || {}
      if (!_.isEmpty(product)) {
        trigger('omni:viewProduct', {
          id: _.isEmpty(meta.skuCode) ? product.code : meta.skuCode,
          name: product.title,
          price: meta.price,
          quantity: meta.quantity,
        })
      }
      break
    case 'viewProductImpression':
      products = _.get(meta, 'products', [])
      title = _.get(meta, 'title', '')
      if (!_.isEmpty(products)) {
        trigger('omni:viewProductImpression', _.map(products, (product) => ({
          id: _.get(product, 'code'),
          name: _.get(product, 'name'),
          category: _.get(product, 'category'),
          brand: _.get(product, 'brand'),
          variant: _.get(product, 'variant'),
          list: _.get(product, 'list'),
          position: _.get(product, 'position'),
        })))
      }
      break
    case 'customerProductClick':
      product = meta.product || {}
      if (!_.isEmpty(product)) {
        trigger('omni:productClick', {
          id: product.code,
          name: product.title,
          category: product.category,
          brand: product.brand,
          variant: product.variant,
          position: product.position,
          list: product.list,
        })
      }
      break
    case 'customerAddToCart':
      // add to cart
      product = _.get(meta, 'product', {})
      trigger('omni:addToCart', {
        id: meta.skuCode,
        name: product.title,
        price: meta.price,
        quantity: options.eventValue,
        value: meta.value,
        currency: meta.currencyCode,
      })
      break
    case 'customerRemoveFromCart':
      // remove from cart
      trigger('omni:removeFromCart', {
        id: meta.skuCode,
        name: meta.name,
        price: meta.price,
        quantity: meta.quantity,
      })
      break
    case 'customerCheckoutViewCart':
    case 'customerCheckoutDeliveryAddress':
    case 'customerCheckoutConfirm': {
      const gaEcSetActionOptions = {
        step: customerCheckoutSteps[eventName],
        options,
      }
      const { cartLineProperties, title } = meta
      trigger('omni:checkout', {
        step: customerCheckoutSteps[eventName],
        items: _.map(groupCartLineProperties(cartLineProperties), (item) => ({
          id: _.get(item, 'sku.code'),
          name: _.get(item, 'sku.product.title'),
          price: _.get(item, 'priceDetails.mainPropertyUnitPrice'),
          quantity: _.get(item, 'quantity'),
          variant: _.join([
            _.get(item, 'sku.colorOption.name', ''),
            ..._.map(
              _.get(item, 'addons', []),
              ({ colorOption }) => (
                _.get(colorOption, 'name', '')
              ),
            ),
          ], ', '),
        })),
      })
      break
    }
    case 'customerCheckoutCompleteOrder': {
      order = meta.order || {}
      orderItems = meta.orderItems || []
      skus = meta.skus || []
      title = meta.title || ''
      trigger('omni:purchase', {
        id: options.orderReferenceNumber,
        revenue: options.totalPrice,
        coupon: options.coupon,
        shipping: options.delivery,
        currency: order.currencyCode,
        items: orderItems.map((item) => {
          const sku = _.find(skus, { id: item.sku.id })
          return {
            id: _.get(sku, 'code'),
            name: _.get(sku, 'product.title'),
            price: _.get(item, 'priceDetails.propertyUnitPrice'),
            category: _.get(sku, 'product.categories[0].name', ''),
            brand: '',
            variant: _.get(sku, 'colorOption.name', ''),
            quantity: _.get(item, 'quantity'),
          }
        }),
      })
      break
    }
    default:
      break;
  }
}

export default trackEventHook
