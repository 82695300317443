/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'
import ContentGroup from '../content-group'
import Link from '../link'

import useStyle from './footer-style'
import TofugearLogo from '../../assets/icons/tofugear-logo.svg'
import combineClassNames from '../../helpers/combineClassNames'
import {
  useThemeConfig,
} from '../../hook'

const FooterView = ({ className, breakpoints }) => {
  // prepare hook
  const { t } = useTranslation()
  const { getConfig, getContentGroup } = useThemeConfig()
  const siteLogo = getConfig(
    breakpoints.xs
      ? 'config.logoImageMobile'
      : 'config.logoImageDesktop',
    TofugearLogo,
  )
  const logoMinHeightDesktop = getConfig('config.logoMinHeightDesktop')
  const logoMinHeightMobile = getConfig('config.logoMinHeightMobile')

  // local variable
  const footerLinkContentGroupProps = {
    code: getContentGroup('config.ui.footerNavigation.contentGroup', 'ecom_footer_link'),
    template: getConfig('config.ui.footerNavigation.template', 'ContentGroup'),
    footer: getConfig('config.ui.footerNavigation.footer', true),
  }

  const footerLinkTermsContentGroupProps = {
    code: getContentGroup('config.ui.footerLinkTerms.contentGroup', ''),
    template: getConfig('config.ui.footerLinkTerms.template', 'List'),
    footer: getConfig('config.ui.footerLinkTerms.footer', true),
  }

  const footerSocialLinkContentGroupProps = {
    code: getContentGroup('config.ui.footerSocialMedia.contentGroup', 'ecom_footer_social_media'),
    template: getConfig('config.ui.footerSocialMedia.template', 'List'),
    footer: getConfig('config.ui.footerSocialMedia.footer', true),
  }

  const footerFacebookContentGroupProps = {
    code: getContentGroup('config.ui.footerFacebookPageWidget.contentGroup', 'ecom_footer_facebook_page_widget'),
    template: getConfig('config.ui.footerFacebookPageWidget.template', 'FacebookPageWidget'),
    footer: getConfig('config.ui.footerFacebookPageWidget.footer', true),
  }
  const bottomBarContentGroupProps = {
    code: getContentGroup('config.ui.footer.bottomBar.contentGroup'),
    template: getConfig('config.ui.footer.bottomBar.template', 'ContentGroup'),
  }
  const hasWidgetFacebook = !_.isEmpty(_.get(footerFacebookContentGroupProps, 'code'))
  const styles = useStyle({
    siteLogo, hasWidgetFacebook, logoMinHeightDesktop, logoMinHeightMobile,
  })

  // internal states
  const [width, setWidth] = useState('500')
  const [height, seHeight] = useState('210')

  useEffect(() => {
    if (breakpoints.sm) {
      setWidth('340')
      seHeight('280')
    } else {
      setWidth('500')
      seHeight('230')
    }
  }, [breakpoints])

  return (
    <div className={combineClassNames([
      styles.containerFluid,
      className])}
    >
      <div className={styles.containerLines}>
        <div className={styles.container}>
          <div className={styles.contentLinks}>
            <ContentGroup {...footerLinkContentGroupProps} />

            <div className={styles.socialLink}>
              <ContentGroup {...footerSocialLinkContentGroupProps} />
            </div>
          </div>
          {
            hasWidgetFacebook && (
            <div className={styles.witgetLink}>
              <ContentGroup
                height={height}
                width={width}
                {...footerFacebookContentGroupProps}
              />
            </div>
            )
          }
        </div>
      </div>
      <div className={styles.containerLogo}>
        <div className={styles.boxLogo}>
          <h1 className={styles.logo}>
            <Link to="/" className={styles.logoImage} id="logo" />
          </h1>
          <span>{t('ui.footerCheckout.copy')}</span>
        </div>

        <div className={styles.socialLinkLogo}>
          <ContentGroup {...footerSocialLinkContentGroupProps} />
        </div>
      </div>
      { footerLinkTermsContentGroupProps.code && (
        <div className={styles.containerLinksFooter}>
          <ContentGroup {...footerLinkTermsContentGroupProps} />
        </div>
      )}
      {
        bottomBarContentGroupProps.code && (
          <div className={styles.bottomBar}>
            <ContentGroup
              {...bottomBarContentGroupProps}
              lineStyle={styles.bottomBarContent}
            />
          </div>
        )
      }
    </div>
  )
}

export default withBreakpoints(FooterView)
