import _ from 'lodash'
import React from 'react'
import LocationContext from './location-context'

/**
 * LocationProvider
 * Contain most logic handing navigate
 */
export default function LocationProvider({ value: { location, locale }, children }) {
  const replacePattern = new RegExp(`^/${locale}`)
  const state = {
    location: {
      ...location,
      page: _.replace(_.get(location, 'pathname', ''), replacePattern, ''),
    },
  };

  return (
    <LocationContext.Provider value={state}>
      {children}
    </LocationContext.Provider>
  )
}
