/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import useStyles from '../modal-compare-style'
import placeholderImage from '../../../assets/images/icon_image_placeholder.png'
import clearIcon from '../../../assets/icons/icon_clear_grey.svg'
import Button from '../../button'

const Frame = ({ frame, handleRemoveCartLine }) => {
  const styles = useStyles()
  const theme = useTheme()
  const imageUrl = _.get(frame, 'image')
  const [noImage, setNoImage] = useState(_.isEmpty(imageUrl))

  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)

  useEffect(() => {
    if (_.isEmpty(imageUrl) !== noImage) {
      setNoImage(_.isEmpty(imageUrl))
    }
  }, [imageUrl])

  return (
    <div className={styles.frame}>
      {frame
      && frame.id
        ? (
          <>
            <div className={styles.removeItem}>
              <Button
                inline
                onClick={() => handleRemoveCartLine(frame)}
                iconImage={clearIcon}
                className={styles.removeItemButton}
              />
            </div>
            <img
              role="presentation"
              className={noImage ? styles.placeholder : ''}
              src={noImage ? placeholder : imageUrl}
              alt={_.get(frame, 'product.title')}
              onError={() => {
                setNoImage(true)
              }}
            />
          </>
        )
        : ''}
    </div>
  )
}

export default Frame
