/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import moment from 'moment'
import {
  useSystemSettings,
} from 'react-omnitech-api'

export default function useCurrentOrderingPeriod() {
  const { getSystemSetting } = useSystemSettings()
  const vendorKey = getSystemSetting('vendor_specific_system_setting_key')
  const orderingPeriods = getSystemSetting(`vendor_specific_settings.${vendorKey}.ordering_periods`)
  const currentSlotCode = _.reduce(orderingPeriods, (result, orderingPeriod) => {
    const { slotCode, fromTime, toTime } = orderingPeriod
    const timeFormat = 'HH:mm'
    const from = moment(fromTime, timeFormat)
    const to = moment(toTime, timeFormat)
    if (moment().isBetween(from, to)) {
      result = slotCode
    }
    return result
  }, '')
  return currentSlotCode
}
