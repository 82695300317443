import React from 'react'
import PropTypes from 'prop-types'

function AddressFormView({
  children,
  ready,
}) {
  return (
    <div>
      { ready ? children : '' }
    </div>
  )
}

AddressFormView.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AddressFormView
