/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
export default function hexToRgba(hex, opacity = 1) {
  let cssHex = hex
  cssHex = cssHex.replace('#', '')
  cssHex = `0x${cssHex}`
  const r = cssHex >> 16 & 0xFF;
  const g = cssHex >> 8 & 0xFF;
  const b = cssHex & 0xFF;
  return `rgba(${r},${g},${b},${opacity})`;
}
