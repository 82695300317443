/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React, { useMemo } from 'react'
import {
  Field,
} from 'formik'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './input-radio-style'

const InpuRadioView = ({
  className,
  labelClassName,
  radioClassName,
  textClassName,
  checked,
  disabled,
  children,
  id,
  formik,
  label,
  name,
  value,
  onChange,
}) => {
  const styles = useStyles()
  const InputComponent = useMemo(() => (formik ? Field : 'input'), [formik])
  const inputProps = {
    id,
    name,
    type: 'radio',
    value,
    disabled,
    className: styles.radioInput,
  }

  // only onChange is provided and is a function, will pass to `Field
  if (typeof onChange === 'function') {
    inputProps.onChange = onChange
  }
  if (!formik) {
    inputProps.checked = checked
  }

  return (
    <div
      className={
        combineClassNames([styles.radioButton, className, disabled && styles.radioButtonDisabled])
      }
    >
      <label
        className={
          combineClassNames([styles.label, labelClassName, disabled && styles.labelDisabled])
        }
        htmlFor={id}
      >
        <InputComponent {...inputProps} />
        <i className={combineClassNames([styles.radio, radioClassName])} />
        { !_.isEmpty(label) && (
          <span className={combineClassNames([styles.text, textClassName])}>{label}</span>
        )}
        {children}
      </label>
    </div>
  )
}

export default InpuRadioView
