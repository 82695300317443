import { useContext } from 'react'
import MiniCartContext from './mini-cart-context'

/**
 * useLink
 * The custom hook which provided to external use via context
 */
const useMiniCart = () => {
  const context = useContext(MiniCartContext)
  return context
};

export default useMiniCart
