import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  reviewFrom: () => ({
    marginTop: `${theme.gap}rem`,
    textAlign: 'left',
    maxWidth: '47rem',
  }),
  title: {
    fontWeight: 400,
    fontSize: '1.4rem',
    margin: 0,
  },
  signed: {
    fontSize: '1rem',
    lineHeight: 1.2,
    marginBottom: '1.2rem',
    marginTop: '1rem',
    '& span': {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.2,
    },
  },
  login: {
    fontSize: '1rem',
    lineHeight: 1.2,
    marginBottom: '1.2rem',
    marginTop: '1rem',
    '& a': {
      color: 'currentColor',
    },
  },
  rating: {
    marginBottom: '2rem',
  },
  comment: {
    '& form': {
      marginBottom: '1.5rem',
    },
  },
  textArea: {
    width: '100%',
    height: '12rem',
    margin: '1.2rem 0 1rem',
    fontSize: '1.2rem',
    lineHeight: 1.333,
    padding: '1rem',
    appearance: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.bgDark,
    borderRadius: 0,
  },
  controls: {
    width: '22.6rem',
    display: 'flex',
    marginRight: '-2rem',
  },
  button: {
    height: '3.6rem',
    minHeight: 0,
    lineHeight: 1,
    marginRight: '2rem',
    paddingTop: '0.8rem',
    paddingBottom: '1rem',
    fontSize: '1.6rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    reviewFrom: () => ({
      marginTop: `${theme.gap}rem`,
      textAlign: 'left',
      '& textarea': {
        width: '47rem',
      },
    }),
    comment: {
      '& form': {
        marginBottom: '4rem',
      },
    },
  },
}))

export default useStyles
