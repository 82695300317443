/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { useFormikContext } from 'formik'
import { isSafari } from 'react-device-detect';
import useStyles from './birthday-input-style'
import combineClassNames from '../../helpers/combineClassNames';
import arrowDownIcon from '../../assets/icons/icon_dropdown_arrow.svg'
// import Input from '../input'

/**
 * BirthdayInputController
 * customised input for birthday
 * @param {*} param0
 */
function BirthdayInputController({
  className,
  labelClassName,
  label,
  name,
  defaultValue,
  required,
  uppercase,
  onChange,
  disabled,
}) {
  const {
    errors, values,
  } = useFormikContext()

  const [birthday, setBirthday] = useState({
    birthdayYear: defaultValue ? moment(defaultValue).format('YYYY') : '',
    birthdayMonth: defaultValue ? moment(defaultValue).format('MM') : '',
    birthdayDay: defaultValue ? moment(defaultValue).format('DD') : '',
  })

  const yearMin = moment().subtract(120, 'years').format('YYYY')
  const yearMax = moment().subtract(4, 'years').format('YYYY')
  const yearOptions = _.reverse(_.times(_.toInteger(yearMax) - _.toInteger(yearMin), (year) => ({
    value: `${year + _.toInteger(yearMin)}`,
    label: year + _.toInteger(yearMin),
    key: 'birthdayYear',
  })))
  const monthOptions = _.times(12, (month) => ({
    value: `${month + 1}`,
    label: month + 1,
    key: 'birthdayMonth',
  }))
  let dayOptions = _.times(moment(`${birthday.birthdayYear}-${birthday.birthdayMonth}`, 'YYYY-MM').daysInMonth() || 31, (day) => ({
    value: `${day + 1}`,
    label: day + 1,
    key: 'birthdayDay',
  }))

  const [dayOptionsState, setdayOptionsState] = useState(dayOptions)

  const styles = useStyles({
    required,
    isSafari,
  })

  function handleChange({ value, key }) {
    const birthdayMonth = birthday.birthdayMonth || 1

    if (key === 'birthdayYear' && moment(`${value}-${birthdayMonth}`, 'YYYY-MM').daysInMonth() < birthday.birthdayDay) {
      setBirthday({
        ...birthday,
        birthdayDay: '',
        [key]: value.length < 2 ? `0${value}` : value,
      })
    } else if (key === 'birthdayMonth' && moment(value, 'MM').daysInMonth() < birthday.birthdayDay) {
      setBirthday({
        ...birthday,
        birthdayDay: '',
        [key]: value.length < 2 ? `0${value}` : value,
      })
    } else {
      setBirthday({
        ...birthday,
        [key]: value.length < 2 ? `0${value}` : value,
      })
    }
  }

  useEffect(() => {
    if (
      _.isEmpty(birthday.birthdayYear)
      || _.isEmpty(birthday.birthdayMonth)
      || _.isEmpty(birthday.birthdayDay)
    ) {
      onChange('')
      return
    }

    const value = [
      birthday.birthdayYear,
      birthday.birthdayMonth,
      birthday.birthdayDay,
    ].join('-')
    onChange(value)
  }, [birthday])

  useEffect(() => {
    const year = birthday.birthdayYear || 2020
    dayOptions = _.times(moment(`${year}-${birthday.birthdayMonth}`, 'YYYY-MM').daysInMonth() || 31, (day) => ({
      value: `${day + 1}`,
      label: day + 1,
      key: 'birthdayDay',
    }))

    setdayOptionsState(dayOptions)
  }, [birthday])

  const commonSelectProps = {
    className: styles.select,
    classNamePrefix: styles.select,
    placeholder: '',
    onChange: handleChange,
    required,
    disabled,
    components: {
      DropdownIndicator: (componentProps) => (
        <components.DropdownIndicator {...componentProps}>
          <img src={arrowDownIcon} alt="" />
        </components.DropdownIndicator>
      ),
    },
  }

  return (
    <div className={combineClassNames([styles.container, className])}>
      {
        label && (
          <label
            htmlFor="birthdayYear"
            className={combineClassNames([
              styles.label,
              uppercase && styles.labelUpperCase,
              labelClassName,
            ])}
          >
            {label}
          </label>
        )
      }
      <div className={styles.dateInputs}>
        <div className={styles.dateInput}>
          <Select
            name="birthdayYear"
            options={yearOptions}
            {...commonSelectProps}
            value={_.find(yearOptions, { value: _.get(birthday, 'birthdayYear') })}
          />
        </div>
        <div className={styles.dateInput}>
          <Select
            name="birthdayMonth"
            options={monthOptions}
            {...commonSelectProps}
            value={_.find(monthOptions, { value: _.replace(_.get(birthday, 'birthdayMonth', ''), /^0/, '') })}
          />
        </div>
        <div className={styles.dateInput}>
          <Select
            name="birthdayDay"
            options={dayOptionsState}
            {...commonSelectProps}
            value={!_.isEmpty(_.get(birthday, 'birthdayDay')) ? _.find(dayOptionsState,
              { value: _.replace(_.get(birthday, 'birthdayDay', ''), /^0/, '') })
              : ''}
          />
        </div>
      </div>
      {errors[name] && values[name] ? <p className={styles.errorStyle}>{errors[name]}</p> : null}
    </div>
  )
}

export default BirthdayInputController
