import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'block',
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
  },
  img: {
    display: 'block',
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  noImage: {
    color: theme.colors.disabled,
  },
}))

export default useStyles
