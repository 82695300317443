/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useCouponMarketplace } from '../../hook'
import useStyles from './mini-cart-coupons-style'
import Loading from '../loading'
import {
  CouponMarketplaceCouponDetail,
  CouponMarketplaceCouponTokenDetail,
  CouponMarketplacePopup,
  CouponMarketplaceTabs,
  CouponMarketplaceModal,
} from './components/index'

export default function MiniCartCouponView({
  cartCouponTokens,
  onApplyCouponWithCouponMarketplace,
  isOutsideCheckout,
}) {
  const {
    coupon,
    coupons,
    couponToken,
    couponTokens,
    hasMoreCoupons,
    hasMoreCouponTokens,
    isCouponMarketplaceOpen,
    isCouponsReady,
    isCouponRedeemed,
    isCouponTokensReady,
    isCouponDetailOpen,
    isCouponTokenDetailOpen,
    isDetailReady,
    isLoading,
    isModalOpen,
    isPopupOpen,
    modalContent,
    popupMessage,
    userCurrentPoints,
    onFetchCouponsNextPage,
    onFetchCouponTokensNextPage,
    onApplyCoupon,
    onApplyCouponError,
    onApplyCouponSuccess,
    onCouponDetailClose,
    onCouponMarketplaceClose,
    onPopupClose,
    onRedeemCoupon,
  } = useCouponMarketplace()

  const handleApplyCoupon = async (couponTokenToken) => {
    try {
      onApplyCoupon()
      await onApplyCouponWithCouponMarketplace(couponTokenToken)
      onApplyCouponSuccess()
    } catch (error) {
      onApplyCouponError(error)
    }
  }

  useEffect(() => {
    document.body.classList[isCouponMarketplaceOpen ? 'add' : 'remove']('modal-open')
    // if (!isCouponMarketplaceOpen) {
    //   document.body.scrollTop = currenctScrollTop // for safari
    //   document.documentElement.scrollTop = currenctScrollTop // for IE/Firefox/...others
    // }
  }, [isCouponMarketplaceOpen])

  useEffect(() =>
    // remove modal-open class from body when componentDidMount
    () => {
      document.body.classList.remove('modal-open')
      document.getElementsByTagName('body')[0].setAttribute('style', '')
    },
  [])

  // Styles
  const styles = useStyles({ isCouponMarketplaceOpen })

  return (
    <>
      <CSSTransition
        in={isCouponMarketplaceOpen}
        timeout={300}
        unmountOnExit
        classNames="mini-cart"
      >
        <div className={styles.miniCard}>
          <CouponMarketplaceTabs
            coupons={coupons}
            couponTokens={couponTokens}
            hasMoreCoupons={hasMoreCoupons}
            hasMoreCouponTokens={hasMoreCouponTokens}
            isCouponsReady={isCouponsReady}
            isCouponTokensReady={isCouponTokensReady}
            userCurrentPoints={userCurrentPoints}
            onFetchCouponsNextPage={onFetchCouponsNextPage}
            onFetchCouponTokensNextPage={onFetchCouponTokensNextPage}
          />
          <CouponMarketplaceCouponDetail
            coupon={coupon}
            isCouponRedeemed={isCouponRedeemed}
            isDetailOpen={isCouponDetailOpen}
            isDetailReady={isDetailReady}
            isLoading={isLoading}
            userCurrentPoints={userCurrentPoints}
            onCouponDetailClose={onCouponDetailClose}
            onRedeemCoupon={onRedeemCoupon}
          />
          <CouponMarketplaceCouponTokenDetail
            cartCouponTokens={cartCouponTokens}
            couponToken={couponToken}
            isDetailOpen={isCouponTokenDetailOpen}
            isDetailReady={isDetailReady}
            isLoading={isLoading}
            isOutsideCheckout={isOutsideCheckout}
            userCurrentPoints={userCurrentPoints}
            onApplyCoupon={handleApplyCoupon}
            onCouponDetailClose={onCouponDetailClose}
          />
          <CouponMarketplaceModal
            isModalOpen={isModalOpen}
            onCouponMarketplaceClose={onCouponMarketplaceClose}
            {...modalContent}
          />
          <CouponMarketplacePopup
            isOpen={isPopupOpen}
            message={popupMessage}
            onClose={onPopupClose}
          />
        </div>
      </CSSTransition>
      <div className={styles.overlap} onClick={onCouponMarketplaceClose} />
      {
        isLoading && <Loading />
      }
    </>
  )
}
