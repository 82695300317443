/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react'
import ContentGroup from '..'
import ContentGroupTemplate from '../content-group-template'

export default function ContentGroupLineView(props) {
  const {
    id,
    template,
    linkTargetType,
    linkTargetId,
  } = props
  const nestedContentGroupCode = useMemo(() => {
    if (linkTargetType === 'ContentGroup') return linkTargetId
    if (linkTargetType === 'ProductGroup') return linkTargetId
    return null
    // return linkTargetId
  }, [linkTargetId, linkTargetType])

  return (
    <section id={`content-group-line__${id}`}>
      {
        nestedContentGroupCode
          ? (
            <ContentGroup
              {...props}
              code={nestedContentGroupCode}
              template={template}
              id={linkTargetId}
              key={`nested-content-group-${linkTargetId}`}
            />
          )
          : <ContentGroupTemplate {...props} template={template} />
      }
    </section>
  )
}
