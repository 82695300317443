import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './loading-screen-style'

const LoadingScreenView = ({ isFixed }) => {
  const { loadingScreen, loader } = useStyles({ isFixed })
  return (
    <div className={loadingScreen}>
      <div className={loader}> </div>
    </div>
  )
}

LoadingScreenView.propTypes = {
  isFixed: PropTypes.bool,
}

LoadingScreenView.defaultProps = {
  isFixed: true,
}
export default LoadingScreenView
