import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({

  containerFluidSeach: {
    width: '100%',
    marginTop: '0.3rem',
    // maxHeight: '5.3rem',
    overflow: 'hidden',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      maxHeight: ({ isOpenSearch }) => (isOpenSearch ? '7.6rem' : 0),
      transition: 'max-height 0.3s ease-in-out',
      marginBottom: '1.5rem',
    },
  },
  containerSeach: {
    width: '100%',
    padding: 0,
    display: 'flex',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      width: '100%',
      padding: '0 2rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: theme.mediaQueries.desktop,
      margin: [[0, 'auto']],
      padding: 0,
    },
  },
}))

export default useStyles
