import _ from 'lodash'

export default function getOrderPrices(order) {
  const viewTemplate = _.get(order, 'viewTemplate')
  let priceDetails = {}
  if (viewTemplate === 'backward_compatible_line_v1') {
    priceDetails = {
      orderDeliveryFee: _.get(order, 'shippingCharge'),
      orderDiscount: _.get(order, 'discountedPrice'),
      orderSubtotalPrice: _.get(order, 'subtotalPrice'),
      orderTotalPrice: _.get(order, 'totalPrice'),
    }
  } else {
    priceDetails = _.get(order, 'priceDetails')
  }

  return priceDetails
}
