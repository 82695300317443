import React from 'react'
import ProductGroup from '../../product-group'

function TemplateProducGroup(props) {
  const { code, name } = props

  return (
    <ProductGroup
      code={code}
      title={name}
    />
  )
}

export default TemplateProducGroup
