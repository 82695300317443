/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './button-language-style'
import combineClassNames from '../../../helpers/combineClassNames';

const ButtonLanguage = ({
  onLanguageChange,
  availableLocales,
  currentLocale,
}) => {
  const currentLocaleCode = _.get(currentLocale, 'path', '')
  const [active, setActive] = useState(false)
  const styles = useStyles({ active })
  const { t, i18n } = useTranslation()
  const openLanguage = () => {
    setActive(!active)
  }
  return (
    <div className={styles.container}>
      <div className={styles.headerBox}>
        <p>{t('locale', { context: currentLocaleCode })}</p>
        <div
          className={styles.wrapper}
          onClick={openLanguage}
        >
          <div className={styles.iconStyle} />
        </div>
      </div>
      <div className={styles.languageBox}>
        <div className={styles.languageList}>
          {
            _.map(availableLocales, (availableLocale) => (
              <button
                key={`language_item_${availableLocale}`}
                className={combineClassNames([
                  styles.languageListItem,
                  currentLocaleCode === availableLocale && styles.currentLanguage,
                ])}
                type="button"
                onClick={onLanguageChange.bind(this, availableLocale)}
              >
                {t('locale', { context: availableLocale })}
              </button>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ButtonLanguage
