/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useAuth,
  useCart,
  useDeliveryAddresses,
  useOrderMethod,
  useStores,
  useSystemSettings,
} from 'react-omnitech-api'
import {
  useAlert,
  useLocation,
  useLink,
  usePriceTemplate,
  useThemeConfig,
} from '../../hook'
import OrderMethodModalView from './order-method-modal-view'

const CART_INCLUDES = [
  'available_delivery_types',
  'await_confirm_order',
  'cart_line_properties',
  'cart_line_properties.color_option',
  'cart_line_properties.price_details',
  'cart_line_properties.sku',
  'cart_line_properties.product',
  'cart_line_properties.product_addon',
  'cart_line_properties.cart_shipment_id',
  'cart_line_properties.sku_id',
  'price_details',
  'products.product_addons',
  'products.color_option_variant_type',
  'products.size_option_variant_type',
  'skus.active_custom_labels',
  'skus.color_option',
  'skus.product',
  'skus.size_option',
  'skus.stock_level',
  'cart_shipments',
  'cart_shipments.courier_service',
  'cart_shipments.delivery_address',
  'cart_shipments.delivery_time_slot',
  'cart_shipments.inventory_store',
  'cart_shipments.pickup_store',
  'stores.today_open_time_slots',
].join(',')

export default function OrderMethodModalController({
  isOpen = false,
  onRequestClose,
  onContinue,
}) {
  const { getSystemSetting } = useSystemSettings()
  const { auth } = useAuth()
  const { navigate } = useLink()
  const { location } = useLocation()
  const alert = useAlert()
  const { t } = useTranslation()
  const { fetchStores } = useStores()
  const {
    selectableOrderMethod,
  } = useThemeConfig()
  const { code: CART_PRICE_TEMPLATE_KEY } = usePriceTemplate()
  const {
    cartId,
    createCart,
    fetchCart,
    updateCart,
  } = useCart()
  const {
    setOrderMethod,
    setStore,
    setDeliveryAddress,
    orderMethod,
    deliveryAddress,
    store,
  } = useOrderMethod()
  const {
    deleteDeliveryAddresses,
    updateDeliveryAddresses,
    fetchDeliveryAddresses,
  } = useDeliveryAddresses()

  const isLoggedIn = useMemo(() => !_.isEmpty(_.toString(_.get(auth, 'userId', ''))), [auth])
  const themeConfig = getSystemSetting('theme.ecom.config', {})
  const orderMethodOptions = useMemo(() => (
    _.map(selectableOrderMethod, ({ code }) => ({
      label: t('ui.orderMethodModal.options', { context: code }),
      value: code,
    }))
  ), [selectableOrderMethod, t])

  const origOrderMethodItem = useMemo(() => (
    _.find(orderMethodOptions, { value: _.get(orderMethod, 'code') })
  ), [orderMethodOptions, isOpen])
  const initOrderMethodItem = useMemo(() => (
    origOrderMethodItem || _.first(orderMethodOptions)
  ), [orderMethodOptions, origOrderMethodItem])

  const [cart, setCart] = useState()
  const [stores, setStores] = useState([])
  const [storesLoading, setStoresLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(initOrderMethodItem)
  const [selectedStoreItem, setSelectedStoreItem] = useState()
  const [updatedDeliveryAddress, setUpdatedDevliveryAddress] = useState()
  const [showAddressSelector, setShowAddressSelector] = useState(false)
  const [isAddressManagerOpen, setIsAddressManagerOpen] = useState(false)
  const [isChangeStoreDialogOpen, setIsChangeStoreDialogOpen] = useState(false)
  const [isNoStoreDialogOpen, setIsNoStoreDialogOpen] = useState(false)
  const [deliveryAddresses, setDeliveryAddresses] = useState([])
  const [deliveryAddressesLoading, setDeliveryAddressesLoading] = useState(false)
  const [updateOrderMethodInProgress, setUpdateOrderMethodInProgress] = useState(false)
  const [updateCartShipmentInProgress, setUpdateCartShipmentInProgress] = useState(false)
  const [changeOrderMethodFromMenu, setChangeOrderMethodFromMenu] = useState(false)

  const isBlocker = useMemo(() => {
    if (_.isEmpty(orderMethod)) return true
    const { selectStoreBy } = orderMethod
    switch (selectStoreBy) {
      case 'deliveryAddress':
        return _.isEmpty(deliveryAddress)
      default:
        return _.isEmpty(store)
    }
  }, [orderMethod, store, deliveryAddress])
  const isOrderMethodDialogOpen = useMemo(() => (
    isOpen && !isChangeStoreDialogOpen && !isNoStoreDialogOpen
  ), [isOpen, isChangeStoreDialogOpen, isNoStoreDialogOpen])

  const selectedOrderMethod = useMemo(() => (
    _.find(selectableOrderMethod, { code: _.get(selectedItem, 'value') })
  ), [selectedItem, selectableOrderMethod])
  const storeOptions = useMemo(() => {
    // filter here
    const storeListFilter = _.get(selectedOrderMethod, 'storeListFilter', {})
    const filteredStores = _.filter(stores, (st) => (
      // filter by store code
      (!_.has(storeListFilter, 'code')
      || _.includes(
        _.castArray(
          _.get(storeListFilter, 'code', []),
        ),
        _.get(st, 'code', '--'),
      ))
      // filter by any boolean flag in store object
      && _.isMatch(
        st,
        _.pickBy(storeListFilter, _.isBoolean),
      )
    ))
    return _.map(filteredStores, ({ id, name }) => ({
      label: name,
      value: id,
    }))
  }, [stores, selectedOrderMethod])
  const selectedStore = useMemo(() => (
    _.find(stores, { id: _.get(selectedStoreItem, 'value') })
  ), [stores, selectedStoreItem])
  const confirmButtonDisabled = useMemo(() => {
    if (_.isEmpty(selectedOrderMethod)) return true
    const { selectStoreBy } = selectedOrderMethod
    switch (selectStoreBy) {
      case 'deliveryAddress':
        return _.isEmpty(deliveryAddress)
      // case 'takeAway':
      // case 'dineIn':
      // case 'dineInMenu':
      default:
        return _.isEmpty(selectedStore)
    }
  }, [selectedOrderMethod, selectedStore, deliveryAddress])
  const dirty = useMemo(() => (
    !_.isEqual(selectedStore, store) // store updated
    || !_.isEmpty(updatedDeliveryAddress) // delivery address updated
    || !_.isEqual(origOrderMethodItem, selectedItem) // order method updated
  ), [selectedStore, store, updatedDeliveryAddress, origOrderMethodItem, selectedItem])
  const needUserConfirmToClearCartItems = useMemo(() => (
    _.size(_.get(cart, 'cartLineProperties', [])) > 0 && dirty
  ), [dirty, cart])
  const clearCartItemsActions = useMemo(() => {
    if (!needUserConfirmToClearCartItems) return []
    const cartLines = _.filter(_.get(cart, 'cartLineProperties', []), 'isMainProperty')
    return _.map(cartLines, ({
      sku,
      groupUuid,
      identifierUuid,
      parentIdentifierUuid,
    }) => ({
      actionType: 'update_cart_line_property',
      skuId: sku.id,
      quantity: 0,
      quantityMode: 'fixed',
      productAddonId: null,
      groupUuid,
      identifierUuid,
      parentIdentifierUuid,
    }))
  }, [cart, needUserConfirmToClearCartItems])

  const onChange = (value) => {
    setSelectedItem(value)
  }
  const onStoreChange = (value) => {
    setSelectedStoreItem(value)
  }
  const onConfirm = () => {
    setUpdateOrderMethodInProgress(true)
  }

  const updateOrderMethod = useCallback(async () => {
    const {
      selectStoreBy,
      deliveryType,
    } = selectedOrderMethod || {}
    const {
      availableDeliveryTypes = [],
      cartShipments = [],
    } = cart || {}
    const firstCartShipment = _.first(cartShipments) || {}
    const {
      id: shipmentId,
      deliveryType: cartDeliveryType,
    } = firstCartShipment
    const {
      id: deliveryAddressId,
    } = deliveryAddress || {}

    const isSelectedDeliveryTypeAvailable = _.includes(availableDeliveryTypes, deliveryType)

    let cartShipmentId = shipmentId
    if (_.isEmpty(cartShipmentId)) {
      try {
        // FL: fetch cart and retry if cart doesn't have shipment Id
        const { cart: data } = await fetchCart({
          params: {
            includes: ['cart_shipment_ids'],
          },
        })
        cartShipmentId = _.get(data, 'cartShipmentIds.0')
      } catch (errors) {
        if (/^(404|20302)$/.test(_.get(errors, 'generalError.code'))) {
          return createCart().then((data) => {
            console.log('///// data ....', data)
            return updateOrderMethod()
          }).catch((err) => handleError(err))
        }
        handleError(errors)
      }
    }

    let updateDeliveryTypeActions = []
    const commonAction = {
      actionType: 'update_cart_shipment',
      id: cartShipmentId,
      ...(isSelectedDeliveryTypeAvailable && { deliveryType }),
    }
    try {
      if (selectStoreBy === 'deliveryAddress') {
        const { stores: pickupStores } = await fetchStores({
          ..._.pick(deliveryAddress, ['lat', 'long']),
          includes: [
            'meta',
            'stores.address',
            'stores.banner_images',
            'today_open_time_slots',
          ],
          pageSize: 1,
          zeek: 1,
        })
        if (_.isEmpty(pickupStores)) {
          setIsNoStoreDialogOpen(true)
        } else {
          const pickupStore = _.first(pickupStores)
          setStore(pickupStore)
          updateDeliveryTypeActions = [{
            ...commonAction,
            ...(_.isEmpty(deliveryAddressId) ? {} : {
              // courierServiceId, // no courier service in uat
              deliveryAddressId,
              pickupStoreId: _.get(pickupStore, 'id'),
            }),
          }]
        }
      } else {
        updateDeliveryTypeActions = [{
          ...commonAction,
          pickupStoreId: _.get(selectedStore, 'id'),
        }]
        setStore(selectedStore)
      }
    } catch (error) {
      handleError(error)
    }
    // Update cart shipment
    try {
      const { cart: updatedCart } = await updateCart({
        // schemaType: 'takeAway',
        cartId,
        payload: {
          data: {
            actions: [
              ...clearCartItemsActions,
              ...updateDeliveryTypeActions,
            ],
          },
          batchUpdateMode: 2,
        },
        params: {
          includes: CART_INCLUDES,
          priceTemplate: CART_PRICE_TEMPLATE_KEY,
          priceStoreCode: _.get(selectedStore, 'code', null),
          refreshCart: true,
        },
      })
      setCart(updatedCart)
      // refreshCartAction(updatedCart)
      return true
    } catch (errors) {
      if (/^(404|20302)$/.test(_.get(errors, 'generalError.code'))) {
        return createCart().then((data) => {
          console.log('///// data ....', data)
          return updateOrderMethod()
        }).catch((err) => handleError(err))
      }
      handleError(errors)
    }
  }, [
    cart,
    cartId,
    createCart,
    deliveryAddress,
    fetchCartApi,
    fetchStores,
    handleError,
    selectedOrderMethod,
    selectedStore,
    setOrderMethod,
    setStore,
    updateCart,
  ])

  const handleUpdateOrderMethodSuccess = () => {
    onRequestClose()
    if (_.isFunction(onContinue)) {
      onContinue()
    // } else {
    //   navigate('/products/', { replace: true })
    }
  }

  useEffect(() => {
    if (!_.isEmpty(storeOptions)) {
      const initSelectedStoreItem = _.find(storeOptions, { value: _.get(store, 'id') })
      setSelectedStoreItem(initSelectedStoreItem)
    }
  }, [storeOptions])

  useEffect(() => {
    const { value } = selectedItem || {}
    switch (value) {
      case 'delivery':
        setShowAddressSelector(true)
        break;
      default:
        setShowAddressSelector(false)
        break;
    }
  }, [selectedItem])

  useEffect(() => {
    if (updateOrderMethodInProgress) {
      // Clear cart if cart is not empty
      if (needUserConfirmToClearCartItems) {
        setIsChangeStoreDialogOpen(true)
      } else {
        handleUpdateOrderMethod()
        // setOrderMethod(selectedOrderMethod)
      }
    }
  }, [updateOrderMethodInProgress, needUserConfirmToClearCartItems])

  useEffect(() => {
    // Fetch stores when Modal open
    if (!isOpen || !_.isEmpty(stores)) return
    fetchStoresApi()
  }, [isOpen, stores, fetchStoresApi])

  useEffect(() => {
    // if (!changeOrderMethodFromMenu || _.get(orderMethod, 'type') === 'dineInMenu') return
    if (!changeOrderMethodFromMenu) return
    proccessUpdateOrderMethod()
  }, [changeOrderMethodFromMenu, orderMethod, proccessUpdateOrderMethod])

  useEffect(() => {
    if (!updateCartShipmentInProgress) return
    // FL: Make sure order method updated in localstorage
    if (_.get(selectedOrderMethod, 'code') !== _.get(orderMethod, 'code')) return
    proccessUpdateOrderMethod()
  }, [
    updateCartShipmentInProgress,
    orderMethod,
    selectedOrderMethod,
    proccessUpdateOrderMethod,
  ])

  useEffect(() => {
    // Auto select if only one option available
    if (_.size(orderMethodOptions) > 1) return
    setSelectedItem(_.first(orderMethodOptions))
  }, [orderMethodOptions])

  useEffect(() => {
    // Select order method after login
    if (_.has(location, 'state.preSelectedOrderMethod')) {
      setSelectedItem(_.get(location, 'state.preSelectedOrderMethod'))
    }
  }, [_.get(location, 'state.preSelectedOrderMethod')])

  useEffect(() => (
    function cleanUp() {
      cancelRequest.cancelAll([
        'fetchStores',
        'fetchCart',
        'fetchDeliveryAddresses',
      ])
    }
  ), [])

  /**
   * handleError
   * show error message via alert
   */
  function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  /**
   * fetchCartApi
   * get cart from API
   */
  const fetchCartApi = useCallback(async () => {
    try {
      // api call option
      const options = {
        params: {
          includes: CART_INCLUDES,
          priceTemplate: CART_PRICE_TEMPLATE_KEY,
          priceStoreCode: _.get(store, 'code', null),
          refreshCart: true,
        },
      }
      // call api
      const { cart: data } = await fetchCart(options)
      setCart(data)
    } catch (error) {
      handleError(error)
    }
  }, [CART_PRICE_TEMPLATE_KEY, fetchCart, handleError])
  /**
   * fetchStoresApi
   * get stores data from API
   */
  const fetchStoresApi = useCallback(async () => {
    try {
      // call api
      const includes = [
        'meta',
        'today_open_time_slots',
      ]
      const { stores: data } = await fetchStores({ includes })
      setStores(data)
    } catch (error) {
      handleError(error)
    // } finally {
    //   setStoresReady(true)
    }
  }, [fetchStores])

  const onAddressSelectorClick = () => {
    if (!isLoggedIn) {
      const {
        page,
        search,
      } = location
      const redirectUrl = `${page}${search}`
      navigate(
        '/login/',
        {
          state: {
            redirectUrl,
            // throw back selected order method when come back from login
            callbackState: { preSelectedOrderMethod: selectedItem, isBlocker },
          },
          replace: true,
        },
      )
    } else {
      setIsAddressManagerOpen(true)
    }
  }

  const onAddressManagerRequestClose = () => {
    setIsAddressManagerOpen(false)
  }

  const apiFetchDeliveryAddresses = async () => {
    const options = {
      params: {
        includes: [
          'user_address_info',
        ].join(','),
        // page,
      },
    }
    const { deliveryAddresses: data } = await fetchDeliveryAddresses(options)
    return data
  }

  const onFetchDeliveryAddresses = async () => {
    try {
      setDeliveryAddressesLoading(true)
      // TODO: set shipment delivery address id
      // when success, show info
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
      return data
    } catch (error) {
      handleError(error)
    } finally {
      setDeliveryAddressesLoading(false)
    }
  }

  const onSelectDeliveryAddress = (address) => {
    setDeliveryAddress(address)
    setUpdatedDevliveryAddress(address)
    onAddressManagerRequestClose()
  }

  const onUpdateDeliveryAddress = async (address) => {
    try {
      // handleSetLoading(true)
      // when success, show info
      await updateDeliveryAddresses({ address })
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
    } catch (error) {
      handleError(error)
    } finally {
      // handleSetLoading(false)
    }
  }

  /**
   * handleDeleteAddress
   * call api to delete a address
   * @param {*} address
   */
  async function handleDeleteAddress(address) {
    try {
      setStoresLoading(true)
      await deleteDeliveryAddresses({
        addressId: _.get(address, 'id'),
      })
      if (_.get(deliveryAddress, 'id', '--') === _.get(address, 'id', '-')) {
        setDeliveryAddress({})
      }
    } catch (error) {
      handleError(error)
    } finally {
      setStoresLoading(false)
    }
  }

  const handleUpdateOrderMethod = () => {
    // FL: skip update cart if changing order method to dineInMenu
    if (
      _.get(selectedOrderMethod, 'code') === 'dineInMenu'
    ) {
      setStore(selectedStore)
      setOrderMethod(selectedOrderMethod)
      handleUpdateOrderMethodSuccess()
      setUpdateOrderMethodInProgress(false)
      return
    }
    setOrderMethod(selectedOrderMethod)
    setUpdateCartShipmentInProgress(true)
  }

  const proccessUpdateOrderMethod = useCallback(async () => {
    let success = true
    if (dirty && _.get(selectedOrderMethod, 'commerceType') !== 'dineIn') {
      success = await updateOrderMethod()
    }
    if (success) {
      handleUpdateOrderMethodSuccess()
    }
    // reset
    setUpdateOrderMethodInProgress(false)
    setUpdateCartShipmentInProgress(false)
    setChangeOrderMethodFromMenu(false)
  }, [
    dirty,
    handleUpdateOrderMethodSuccess,
    selectedOrderMethod,
    updateOrderMethod,
  ])

  const onChangeStoreConfirm = async () => {
    setIsChangeStoreDialogOpen(false)
    handleUpdateOrderMethod()
    // setOrderMethod(selectedOrderMethod)
  }
  const onChangeStoreDialogRequestClose = () => {
    setIsChangeStoreDialogOpen(false)
    setUpdateOrderMethodInProgress(false)
  }
  const onNoStoreDialogRequestClose = () => {
    setIsNoStoreDialogOpen(false)
    setUpdateOrderMethodInProgress(false)
  }

  const viewProps = {
    confirmButtonDisabled,
    deliveryAddress,
    deliveryAddresses,
    deliveryAddressesLoading,
    handleDeleteAddress,
    isAddressManagerOpen,
    isBlocker,
    isChangeStoreDialogOpen,
    isLoggedIn,
    isNoStoreDialogOpen,
    isOrderMethodDialogOpen,
    onAddressManagerRequestClose,
    onAddressSelectorClick,
    onChange,
    onChangeStoreConfirm,
    onChangeStoreDialogRequestClose,
    onConfirm,
    onFetchDeliveryAddresses,
    onNoStoreDialogRequestClose,
    onRequestClose,
    onSelectDeliveryAddress,
    onStoreChange,
    onUpdateDeliveryAddress,
    orderMethod,
    orderMethodOptions,
    selectedItem,
    selectedStoreItem,
    showAddressSelector,
    storeOptions,
    storesLoading,
    themeConfig,
    updateOrderMethodInProgress,
  }

  return (
    <OrderMethodModalView {...viewProps} />
  )
}
