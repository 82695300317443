/* eslint-disable no-unused-vars */
import _ from 'lodash'
import parseStockLevel from './parse-stock-level'

/**
 * validateCartLines
 * validate cart line for checkout
 *
 * @param {*} cart
 */
export default function validateCartLines(cart) {
  const {
    cartLineProperties,
    cartMaxNumOfQtyPerSku,
    cartShipments,
  } = cart
  const cartShipment = _.first(cartShipments)
  // cart line > 0
  if (_.isEmpty(cartLineProperties)) return false

  // no out of stock item
  // no item is inactive
  // within max quantity per sku
  const allItemsValid = cartLineProperties.every((cartLine) => {
    const { sku = {}, quantity = 0, skuIsInactive } = cartLine
    const stockLevel = parseStockLevel(_.get(sku, 'stockLevel'))
    const isNotEnoughStock = stockLevel > 0 && stockLevel < quantity
    const isOutOfStock = stockLevel <= 0 || skuIsInactive
    const isExceedMaxQty = quantity > cartMaxNumOfQtyPerSku

    return !(isNotEnoughStock || isOutOfStock || isExceedMaxQty)
  })
  if (!allItemsValid) return false

  // finally, the cart is valid
  return true
}
