/* eslint-disable react/jsx-props-no-spreading */

import _ from 'lodash'
import React from 'react'
import { useCurrencies } from 'react-omnitech-api'
import CurrencyFormatView from './currency-format-view'

const CurrencyFormatController = (props) => {
  const { currencies } = useCurrencies()
  const currency = _.find(currencies, { isBaseCurrency: true })
  return !_.isEmpty(currency) ? <CurrencyFormatView currency={currency} {...props} /> : null
}

export default CurrencyFormatController
