/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect,
  useState,
} from 'react'
import _ from 'lodash'
import AddressManagerView from './address-manager-view'

function AddressManagerController({
  addresses,
  addressSelectable,
  addressType = 'delivery',
  autoSelectPrimaryAddress = false,
  defaultAddress,
  closeAfterSelectAddress,
  disableAddressBook,
  selectedAddress,
  onAddress,
  onDeleteAddress,
  onFetchDeliveryAddresses,
  onClose,
  onSelectAddress,
  onSetDefaultAddress,
  onUpdateDeliveryAddress,
}) {
  // internal state
  const [selectedEditAddress, setSelectedEditAddress] = useState({})
  const [selectedDeleteAddress, setSelectedDeleteAddress] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [forceDefaultDelivery, setForceDefaultDelivery] = useState(false)
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [showAddressBook, setShowAddressBook] = useState(false)
  const [showDeleteAddressModal, setShowDeleteAddressModal] = useState(false)
  const disableCancel = _.isEmpty(addresses)

  function handleAddAddress() {
    setIsEdit(false)
    setShowAddressBook(false)
    setShowAddressForm(true)
  }

  function handleCancel() {
    setIsEdit(false)
    setSelectedEditAddress({})
    setShowAddressBook(true)
    setShowAddressForm(false)
  }

  function handleCloseAddressBook() {
    if (_.isEmpty(addresses)) {
      handleAddAddress()
    } else {
      setShowAddressBook(false)
      onClose()
    }
  }

  async function handleDeleteAddress() {
    if (_.isEmpty(selectedDeleteAddress)) {
      handleDeleteAddressModalRequestClose()
      return
    }

    await onDeleteAddress(selectedDeleteAddress)
    await onFetchDeliveryAddresses()
    handleDeleteAddressModalRequestClose()
  }

  function handleDeleteAddressModalRequestClose() {
    setSelectedDeleteAddress({})
    setShowDeleteAddressModal(false)
  }

  function handleDeleteAddressModalShow(address) {
    setSelectedDeleteAddress(address)
    setShowDeleteAddressModal(true)
  }

  // edit an address
  function handleEditAddress(address) {
    setIsEdit(true)
    setSelectedEditAddress(address)
    setShowAddressBook(false)
    setShowAddressForm(true)
  }

  function handleSelectAddress(address) {
    if (_.isFunction(onSelectAddress)) onSelectAddress(address)
  }
  function handleUserSelectAddress(address) {
    handleSelectAddress(address)
    if (closeAfterSelectAddress) {
      setShowAddressBook(false)
      setShowAddressForm(false)
      onClose()
    }
  }

  function handleSetDefaultDelivery(address) {
    // if onSetDefaultAddress is provided, run it instead for override
    // the default behaviour
    if (_.isFunction(onSetDefaultAddress)) {
      onSetDefaultAddress(address)
      return
    }

    const options = addressType === 'delivery'
      ? { isPrimary: true }
      : { isBilling: true }
    onUpdateDeliveryAddress({
      ...address,
      country: address.countryCode,
      ...options,
    })
  }

  async function handleSubmitSuccess() {
    const data = await onFetchDeliveryAddresses()
    setIsEdit(false)
    setSelectedEditAddress({})
    setShowAddressForm(false)
    // setAddresses(data)
    if (_.size(data) === 1) {
      await handleSelectAddress(_.first(data))
      if (onAddress) {
        setShowAddressBook(true)
      }
    } else {
      setShowAddressBook(true)
    }
  }

  async function init() {
    // address form only
    if (disableAddressBook) {
      setShowAddressForm(true)
      return
    }

    if (_.isEmpty(selectedAddress)) {
      const data = await onFetchDeliveryAddresses()
      if (_.isEmpty(data)) {
        // setShowAddressForm(true)
        handleAddAddress()
        setForceDefaultDelivery(true)
        return
      }
      const primaryAddress = _.find(data, { isPrimary: true })
      if (autoSelectPrimaryAddress) {
        await handleSelectAddress(primaryAddress)
      }
    }
    setShowAddressBook(true)
  }

  useEffect(() => {
    init()
  }, [])

  const viewProps = {
    addresses,
    addressSelectable,
    addressType,
    disableCancel,
    defaultAddress,
    forceDefaultDelivery,
    isEdit,
    selectedAddress,
    selectedDeleteAddress,
    selectedEditAddress,
    showAddressForm,
    showAddressBook,
    showDeleteAddressModal,
    onAddress,
    onAddAddress: handleAddAddress,
    onCancel: handleCancel,
    onClose: handleCloseAddressBook,
    onDeleteAddress: handleDeleteAddress,
    onDeleteAddressModalShow: handleDeleteAddressModalShow,
    onDeleteAddressModalRequestClose: handleDeleteAddressModalRequestClose,
    onEditAddress: handleEditAddress,
    onSelectAddress: handleUserSelectAddress,
    onSetDefaultDelivery: handleSetDefaultDelivery,
    onSubmitSuccess: handleSubmitSuccess,
  }

  return (
    <AddressManagerView {...viewProps} />
  )
}

export default AddressManagerController
