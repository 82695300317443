/* eslint-disable no-undef */
import SparkMD5 from 'spark-md5'

export default function fileHash(file) {
  return new Promise((resolve) => {
    let currentChunk = 0

    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    const chunkSize = 2097152 // Read in chunks of 2MB
    const chunks = Math.ceil(file.size / chunkSize)
    const spark = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    fileReader.onload = (e) => {
      console.log('read chunk nr', currentChunk + 1, 'of', chunks)
      spark.append(e.target.result) // Append array buffer
      currentChunk += 1

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash = spark.end()
        resolve(hash) // Compute hash
      }
    }

    function loadNext() {
      const start = currentChunk * chunkSize
      const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext()
  })
}
