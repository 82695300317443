/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next'
import Link from '../link'
import { useMenu } from '../../hook'
import { combineClassNames } from '../../helpers'
import useStyle from './navbar-style'
import SubmenuView from './components'

const NavBarView = () => {
  const { t } = useTranslation()
  const [dataMenu, setDataMenu] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const { menuData = [] } = useMenu()
  const numberOfDepartaments = menuData.length
  const {
    container, containerFluid, menu, menuItem, menuItemLarge, active,
  } = useStyle({ numberOfDepartaments })
  let setTiming
  const onSubMenuOpen = (data = {}) => {
    const { children } = data

    setTiming = setTimeout(() => {
      if (_.isEmpty(children)) {
        setIsOpen(false)
        setDataMenu([])
      } else {
        setDataMenu(data)
        setIsOpen(true)
      }
    }, 350)
  }

  const onSubMenuClose = useCallback(() => {
    setIsOpen(false)
    setDataMenu([])
  }, [dataMenu])

  return (
    <nav role="presentation" className={containerFluid} onMouseLeave={() => onSubMenuClose()}>
      <div className={container}>
        <ul className={menu}>
          {
            menuData && menuData.map((item) => (
              <li
                role="presentation"
                className={combineClassNames([
                  menuItem,
                  numberOfDepartaments <= 5 && menuItemLarge,
                ])}
                key={item.key}
                onMouseEnter={() => {
                  onSubMenuOpen(item)
                }}
                onMouseLeave={() => {
                  clearTimeout(setTiming)
                }}
              >
                {
                  _.isEmpty(item.url)
                    ? <a className={item.active ? active : ''}>{item.text}</a>
                    : (
                      <Link to={item.url} className={item.active ? active : ''}>
                        {item.text}
                      </Link>
                    )
                }
              </li>
            ))
          }
        </ul>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={150}
        unmountOnExit
        classNames="sub-menu"
      >
        <SubmenuView
          data={dataMenu}
          t={t}
          onSubMenuOpen={onSubMenuOpen}
        />
      </CSSTransition>
    </nav>
  )
}

export default NavBarView
