/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import OrderMethodModal from '../order-method-modal'
import useStyles from './order-method-style'
import ArrowDownIconInline from '../../assets/icons/icon_dropdown_arrow.inline.svg'

export default function OrderMethodModalView({
  modalOpen,
  onClick,
  onContinue,
  orderMethod,
  orderMethodValue,
  requestModalClose,
  selectableOrderMethod,
}) {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div>
      <OrderMethodModal
        isOpen={modalOpen}
        onRequestClose={requestModalClose}
        onContinue={onContinue}
      />
      <button
        type="button"
        onClick={onClick}
        className={styles.orderMethod}
      >
        {
          _.size(selectableOrderMethod) > 1 && (
            t('ui.orderMethod.label', {
              type: t('ui.orderMethodModal.options', { context: _.get(orderMethod, 'code') }),
            })
          )
        }
        <em
          className={styles.orderMethodValue}
        >
          {orderMethodValue}
        </em>
        <ArrowDownIconInline className={styles.orderMethodIcon} />
      </button>
    </div>
  )
}
