/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { globalHistory } from '@reach/router'
import AlertContext from './alert-context'

/**
 * AlertProvider
 * Contain most logic handing error alert
 */
export default function AlertProvider({ children }) {
  const [alertMessage, setAlertMessage] = useState('')
  const [stateMessage, setStateMessage] = useState('error')

  const remove = () => {
    setAlertMessage('')
  }

  const show = (message = '', state = 'error') => {
    if (_.isEmpty(message)) return
    setAlertMessage(message)
    setStateMessage(state)
  }

  const isOpen = useMemo(() => !_.isEmpty(alertMessage), [alertMessage])

  // when going to a new page, reset the error message
  useEffect(() => {
    remove()
  }, [globalHistory.location.href])

  const contextValue = {
    isOpen,
    alertMessage,
    stateMessage,
    remove,
    show,
  }

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  )
}
