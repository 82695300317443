import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './address-book-style'
import Button from '../button'
import AddressCard from '../address-card'
import combineClassNames from '../../helpers/combineClassNames'

const AddressBookView = ({
  addresses,
  addressSelectable,
  addressType,
  defaultAddress,
  selectedAddress,
  onAddress,
  onAddAddress,
  onClose,
  onDeleteAddressModalShow,
  onEditAddress,
  onSelectAddress,
  onSetDefaultDelivery,
}) => {
  const { t } = useTranslation()

  const selectedAddressId = _.get(selectedAddress, 'id')

  const styles = useStyles()

  return (
    <div className={styles.addressBook}>
      <h4>{t('ui.addressBook.title')}</h4>

      <div className={styles.boxAdress}>
        {
          addresses.map((address) => (
            <AddressCard
              address={address}
              addressSelectable={addressSelectable}
              addressType={addressType}
              isDefault={defaultAddress.id === address.id}
              selected={selectedAddressId === address.id}
              key={`address-${address.id}`}
              onEdit={onEditAddress}
              onSelect={onSelectAddress}
              onDeleteModalShow={onDeleteAddressModalShow}
              onSetDefaultDelivery={onSetDefaultDelivery}
            />
          ))
        }
      </div>
      <div className={styles.buttons}>
        <Button
          className={combineClassNames([styles.button, styles.buttonAdd])}
          dark
          text={t('ui.addressBook.add')}
          onClick={onAddAddress}
        />
        {
          onAddress
          && (
          <Button
            className={combineClassNames([styles.button, styles.buttonCancel])}
            border
            text={t('ui.addressBook.cancel')}
            onClick={onClose}
          />
          )
        }
      </div>
    </div>
  )
}

export default AddressBookView
