/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React, { useMemo } from 'react'
// import {
//   Field,
// } from 'formik'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './input-quantity-style'

const InputQuantityView = ({
  max = Infinity,
  min = 0,
  step = 1,
  value = 0,
  inline = false,
  disabled,
  onChange,
  // formik,
}) => {
  const valueZeroView = useMemo(() => value === 0 && min === 0 && inline, [value, min, inline])
  const styles = useStyles({ disabled, inline, valueZeroView })
  const onIncrease = () => {
    if (_.isFunction(onChange)) onChange(Math.min(value + step, max))
  }
  const onDecrease = () => {
    if (_.isFunction(onChange)) onChange(Math.max(value - step, min))
  }

  return (
    <div className={styles.container}>
      { !valueZeroView && (
        <button
          type="button"
          className={combineClassNames([styles.button, styles.buttonDecrease])}
          onClick={!disabled ? onDecrease : undefined}
          disabled={disabled || value <= min}
        >
          <i className={combineClassNames([styles.buttonIcon, styles.buttonIconDecrease])} />
        </button>
      )}
      { !valueZeroView && (
        <div className={styles.value}>{value}</div>
      )}
      <button
        type="button"
        className={combineClassNames([styles.button, styles.buttonIncrease])}
        onClick={!disabled ? onIncrease : undefined}
        disabled={disabled || value >= max}
      >
        <i className={combineClassNames([styles.buttonIcon, styles.buttonIconIncrease])} />
      </button>
    </div>
  )
}

export default InputQuantityView
