/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash'
import React from 'react'
// import defaultTheme from 'react-treebeard/dist/themes/default';
import { decorators as defaultDecorators } from 'react-treebeard';
import withStyles from 'react-jss'

class CustomContainer extends defaultDecorators.Container {
  render() {
    const {
      style, decorators, node, classes, nodeParents,
    } = this.props;
    const classTop = _.includes(nodeParents, node.code)
      ? classes.headerParent
      : classes.headerChildren
    const styles = node.level && node.level > 2 ? { padding: '0 20px 0 60px', backgroundColor: '#f1f1f1' } : {}
    return (
      <div
        ref={(ref) => (this.clickableRef = ref)}
        className={classTop}
        style={styles}
      >
        <decorators.Header
          node={node}
          style={style.header}
          nodeParent={node.code}
        />

        <decorators.Toggle {...this.props} />
        {/* {!terminal && !_.isEmpty(node.children) ? this.renderToggle() : null} */}
      </div>
    );
  }
}
const styles = {

  headerParent: {
    position: 'relative',
    height: 49,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    color: '#282828',
    borderBottom: '1px solid #bfbfbf',
  },
  headerChildren: {
    position: 'relative',
    height: 49,
    backgroundColor: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#282828',
    padding: '0 20px 0 40px',
    borderBottom: '1px solid #bfbfbf',
  },
}

export default withStyles(styles)(CustomContainer)
