/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import useStyles from './coupon-marketplace-header-style'
import CrossIcon from '../../../../assets/icons/icon_cross.svg'
import LeftArrowIcon from '../../../../assets/icons/icon_left_arrow.svg'

const CouponMarketplaceHeaderView = ({
  title,
  widthArrow,
  onBack,
  onCloseDetail,
}) => {
  const styles = useStyles()
  return (
    <div className={styles.header}>

      <div
        className={styles.backButton}
        onClick={onBack}
      >
        {widthArrow && (
          <img
            alt="close"
            className={styles.cross}
            src={LeftArrowIcon}
          />
        )}
      </div>
      <h4 className={styles.titleHeaderDetail}>{title}</h4>
      <div
        className={styles.closeButton}
        onClick={onCloseDetail}
      >
        <img alt="close" className={styles.cross} src={CrossIcon} />
      </div>
    </div>
  )
}

export default CouponMarketplaceHeaderView
