import * as Yup from 'yup'
import i18next from 'i18next'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default function transformEnquiryFormValidationSchema() {
  const yupShapeObj = {}

  yupShapeObj.phone = Yup.string()
    .required(i18next.t('screens.enquiry.errors.phone', {
      context: 'required',
    }))
    .matches(phoneRegExp, i18next.t('screens.enquiry.errors.phone', {
      context: 'valid',
    }))
  yupShapeObj.email = Yup.string()
    .required(i18next.t('screens.enquiry.errors.email', {
      context: 'required',
    }))
    .email(i18next.t('screens.enquiry.errors.email', {
      context: 'valid',
    }))
  return Yup.object().shape(yupShapeObj)
}
