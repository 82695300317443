/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useCart,
  useDineInCart,
  useOrderMethod,
  useOrders,
} from 'react-omnitech-api'
import { useModal } from '../use-modal'

function useCartHook() {
  const { t } = useTranslation()
  const modal = useModal()
  const apiCart = useCart()
  const apiDineInCart = useDineInCart()
  const {
    orderMethod,
  } = useOrderMethod()
  const { updateOrderToDecline } = useOrders()

  // variables for dependencies
  const depOrderMethod = useMemo(() => JSON.stringify(orderMethod), [orderMethod])
  const depApiCart = useMemo(() => JSON.stringify(apiCart), [apiCart])
  const depApiDineInCart = useMemo(() => JSON.stringify(apiDineInCart), [apiDineInCart])
  const {
    cartId,
    dineInCartId,
    initCart,
    updateCart,
    ...rest
  } = useMemo(() => {
    switch (_.get(orderMethod, 'code')) {
      case 'dineIn':
        return apiDineInCart
      default:
        return apiCart
    }
  }, [depOrderMethod, depApiCart, depApiDineInCart])
  const currentCartId = useMemo(() => {
    switch (_.get(orderMethod, 'code')) {
      case 'dineIn':
        return dineInCartId
      default:
        return cartId
    }
  }, [depOrderMethod, dineInCartId, cartId])
  const orderUuid = useMemo(() => _.get(initCart, 'awaitConfirmOrder.uuid'), [initCart])
  const isCheckoutOperator = useMemo(() => _.get(initCart, 'awaitConfirmOrder.isCheckoutOperator'), [initCart])
  const cartState = useMemo(() => _.get(initCart, 'state'), [initCart])
  const resolveUpdateCart = (options) => {
    if (
      _.isEmpty(orderUuid)
      && !_.isEqual(cartState, 'checkout_in_progress')
    ) {
      return updateCart(options)
    }
    // return promise to wait for user actions
    return new Promise((resolve, reject) => {
      if (isCheckoutOperator) {
        modal.open({
          title: t('ui.cartPaymentInProgressModal.title'),
          message: t('ui.cartPaymentInProgressModal.description'),
          buttons: [
            {
              text: t('ui.cartPaymentInProgressModal.buttons.cancel'),
              onClick: () => reject(onCancel()),
            },
            {
              text: t('ui.cartPaymentInProgressModal.buttons.declineOrder'),
              onClick: async () => {
                // close modal
                modal.close()
                // decline order
                try {
                  await updateOrderToDecline({
                    byType: 'by_uuid',
                    uuid: orderUuid,
                    action: 'decline',
                  })
                  resolve(updateCart(options))
                } catch (error) {
                  reject(error)
                }
              },
            },
          ],
        })
      } else {
        modal.open({
          title: t('ui.cartPaymentInProgressNonCheckoutOperatorModal.title'),
          message: t('ui.cartPaymentInProgressNonCheckoutOperatorModal.description'),
          buttons: [
            {
              text: t('ui.cartPaymentInProgressNonCheckoutOperatorModal.buttons.cancel'),
              onClick: () => reject(onCancel()),
            },
          ],
        })
      }
    })
  }

  const onCancel = () => {
    modal.close()
    return { generalError: { code: 0 } }
  }

  return {
    cartId: currentCartId,
    updateCart: resolveUpdateCart,
    initCart,
    ...rest,
  };
}

export default useCartHook
