/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */

import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from '../content-group/templates/content-group-style'
import Title from '../title-detail'
import ProductCard from '../product-card'
import Button from '../button'

import ArrowFat from '../../assets/icons/icon_solid_arrow.svg'

function ProductGroupView(props) {
  const {
    productGroupCode,
    productGroupLines,
    title,
    onClick,
    onClickTrackEvent,
  } = props
  const { t } = useTranslation()
  const styles = useStyles()

  if (productGroupLines.length === 0) return <div className={styles.placeholderDiv} />

  const urlNavigation = _.isNumber(productGroupCode)
    ? `/products/?productGroupId=${productGroupCode}`
    : `/products/?productGroupCodeEq=${productGroupCode}`
  return (
    <div className={styles.productGroupStyle}>
      <Title
        text={title}
        uppercase
        margin="3rem 0 1rem"
      />
      <div className={styles.row}>
        {
        productGroupLines.map((item, index) => {
          const itemProps = {
            productId: _.get(item, 'sku.product.id'),
            colorOptionVariantType: _.get(item, 'sku.product.colorOptionVariantType', {}),
            imageUrl: _.get(item, 'sku.colorOption.defaultImage.versions.webLarge'),
            title: _.get(item, 'sku.product.title'),
            colorName: _.get(item, 'sku.colorOption.name'),
            colorOptionId: _.get(item, 'sku.colorOption.id'),
            sellPrice: _.get(item, 'sku.colorOption.sellPrice'),
            originalPrice: _.get(item, 'sku.colorOption.originalPrice'),
            activeCustomLabels: _.get(item, 'sku.colorOption.activeCustomLabels'),
            favourite: _.get(item, 'sku.colorOption.favourite'),
            url: _.get(item, 'sku.product.canonicalHref'),
            sku: _.get(item, 'sku'),
            stockLevel: _.get(item, 'sku.colorOption.stockLevel'),
            index: index + 1,
            list: title,
            onClickTrackEvent,
          }
          return (
            <div key={`product-group-${_.get(item, 'sku.id', '')}`} className={styles.productCardItem}>
              <ProductCard item={item} key={`product-group-line-${_.get(item, 'sku.id', '')}`} {...itemProps} />
            </div>
          )
        })
      }
      </div>
      <div className={styles.buttonMoreStyle}>
        <Button
          inline
          text={t('screens.home.ui.productsGroup.more')}
          navigate={urlNavigation}
          iconImage={ArrowFat}
          onClick={onClick}
          className={styles.moreButton}
          iconClassName={styles.moreButtonIcon}
        />
      </div>
    </div>
  )
}

export default ProductGroupView
