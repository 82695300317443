import React from 'react'
import useStyles from './spinner-style'

export default function Spinner(props) {
  const { size = 'normal' } = props
  const styles = useStyles()
  return (
    <div className={styles.loaderBox}>
      <div className={styles[size === 'small' ? 'loaderSmall' : 'loader']}> </div>
    </div>
  )
}
