import React from 'react'
import useStyles from './loading-dots-style'
import { combineClassNames } from '../../helpers'

const LoadingScreenView = ({ className } = {}) => {
  const { container, loader } = useStyles()
  return (
    <div className={combineClassNames([container, className])}>
      <div className={loader} />
    </div>
  )
}
export default LoadingScreenView
