/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import URI from 'urijs'
import {
  useBrands,
  useCategories,
  useContentGroups,
  useDepartments,
} from 'react-omnitech-api'
import MenuContext from './menu-context'
import getBrands from '../../helpers/get-brands-list'

/**
 * MenuProvider
 * Contain most logic handing menu
 */
export default function MenuProvider({ children }) {
  const [menuData, setMenuData] = useState([])
  const [menuContentGroup, setMenuContentGroup] = useState({})
  const { brands } = useBrands()
  const { categories } = useCategories()
  const { fetchContentGroupByCode } = useContentGroups()
  const { departments, fetchDepartments } = useDepartments()

  function getChildrenCategories({ departmentId, categoryIds, extraParams = {} }) {
    // if (_.isEmpty(categoryIds) && _.isEmpty(departmentId)) {
    //   return []
    // }

    let childrenCategories = []

    if (departmentId) {
      childrenCategories = _.filter(categories, (data) => departmentId === data.departmentId)
    } else {
      childrenCategories = _.filter(categories, (data) => _.includes(categoryIds, data.id))
    }

    return childrenCategories.map((data) => {
      const { childIds: childrenSubCategoryIds = [], code, name } = data
      const urlObj = new URI('/products/')
        .addSearch({
          categoryCodeEq: code,
          ...extraParams,
        })
      const url = `${urlObj.path()}${urlObj.search()}`
      const childrenSubCategories = getChildrenCategories({
        departmentId: undefined,
        categoryIds: childrenSubCategoryIds,
        extraParams: {
          ...extraParams,
        },
      })

      return {
        children: childrenSubCategories,
        key: `category-${data.id}`,
        text: name,
        type: 'category',
        url,
      }
    })
  }

  function getCategory(categoryId) {
    const category = _.find(categories, { id: categoryId })
    // if category not found, return a empty object
    if (_.isEmpty(category)) return {}

    const { childIds = [], code, name } = category
    const urlObj = new URI('/products/')
      .addSearch({
        categoryCodeEq: code,
      })
    const url = `${urlObj.path()}${urlObj.search()}`
    const departmentId = null
    const childrenCategories = getChildrenCategories({
      departmentId,
      categoryIds: childIds,
    })
    const paramUrl = window.location.href.substr(window.location.href.lastIndexOf('=') + 1)
    const paramLink = url.substr(url.lastIndexOf('=') + 1)

    return {
      children: childrenCategories,
      key: `category-${category.id}`,
      text: name,
      type: 'category',
      active: paramLink === paramUrl,
      url,
    }
  }

  function getDepartment(departmentId) {
    const department = _.find(departments, { id: departmentId })

    // if department not found, return a empty object
    if (_.isEmpty(department)) return {}

    const { categoryIds = [], code, name } = department
    const urlObj = new URI('/products/')
      .addSearch({
        departmentCodeEq: code,
      })
    const url = `${urlObj.path()}${urlObj.search()}`
    const childrenCategories = getChildrenCategories({
      departmentId,
      categoryIds,
      extraParams: {
        departmentCodeEq: code,
      },
    })
    const paramUrl = window.location.href.substr(window.location.href.lastIndexOf('=') + 1)
    const paramLink = url.substr(url.lastIndexOf('=') + 1)

    return {
      children: childrenCategories,
      key: `department-${department.id}`,
      text: name,
      type: 'department',
      active: paramLink === paramUrl,
      url,
    }
  }

  function transformContentGroupLinesToMenu(contentGroup) {
    const contentGroupLines = _.get(contentGroup, 'contentGroupLines', [])
    const data = contentGroupLines.map((line) => {
      const {
        id,
        linkTargetId,
        linkTargetType,
        name,
        url,
        configOptions,
      } = line
      const type = _.toLower(linkTargetType)
      const brandsMenu = _.get(configOptions, 'brandsMenu')
      // special handling
      let targetObject = {}
      switch (true) {
        case type === 'department':
          targetObject = getDepartment(linkTargetId)
          break
        case type === 'category':
          targetObject = getCategory(linkTargetId)
          break
        case brandsMenu === 'true':
          targetObject = getBrands(brands)
          break
        default:
          break
      }

      // object of a menu item
      return {
        key: `content-group-line-${id}`,
        text: name,
        // FL: Prevent url is empty string that make below check return `true`
        active: _.includes(window.location.href, (url || '!!!!!!!')),
        type,
        url,
        ...targetObject,
      }
    })

    return data
  }

  const apiFetchContentGroup = useCallback(async () => {
    try {
      // api call option from system setting
      const option = {
        code: 'ecom_main_navigation',
        includes: [
          'content_group_lines',
        ].join(','),
      }
      const { contentGroup: data } = await fetchContentGroupByCode(option)
      setMenuContentGroup(data)
    } catch (error) {
      // TODO: handle error
      console.warn('[MenuProvider] apiFetchContentGroup error: ', error)
    }
  }, [fetchContentGroupByCode])

  useEffect(() => {
    apiFetchContentGroup()
  }, [])

  useEffect(() => {
    if (_.isEmpty(menuContentGroup)) return
    const memuObject = transformContentGroupLinesToMenu(menuContentGroup)
    setMenuData(memuObject)
  }, [brands, categories, departments, menuContentGroup])

  const state = {
    menuData,
    getDepartment,
  }

  return (
    <MenuContext.Provider value={state}>
      {children}
    </MenuContext.Provider>
  )
}
