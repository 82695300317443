import React from 'react'
import Link from '../../link'

export default function Menu({ name = '', url }) {
  return (
    <Link to={url}>
      {name}
    </Link>
  )
}
