import _ from 'lodash'

export default function ratioCalc(ratio) {
  const regex = /^(?<width>\d+):(?<height>\d+)$/
  const size = _.get(regex.exec(ratio), 'groups')
  if (!size) return null
  const { width, height } = size
  const calcResult = (height / width) * 100
  return `${Number.parseFloat(calcResult).toFixed(2)}%`
}
