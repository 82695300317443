import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'
import ratioCalc from '../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  productCard: {
    padding: [[20, 10]],
    textAlign: 'left',
    flex: 1,
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.25,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[20, 15]],
    },
  },
  figure: {
    position: 'relative',
    margin: 0,
  },
  image: ({ product } = {}) => ({
    height: 0,
    paddingBottom: product
      ? ratioCalc(theme.config.ratioMiniCarousel)
      : ratioCalc(theme.config.ratioPlp),
    position: 'relative',
    overflowY: 'hidden',
    backgroundColor: '#eee',
    borderRadius: '1rem',
  }),
  content: {
    textAlign: 'center',
    padding: '4rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    height: '1.7rem',
    marginBottom: '1rem',
    backgroundColor: '#eee',
    borderRadius: '1rem',
  },
  title: {
    extend: 'text',
    width: '90%',
  },
  variant: {
    extend: 'text',
    width: '50%',
  },
  price: {
    extend: 'text',
    width: '40%',
  },
}))

export default useStyles
