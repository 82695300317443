import React from 'react'
import useStyles from './loading-spinner-style'

const LoadingScreenView = () => {
  const { container, loader } = useStyles()
  return (
    <div className={container}>
      <div className={loader} />
    </div>
  )
}
export default LoadingScreenView
