export default function poll(fn, timeout, interval = 100, stopTest) {
  const endTime = Number(new Date()) + (timeout || 2000)

  const checkCondition = (resolve, reject) => {
    const ajax = fn()
    // dive into the ajax promise
    ajax.then((response) => {
      const stopTestResult = stopTest(response)
      // If the condition is met, we're done!
      if (stopTestResult) {
        resolve(response)
      } else if (Number(new Date()) < endTime) {
        // If the condition isn't met but the timeout hasn't elapsed, go again
        setTimeout(checkCondition, interval, resolve, reject)
      } else {
        // Didn't match and too much time, reject!
        reject(new Error('Time out'))
      }
    }).catch((error) => {
      reject(error)
    })
  }

  return new Promise(checkCondition)
}
