import { createContext } from 'react'

/**
 * LinkContext
 * Create Link context for custom hook
 */
const LinkContext = createContext({
  getPath: () => {},
  navigate: () => {},
})

export default LinkContext
