/* eslint-disable import/no-cycle */
import React from 'react'
import ContentGroupLine from '../content-group-line'

function TemplateContentGroup(props) {
  const { contentGroup, lineStyle } = props
  const { contentGroupLines } = contentGroup

  return (
    <>
      {
        contentGroupLines.map((contentGroupLine) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ContentGroupLine key={`content-group-line-${contentGroupLine.id}`} {...contentGroupLine} style={lineStyle} />
        ))
      }
    </>
  )
}

export default TemplateContentGroup
