import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    color: theme.colors.primary,
    marginBottom: '2rem',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '4.8rem',
    backgroundColor: theme.colors.bgLight,
    lineHeight: 2,
    margin: '2rem 0',
  },
  expire: {
    textTransform: 'none',
    margin: 0,
  },
  month: {
    textTransform: 'uppercase',
    margin: 0,
  },
  titleMinicart: {
    backgroundColor: theme.colors.bgMediumDark,
    height: '2.8rem',
    color: theme.colors.background,
    margin: '0',
  },
  contentCointainer: {
    padding: '1.6rem 1rem 0',
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    contentCointainer: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  },
}))

export default useStyles
