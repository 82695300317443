import { useContext } from 'react'
import CompareContext from './compare-context'

/**
 * useLink
 * The custom hook which provided to external use via context
 */
const useCompare = () => {
  const context = useContext(CompareContext);
  return context
};

export default useCompare
