// import _ from 'lodash'
import React from 'react'
import { useAlert } from '../../hook'
import AlertView from './alert-view'

const AlertController = () => {
  const {
    isOpen, remove, alertMessage, stateMessage,
  } = useAlert()

  const handleClose = (event) => {
    event.preventDefault()
    remove()
  }

  return (
    <AlertView
      alertMessage={alertMessage}
      show={isOpen}
      onClose={handleClose}
      state={stateMessage}
    />
  )
}

export default AlertController
