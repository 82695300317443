/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import combineClassNames from '../../helpers/combineClassNames'
import Tag from '../tag'
import useStyles from './custom-labels-style'

function CustomLabelsView(props) {
  const { customLabels, className } = props
  const styles = useStyles()

  if (customLabels.length === 0) {
    return null
  }

  return (
    <div className={combineClassNames([styles.customLabels, className])}>
      {
        _.map(customLabels, ({ code, name, frontendStyle }) => (
          <Tag key={`custom-label-${code}`} text={name} soldOut={code === 'soldOut'} {...{ frontendStyle }} />
        ))
      }
    </div>
  )
}

CustomLabelsView.propTypes = {
  customLabels: PropTypes.arrayOf(PropTypes.object),
}

CustomLabelsView.defaultProps = {
  customLabels: [],
}
export default CustomLabelsView
