import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './address-card-style'
import InputRadio from '../input-radio'
import Button from '../button'
import Checkbox from '../checkbox'

const AddressCardView = ({
  address,
  addressSelectable,
  addressType,
  isDefault,
  selected,
  onDeleteModalShow,
  onEdit,
  onSelect,
  onSetDefaultDelivery,
}) => {
  const { t } = useTranslation()
  const {
    id,
    firstName,
    formattedAddressLines,
    lastName,
  } = address
  const phoneFormmatted = _.get(address, 'phoneData.formatted', null)
  const name = `${firstName} ${lastName}`

  const handleSelect = () => {
    onSelect(address)
  }

  const handleEdit = () => {
    onEdit(address)
  }

  const handleDelete = () => {
    onDeleteModalShow(address)
  }

  const handleSetDefaultDelivery = () => {
    onSetDefaultDelivery(address)
  }

  const styles = useStyles()

  return (
    <div className={styles.addressCard}>
      {
        addressSelectable && (
          <div className={styles.boxInput}>
            <InputRadio id={id} checked={selected} onChange={handleSelect} />
          </div>
        )
      }
      <div className={styles.contentAddress}>
        <p>{name}</p>
        {
          formattedAddressLines.map((line, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`address-line-${id}-${index}`}>{line}</p>
          ))
        }
        {
          !_.isNull(phoneFormmatted)
            && (
              <>
                <br />
                <p>{phoneFormmatted}</p>
              </>
            )
        }

        <Checkbox
          checked={isDefault}
          disabled={isDefault}
          onChange={handleSetDefaultDelivery}
        >
          {t('ui.addressBook.setDefault', { context: addressType })}
        </Checkbox>
        <div className={styles.action}>
          <Button
            className={styles.button}
            text={t('ui.addressBook.edit')}
            margin="0"
            padding="0"
            onClick={handleEdit}
          />
          <Button
            className={styles.button}
            text={t('ui.addressBook.delete')}
            margin="0"
            padding="0"
            onClick={handleDelete}
          />
        </div>
      </div>
    </div>
  )
}

export default AddressCardView
