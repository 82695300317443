/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
import React, { useMemo } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  isAndroid,
  isSafari,
  isChrome,
  isIOS,
} from 'react-device-detect';
import { useCouponMarketplace } from '../../../../hook'
import useStyles from './coupon-marketplace-detail-style'
import combineClassNames from '../../../../helpers/combineClassNames'
import CouponMarketplaceHeader from '../coupon-marketplace-header'
import CouponMarketplaceCouponInfo from '../coupon-marketplace-coupon-info'
import Button from '../../../button'
import Spinner from '../../../spinner'

const CouponMarketplaceCouponTokenDetailView = ({
  cartCouponTokens = [],
  couponToken = {},
  isDetailOpen,
  isDetailReady,
  isLoading,
  isOutsideCheckout,
  onApplyCoupon,
  onCouponDetailClose,
}) => {
  const { t } = useTranslation()
  const { onCouponMarketplaceClose } = useCouponMarketplace()

  const {
    id, coupon = {}, isValid, qrCodeValue,
  } = couponToken
  const { terms, name } = coupon || {}
  // useMemo
  const isCouponTokenApplied = useMemo(() => {
    const appliedCoupon = _.find(cartCouponTokens, (cartCouponToken) => {
      const couponTokenId = _.get(cartCouponToken, 'couponToken.id')
      return couponTokenId === id
    })
    return !_.isEmpty(appliedCoupon)
  }, [id, cartCouponTokens])

  const isSubmitDisabled = isLoading || isCouponTokenApplied || !isValid || isOutsideCheckout

  const buttonText = useMemo(() => {
    let text = ''
    switch (true) {
      case isOutsideCheckout:
        text = t('ui.minicartCoupons.detail.buttons.applyCheckout')
        break
      case isCouponTokenApplied:
        text = t('ui.minicartCoupons.detail.buttons.couponApplied')
        break
      case !isValid:
        text = t('ui.minicartCoupons.detail.buttons.invalidCoupon')
        break
      default:
        text = t('ui.minicartCoupons.detail.buttons.useCoupon')
        break
    }
    return text
  }, [isCouponTokenApplied, isValid])

  const handleSubmit = () => {
    onApplyCoupon(couponToken.token)
  }

  const styles = useStyles({
    isSubmitDisabled, isAndroid, isSafari, isChrome, isIOS,
  })

  return (
    <div className={
      combineClassNames([styles.containerDetail,
        isDetailOpen && styles.containerDetailOpen])
      }
    >
      <CouponMarketplaceHeader
        title={name}
        widthArrow
        onBack={onCouponDetailClose}
        onCloseDetail={onCouponMarketplaceClose}
      />
      {
        isDetailReady
          ? (
            <div className={styles.bodyDetail}>
              {/* Error message */}
              {
                !isValid && (
                  <div
                    className={
                      combineClassNames([styles.messsages, styles.withError])
                    }
                  >
                    <div className={styles.error}>
                      <div className={styles.errorBox}>
                        <span>{t('ui.minicartCoupons.error')}</span>
                      </div>
                    </div>
                  </div>
                )
              }
              <div className={styles.content}>
                <CouponMarketplaceCouponInfo
                  coupon={coupon}
                  startAt={couponToken.startAt}
                  endAt={couponToken.expiresAt}
                  qrCodeValue={qrCodeValue}
                  validPeriod
                />
                {/* Coupon Terms */}
                {/* <div
                  className={styles.termsStyle}
                  dangerouslySetInnerHTML={{ __html: terms }}
                /> */}
              </div>
            </div>
          )
          : (
            <Spinner />
          )
      }

      {/* TODO: redeem coupon / apply coupon */}
      {/* if user already redeemed all the coupon per each user, show redeemed */}
      {
        isDetailReady && (
          <div className={styles.footerDetail}>
            <Button
              dark
              className={styles.buttonDetail}
              disabled={isSubmitDisabled}
              text={buttonText}
              onClick={handleSubmit}
            />
          </div>
        )
      }
    </div>
  )
}

export default CouponMarketplaceCouponTokenDetailView
