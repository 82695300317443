import React from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './tag-style'

const TagView = ({
  text, soldOut, frontendStyle = 'A', className,
}) => {
  const styles = useStyles({ frontendStyle })
  return (
    <div
      className={
        combineClassNames([
          styles.tag,
          soldOut && styles.soldOut,
          className,
          soldOut && styles.soldOutClassName,
        ])
      }
    >
      <span className={styles.text}>{text}</span>
    </div>
  )
}

export default TagView
