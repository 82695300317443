import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import CurrencyFormat from '../currency-format'
import useStyles from './order-summary-style'
import combineClassNames from '../../helpers/combineClassNames'
import { isNotNullOrUndefined } from '../../helpers'

const OrderSummaryView = ({ summary = {}, classN }) => {
  const { t } = useTranslation()
  const {
    quantityCount,
    deliveryFee,
    discount,
    discountGroup,
    serviceCharge,
    subtotalPrice,
    totalPrice,
  } = summary

  const style = useStyles()

  return (
    <div className={style.container}>
      { style.hasTitle && <h6>{t('screens.orderDetail.summary.title')}</h6>}
      <div className={combineClassNames([style.summary, classN])}>
        <div className={combineClassNames([style.row, style.textBlock])}>
          <span>{t('screens.orderDetail.summary.subtotal', { items: quantityCount })}</span>
          <CurrencyFormat value={subtotalPrice} />
        </div>
        {/* TODO: need to loop out the discountGroup detail */}
        <div className={style.row}>
          <div className={style.textBlock}>
            <span>{t('screens.orderDetail.summary.discount')}</span>
            <CurrencyFormat value={discount} />
          </div>
          {
            !_.isEmpty(discountGroup) && (
              <div className={style.infoBlock}>
                {
                  discountGroup.map((cartDiscount) => (
                    <div key={`discount-group-${cartDiscount.uuid}`} className={style.info}>
                      <span>{cartDiscount.title}</span>
                      <CurrencyFormat value={cartDiscount.totalAmount} />
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
        { isNotNullOrUndefined(deliveryFee) && (
          <div className={combineClassNames([style.row, style.textBlock])}>
            <span>{t('screens.orderDetail.summary.delivery')}</span>
            {
              parseFloat(deliveryFee || '0') > 0 ? (
                <CurrencyFormat value={deliveryFee} />
              ) : (
                <span>--</span>
              )
            }
          </div>
        )}
        { isNotNullOrUndefined(serviceCharge) && (
          <div className={combineClassNames([style.row, style.textBlock])}>
            <span>{t('screens.orderDetail.summary.service')}</span>
            {
              parseFloat(serviceCharge || '0') > 0 ? (
                <CurrencyFormat value={serviceCharge} />
              ) : (
                <span>--</span>
              )
            }
          </div>
        )}
        <div className={combineClassNames([style.row, style.textBlock, style.total])}>
          <span>{t('screens.orderDetail.summary.total')}</span>
          <CurrencyFormat value={totalPrice} />
        </div>
      </div>
    </div>
  )
}

export default OrderSummaryView
