/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  button: (props) => {
    const color = (props.dark)
      ? theme.colors.background : (props.alert)
        ? theme.colors.alert : theme.colors.text
    const backgroundColor = (props.dark) ? theme.colors.primary : theme.colors[`${props.background}`] || theme.colors.background
    return {
      position: 'relative',
      display: 'block',
      width: '100%',
      minHeight: '5rem',
      padding: [['1rem', '1em']],
      fontSize: '1.6rem',
      fontWeight: '600',
      lineHeight: 1.143,
      textAlign: 'center',
      textTransform: 'uppercase',
      cursor: 'pointer',
      color,
      backgroundColor,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: backgroundColor,
      outline: 'none',
      ...(props.border
        ? {
          borderColor: 'currentColor',
          ...props.border,
        }
        : {}),
      ...(props.addFacebook
        ? {
          color: theme.colors.background,
          borderColor: theme.colors.facebook,
          backgroundColor: theme.colors.facebook,
          ...props.addFacebook,
        }
        : {}),
      ...(props.disabled
        ? {
          color: (props.border) ? theme.colors.bgMedium : theme.colors.background,
          backgroundColor: (props.border) ? theme.colors.background : theme.colors.bgMedium,
          borderColor: theme.colors.bgMedium,
          cursor: 'not-allowed',
          ...props.disabled,
        }
        : {}),
    }
  },
  inline: () => ({
    font: 'inherit',
    lineHeight: 'inherit',
    display: 'inline-block',
    width: 'auto',
    minHeight: 0,
    padding: 0,
    margin: 0,
    border: 'none',
  }),
  mini: () => ({
    display: 'inline-block',
    height: 0,
    minHeight: '3rem',
    width: 'auto',
    padding: [['0.5rem', '1em']],
    borderWidth: '0.1rem',
  }),
  icon: {
    left: 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: '0 1rem',
  },
  inlineIcon: {
    position: 'static',
    transform: 'none',
    margin: 0,
    marginLeft: '0.8rem',
  },
}))

export default useStyles
