/* eslint-disable react-hooks/exhaustive-deps */
/**
 * MiniCartProvider
 * Contain most logic mini cart
 */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useOrderMethod,
  useSystemSettings,
} from 'react-omnitech-api'
import { useAlert } from '../use-alert'
import useCart from '../use-cart'
import useSku from '../use-sku'
import MiniCartContext from './mini-cart-context'
import usePriceTemplate from '../use-price-template'
import useAnalytics from '../use-analytics'

// TODO: get from config
const CART_INCLUDES = [
  'await_confirm_order',
  // 'cart_lines',
  // 'cart_lines.price_details',
  // 'cart_lines.sku',
  'cart_line_properties',
  'cart_line_properties.color_option',
  'cart_line_properties.price_details',
  'cart_line_properties.sku',
  'cart_line_properties.product',
  'cart_line_properties.product_addon',

  'cart_line_properties.sku_id',
  'cart_shipments',
  'cart_shipments.inventory_store',
  'cart_shipments.pickup_store',

  'price_details',

  'products.color_option_variant_type',
  'products.size_option_variant_type',

  'skus.active_custom_labels',
  'skus.checkout_settings',
  'skus.color_option',
  'skus.meta',
  'skus.product',
  'skus.size_option',
  'skus.stock_level',

  'stores.today_open_time_slots',
].join(',')

export default function MiniCartProvider({ children }) {
  const CART_PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')
  // prepare hook
  const alert = useAlert()
  const { t } = useTranslation()
  const { trackEvent } = useAnalytics()
  const { fetchCart, totalItems, updateCart } = useCart()
  const { isAllowToCheckoutFnb } = useSku()
  const { getSystemSetting } = useSystemSettings()
  const fnbEnabled = getSystemSetting('features.fnb.enable')
  const {
    store,
  } = useOrderMethod()
  // internal state
  const [cart, setCart] = useState({})
  const [loading, setLoading] = useState(false)
  const [miniCartOpen, setMiniCartOpen] = useState(false)
  const [skusUnavailableToCheckout, setSkusUnavailableToCheckout] = useState([])

  /**
   * apiFetchCart
   * get cart data from API
   */
  function apiFetchCart() {
    const options = {
      params: {
        includes: CART_INCLUDES,
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        priceStoreCode: _.get(store, 'code', null),
        refresh_cart: true,
      },
    }
    return fetchCart(options)
  }

  /**
   * apiUpdateCart
   * call API to update cart
   * @param {*} actions
   */
  function apiUpdateCart(actions) {
    const option = {
      payload: {
        data: {
          actions,
        },
        batchUpdateMode: 2,
      },
      params: {
        includes: CART_INCLUDES,
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        priceStoreCode: _.get(store, 'code', null),
        refreshCart: true,
      },
    }
    return updateCart(option)
  }

  /**
   * handleError
   * @param {*} error
   */
  async function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    let errorMessage = ''
    if (generalError.code === 404) {
      errorMessage = t('screens.cart.errors.cartNotFound')
    } else {
      const batchActionError = _.find(error.batchActionErrors, 'message') || {}
      // if batch action is not provided, use the general error message
      errorMessage = _.isEmpty(batchActionError.message)
        ? generalError.message
        : batchActionError.message
    }
    alert.show(errorMessage)
  }

  /**
   * function handleFetchCart() {
   */
  async function handleFetchCart() {
    try {
      // call api
      const { cart: data } = await apiFetchCart()
      setCart(data)
      return data
    } catch (error) {
      await handleError(error)
    } finally {
      setLoading(false)
    }
  }

  function onTrackRemoveFromCart(cartLineId) {
    const { cartLineProperties } = cart
    const cartLineProperty = _.find(cartLineProperties, {
      id: cartLineId,
    })
    const name = _.get(cartLineProperty, 'sku.product.title')
    const skuCode = _.get(cartLineProperty, 'sku.code')
    const price = _.get(cartLineProperty, 'priceDetails.propertyUnitPrice')
    const quantity = _.get(cartLineProperty, 'quantity')
    const title = 'Page Mini-Cart'

    trackEvent('customerRemoveFromCart',
      {},
      {
        name,
        price,
        quantity,
        skuCode,
        title,
      })
  }

  /**
   * function handleCloseMiniCart() {
   * close mini cart
   */
  function handleCloseMiniCart() {
    setMiniCartOpen(false)
  }

  /**
   * function handleOpenMiniCart() {
   * open mini cart
   */
  function handleOpenMiniCart() {
    setLoading(true)
    setMiniCartOpen(true)
  }

  /**
   * handleRemoveCartLine
   */
  function handleRemoveCartLine({
    item,
  }) {
    const actions = [
      {
        actionType: 'update_cart_line_property',
        skuId: item.sku.id,
        quantity: 0,
        quantityMode: 'fixed',
        productAddonId: null,
        groupUuid: item.groupUuid,
        identifierUuid: item.identifierUuid,
        parentIdentifierUuid: null,
      },
    ]
    handleUpdateCart(actions)
    onTrackRemoveFromCart(item.id)
  }

  /**
   * handleUpdateCart
   * handle all update cart actions
   * @param {*} actions
   */
  async function handleUpdateCart(actions) {
    alert.remove()
    setLoading(true)
    try {
      const { cart: data } = await apiUpdateCart(actions)
      setCart(data)
      // close mini cart when cart is empty
      if (_.isEmpty(data.cartLineProperties)) {
        handleCloseMiniCart()
      }
    } catch (error) {
      await handleError(error)
    } finally {
      setLoading(false)
    }
  }

  function checkSkuAllowToCheckout(skus) {
    setSkusUnavailableToCheckout(_.reject(skus, isAllowToCheckoutFnb))
  }

  /**
   * check once mini cart is open, fetch cart data
   */
  useEffect(() => {
    if (miniCartOpen && loading) {
      handleFetchCart()
    }
  }, [miniCartOpen, loading])

  /**
   * check skus are allow to checkout for fnb
   */
  useEffect(() => {
    const skus = _.uniqBy(_.map(_.get(cart, 'cartLineProperties', []), 'sku'), 'id')
    if (!_.isEmpty(skus) && fnbEnabled) {
      checkSkuAllowToCheckout(skus)
    }
  }, [cart, fnbEnabled])

  const state = {
    cart,
    loading,
    miniCartOpen,
    totalItems,
    skusUnavailableToCheckout,
    openMiniCart: handleOpenMiniCart,
    closeMiniCart: handleCloseMiniCart,
    onRemoveCartLine: handleRemoveCartLine,
  }

  return (
    <MiniCartContext.Provider value={state}>
      {children}
    </MiniCartContext.Provider>
  )
}
