import {
  createUseStyles,
} from 'react-jss'
import ratioCalc from '../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  productCardStyle: (props) => ({
    position: 'relative',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    borderBottom: `1px solid ${theme.colors.bgDark}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    ...(props.dark
      ? {
        borderBottom: `1px solid ${theme.colors.background}`,
        ...props.dark,
        '&:last-child': {
          borderBottom: `1px solid ${theme.colors.background}`,
        },
      }
      : {}),
  }),
  clearIconStyle: {
    '&:focus': {
      outline: 'none',
    },
  },
  clearIconButtonStyle: {
    background: 'transparent',
    border: 0,
    padding: [0, 0, 0, '1.5rem'],
    position: 'absolute',
    right: '1rem',
    top: '1.8rem',
    '&:focus': {
      outline: 'none',
    },
  },
  boxProductStyle: {
    display: 'flex',
  },
  contentProductStyle: (props) => ({
    padding: '1rem 2rem',
    textAlign: 'left',
    flex: '1 3 calc(100% - 80px)',
    ...(props.dark
      ? {
        flex: 'auto',
        padding: '0.4rem 2rem 0',
        ...props.dark,
      }
      : {}),

  }),
  contentMaxWidth: {
    jmxwidth: 160,
  },
  productTitleLink: {
    color: 'currentColor',
    textDecoration: 'none',
  },
  textMinicart: (props) => ({
    margin: 0,
    color: theme.colors.background,
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: 1.45,
    ...(props.dark
      ? {
        lineHeight: 1.8,
        ...props.dark,
      }
      : {
        color: theme.colors.text,
      }),
  }),
  addons: () => ({
    marginBottom: '0.5rem',
  }),
  textAddons: () => ({
    opacity: 0.75,
    lineHeight: 1.25,
  }),
  priceUnitProductStyle: () => ({
    fontSize: '1.2rem',
    lineHeight: 1,
    '& span': {
      fontSize: '1.2rem',
      lineHeight: 1.7,
    },
  }),
  nameUnitProductColorStyle: () => ({
    color: theme.colors.bgMedium,
  }),
  priceUnitProductColorStyle: () => ({
    color: theme.colors.alert,
  }),
  nameDiscountProductColorStyle: () => ({
    color: theme.colors.bgMedium,
  }),
  linePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  descriptionDiscountStyle: () => ({
    fontSize: '1.2rem',
  }),
  itemError: {
    color: theme.colors.alert,
    fontSize: '1.2rem',
    lineHeight: 1.25,
    marginTop: '1rem',
  },
  imageProductStyle: (props) => ({
    minWidth: '80px',
    padding: 0,
    '& div': {
      height: 0,
      padding: 0,
      paddingBottom: ratioCalc(theme.config.ratioThumbnail),
      overflow: 'hidden',
      position: 'relative',
      display: 'block',
      margin: 0,
    },
    ...(props.dark
      ? {
        minWidth: '100px',
        '& div': {
          height: 0,
          padding: 0,
          paddingBottom: ratioCalc(theme.config.ratioCartImg),
          overflow: 'hidden',
          position: 'relative',
          display: 'block',
          margin: 0,
        },
        ...props.dark,
      }
      : {}),
    '& img': {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
    },
  }),
  noImage: {
    '& div': {
      background: '#dddddd',
    },
    '& img': {
      width: '50% !important',
    },
  },
}))

export default useStyles
