/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { useTheme } from 'react-jss'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'
import MagicSliderDots from 'react-magic-slider-dots'
import Link from '../link'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './carousel-style'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-magic-slider-dots/dist/magic-dots.css';
import ProductCard from '../product-card'

const CarouselView = ({
  images,
  slidesToShowDesktop,
  slidesToShowMovil,
  paddingImg,
  paddingImgDesktop,
  marginCarousel,
  marginCarouselDesktop,
  carouselSettings,
  postionDots,
  postionDotsMovile,
  product,
  arrowsClassName,
  nextArrowClassName,
  prevArrowClassName,
  imageBoxClassName,
  hasArrows,
  classNaneCarousel,
  autoplay,
  darkDots,
  onClick: onClickTrackEvent,
  speed = 1000,
  autoplaySpeed = 4000,
  breakpoints,
}, ref = null) => {
  const {
    carousel, boxImg, arrowNextStyle, arrowPrevStyle, productItem, image,
  } = useStyles({
    paddingImg,
    paddingImgDesktop,
    marginCarousel,
    marginCarouselDesktop,
    postionDots,
    postionDotsMovile,
    darkDots,
  })
  const theme = useTheme()
  const variableNameImage = breakpoints.sm ? 'ecomMedium' : 'ecomLarge'

  function NextArrow(props) {
    const {
      className, onClick, classN,
    } = props;
    return (
      <div
        role="button"
        className={combineClassNames([classN, className, arrowsClassName, nextArrowClassName])}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const {
      className, onClick, classN,
    } = props;

    return (
      <div
        role="button"
        className={combineClassNames([classN, className, arrowsClassName, prevArrowClassName])}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    autoplay,
    autoplaySpeed,
    speed,
    slidesToShow: slidesToShowDesktop,
    slidesToScroll: slidesToShowDesktop,
    className: combineClassNames([carousel, classNaneCarousel]),
    nextArrow: hasArrows ? <NextArrow classN={arrowNextStyle} /> : '',
    prevArrow: hasArrows ? <PrevArrow classN={arrowPrevStyle} /> : '',
    arrows: hasArrows,
    appendDots: (dots) => <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />,
    responsive: [
      {
        breakpoint: theme.mediaQueries.mobile,
        settings: {
          slidesToShow: slidesToShowMovil,
          slidesToScroll: slidesToShowMovil,
          arrows: false,
        },
      },
    ],
    ...carouselSettings,
  }
  return (
    <Slider ref={ref} {...settings}>
      {
        images.map((item, index) => {
          const {
            id,
            ecomLargeUrl,
            versions,
            attachment,
            url,
            configOptions,
          } = item
          const label = _.get(configOptions, 'label', id)
          const renderProduct = (
            <div className={productItem}>
              <ProductCard
                {...item}
                product
                index={index + 1}
                list={label}
                onClickTrackEvent={onClickTrackEvent}
              />
            </div>
          )
          const renderImage = <img src={ecomLargeUrl || _.get(versions, 'webLarge') || _.get(versions, 'ecomLarge') || _.get(attachment, `versions.${variableNameImage}`, '')} alt="" className={image} />

          return (
            <div className={combineClassNames([boxImg, imageBoxClassName])} key={`carousel-image-${id}`}>
              {
                product
                  ? renderProduct
                  : _.isEmpty(url)
                    ? renderImage
                    : (
                      <Link
                        // CP-20210429: temporary removal as this sometimes affects the click-through destination
                        // gatsbyLink
                        // onClick={() => onClickTrackEvent(label)}
                        to={url}
                      >
                        {renderImage}
                      </Link>
                    )
              }
            </div>
          )
        })
      }
    </Slider>
  )
}
CarouselView.propTypes = {
  images: PropTypes.array.isRequired,
  slidesToShowDesktop: PropTypes.number,
  slidesToShowMovil: PropTypes.number,
  paddingImg: PropTypes.string,
  paddingImgDesktop: PropTypes.string,
  marginCarousel: PropTypes.string,
  marginCarouselDesktop: PropTypes.string,
  postionDots: PropTypes.number,
  product: PropTypes.bool,
  hasArrows: PropTypes.bool,
  classNaneCarousel: PropTypes.string,
}

CarouselView.defaultProps = {
  slidesToShowDesktop: 1,
  slidesToShowMovil: 1,
  paddingImg: '0',
  paddingImgDesktop: '0',
  marginCarousel: '0',
  marginCarouselDesktop: '0',
  postionDots: 60,
  product: false,
  hasArrows: false,
  classNaneCarousel: '',
}

export default (withBreakpoints(forwardRef(CarouselView)))
