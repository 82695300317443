/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import {
  useSystemSettings,
} from 'react-omnitech-api'

export default function usePriceTemplate(props) {
  // TODO: get from config
  const availablePriceTemplates = [
    {
      // CP-20210128: v3 price template is used for both membership and fnb cart so
      // instead of modifying the usage in many places we're adding a v3 here for membership type.
      code: 'fnb_mobile_v3',
      type: 'membership',
      version: 3,
    },
    {
      code: 'membership_upgrade_v1',
      type: 'membership',
      version: 1,
    },
    {
      code: 'fnb_mobile_v3',
      type: 'fnb',
      version: 3,
    },
    {
      code: 'fnb_mobile_v1',
      type: 'fnb',
      version: 1,
    },
    {
      code: 'fnb_mobile_v2',
      type: 'fnb',
      version: 2,
    },
    {
      code: 'ecom_v2',
      type: 'ecom',
      version: 2,
    },
  ]
  const { getSystemSetting } = useSystemSettings()
  const fnbEnabled = getSystemSetting('features.fnb.enable')
  const defaultType = fnbEnabled ? 'fnb' : 'ecom'
  const matches = (() => {
    // if props not provided then use default type
    if (_.isEmpty(props)) {
      return { type: defaultType }
    }
    // if props is a string then convert to an object
    if (_.isString(props)) {
      return { type: props }
    }
    // if props not contain `type` then fallback to use default type
    if (_.isPlainObject(props)) {
      return { type: defaultType, ...props }
    }
    return props
  })()
  // gets first match
  const priceTmpl = _.find(availablePriceTemplates, matches)

  return priceTmpl
}
