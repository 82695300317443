import React from 'react'
import useStyles from './state-label-style'
import combineClassNames from '../../helpers/combineClassNames';

const StateLabelView = ({
  children, className, ...others
}) => {
  const styles = useStyles({
    ...others,
  })
  return (
    <i className={combineClassNames([styles.label, className])}>
      {children}
    </i>
  )
}

export default StateLabelView
