import { useContext } from 'react'
import ThemeConfigContext from './theme-config-context'

/**
 * useThemeConfig
 * The custom hook which provided to external use via context
 */
const useThemeConfig = () => {
  const context = useContext(ThemeConfigContext);
  return context
};

export default useThemeConfig;
