/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'
import MaskTop from '../../../../assets/images/icon-coupon-mask-top.svg'
import MaskBotton from '../../../../assets/images/icon_coupon_mask_bottom.svg'

const useStyles = createUseStyles((theme) => ({
  containerCart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    background: theme.colors.background,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      background: '#fff',
      maskImage: `url(${MaskBotton}) `,
      maskType: 'alpha',
      width: '100%',
      height: 20,
      zIndex: 2,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      backgroundColor: 'transparent',
      backgroundImage: `url(${MaskBotton}) `,
      filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.2))',
      width: '100%',
      height: 20,
      zIndex: 1,
    },
  },
  photo: (props) => ({
    width: '100%',
    maxWidth: '50rem',
    overflow: 'hidden',
    background: theme.colors.bgLight,
    marginBottom: 0,
    position: 'relative',
    '& div': {
      height: 0,
      paddingBottom: ratioCalc(theme.config.ratioCouponDetailImg),
      position: 'relative',
      overflowY: 'hidden',
    },
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      margin: 0,

      boxShadow: '0px -15px 3px -3px rgba(0,0,0,0.2)',
      width: '100%',
      height: 'auto',
      maskImage: `url(${MaskTop})`,
      maskType: 'alpha, match-source',
      maskPosition: 'top',
      maskRepeat: 'no-repeat',
    },
  }),
  noImage: {
    '& div': {
      background: '#dddddd',
    },
    '& img': {
      width: '30% !important',
    },
  },
  info: {
    padding: [[12, 20, 12]],
    '& h4': {
      fontSize: '2.1rem',
      fontWeight: 600,
      marginBottom: '0.6rem',
      letterSpacing: 0.5,
      lineHeight: '2.5rem',
      color: theme.colors.text,
    },
    '& p': {
      fontSize: '1.8rem',
      fontWeight: 400,
      letterSpacing: 0.6,
      color: theme.colors.alert,
      marginBottom: '.7rem',
      lineHeight: 'normal',
      '& span': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
      },
    },
  },
  dateStyle: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: '.3rem',
    '& span': {
      fontSize: '1.5rem',
      lineHeight: '1.07',
      lineSpacing: '0.18px',
      color: theme.colors.text,
    },
    '& img': {
      margin: '0 0.8rem 0 0',
    },
  },
  dateIcon: {
    width: '1.6rem',
  },
  pointsBox: {
    alignItems: 'flex-end',
    borderTop: `2px solid ${theme.colors.bgLight}`,
    borderBottom: `2px solid ${theme.colors.bgLight}`,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '.9rem',
    marginBottom: '2.1rem',
    padding: [[14, 16, 12]],
    justifyContent: 'space-between',
    '& p:first-child': {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.33,
      margin: 0,
    },
    '& p:last-child': {
      fontSize: '2.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: 0.29,
      margin: 0,
      color: theme.colors.text,
      '& span': {
        display: 'inline-block',
        lineHeight: 'inherit',
        marginLeft: '.5rem',
        textTransform: 'uppercase',
      },
    },
  },
  conditions: {
    padding: [0, 16],
    color: theme.colors.bgMediumDark,
    fontSize: '1.5rem',
    lineHeight: 1.07,
    fontWeight: 400,
    paddingBottom: 20,
  },
  validPeriod: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    lineHeight: '1.33',
    letterSpacing: '0.14px',
    marginBottom: '.4rem',
  },
  qrCouponStyle: {
    padding: 20,
    textAlign: 'center',
  },
  qrInfo: {
    marginTop: '3rem',
    padding: '0 30px',
    textAlign: 'center',
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
}))

export default useStyles
