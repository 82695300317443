import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    maxWidth: '57rem',
    margin: '0 auto',
    padding: '0',
    '& h6': {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: 1.14,
      margin: '3rem 0',
      textTransform: 'uppercase',
    },
  },
  summary: {
    padding: '1.3rem 3rem',
    height: 'auto',
    background: theme.colors.bgLight,
    border: `1px solid ${theme.colors.bgMedium}`,
  },
  info: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    margin: '.4rem 0',
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: 1.143,
    color: `${theme.colors.disabled}`,
    '& span': {
      font: 'inherit',
      lineHeight: 'inherit',
    },
  },
  row: {
    margin: '1.6rem 0',
  },
  textBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: 1.143,
    '& span': {
      font: 'inherit',
      lineHeight: 'inherit',
    },
  },
  total: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    summary: {
      padding: '2rem',
    },
    container: {
      maxWidth: '57rem',
    },
  },
}))

export default useStyles
