import { createUseStyles } from 'react-jss'
import _ from 'lodash'

const useStyles = createUseStyles((theme) => ({
  tag: (props) => ({
    display: 'inline-flex',
    alignItems: 'center',
    color: _.get(theme.colors.tags, `${_.toLower(props.frontendStyle)}.textColor`, '#000000'),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: _.get(theme.colors.tags, `${_.toLower(props.frontendStyle)}.outline`, '#000000'),
    width: 'fit-content',
    padding: '0.4rem 1rem',
    margin: '0 0.3rem 0.6rem',
    backgroundColor: _.get(theme.colors.tags, `${_.toLower(props.frontendStyle)}.background`, '#FFFFFF'),
    lineHeight: '1rem',
    'html[lang^=zh] &': {
      height: '2rem',
    },
  }),
  soldOut: () => ({
    color: theme.colors.background,
    backgroundColor: theme.colors.disabled,
    borderColor: theme.colors.disabled,
  }),
  text: {
    display: 'inline-block',
    fontSize: '.8rem',
    fontWeight: 400,
    lineHeight: 1.25,
    textTransform: 'uppercase',
    'html[lang^=zh] &': {
      fontSize: '1rem',
    },
  },
}))

export default useStyles
