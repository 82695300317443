import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  reviewCard: () => ({
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: `${theme.gap}rem`,
  }),
  reviewBoxImage: {
    width: '4.8rem',
    flex: '1 0 4.8rem',
    marginRight: '1.6rem',
  },
  imageProfile: {
    width: '4.8rem',
    height: '4.8rem',
  },
  reviewBoxContent: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    textAlign: 'left',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    marginRight: '-4rem',
  },
  info: {
    marginRight: '4rem',
    fontSize: '1.4rem',
  },
  author: {
    fontSize: '1.4rem',
    fontWeight: '600',
  },
  createAt: {
    fontSize: '1.4rem',
  },
  rating: {
    marginRight: '4rem',
    fontSize: '1.4rem',
  },
  ratingLine: {
    display: 'flex',
    alignItems: 'flex-end',
    maxHeight: '2.2rem',
  },
  ratingLabel: {
    fontSize: '1.4rem',
    marginRight: '0.3rem',
  },
  ratingStars: {
    transform: 'translateY(-0.2rem)',
  },
  reviewComment: {
    '& p': {
      fontSize: '1.2rem',
      lineHeight: 1.33,
      margin: 0,
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    reviewBoxContent: {
      display: 'flex',
      flex: '1 0 100%',
      flexDirection: 'column',
      textAlign: 'left',
    },
  },
}))

export default useStyles
