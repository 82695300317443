/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */

import _ from 'lodash'
import React, { useMemo } from 'react'
import { Field } from 'formik'
import useStyles from './checkbox-style'
import combineClassNames from '../../helpers/combineClassNames'

const CheckboxView = ({
  children,
  checked,
  error,
  formik,
  name,
  disabled,
  checkboxRef,
  onChange,
  wrapperStyle,
  frontStyle,
  labelStyle,
  ...others
}) => {
  const handleChange = (e) => {
    if (_.isFunction(onChange)) {
      onChange(e)
    }
  }
  const styles = useStyles({ checked, disabled })
  const checkboxId = _.uniqueId('checkbox-')
  const InputComponent = useMemo(() => (formik ? Field : 'input'), [formik])
  const InputProps = {
    ref: checkboxRef,
    id: checkboxId,
    type: 'checkbox',
    className: styles.checkbox,
    name,
    // ...(!disabled ? eventProps : {}),
    disabled,
    // ..._.omit(nonEventProps, ['children', 'onChange']),
    checked,
  }
  // when not using Formik Field component, need to use onChange event passing from props
  if (!formik) {
    InputProps.onChange = handleChange
  }
  return (
    <label className={combineClassNames([styles.wrapper, wrapperStyle])} htmlFor={checkboxId}>
      <InputComponent {...InputProps} />
      <div className={combineClassNames([styles.front, frontStyle])} />
      {
      !_.isEmpty(children)
        && <div className={combineClassNames([styles.label, labelStyle])}>{children}</div>
      }
    </label>
  )
}

export default CheckboxView
