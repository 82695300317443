/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-property-newline */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  ErrorMessage,
  Field,
} from 'formik'
import { isSafari } from 'react-device-detect';
import combineClassNames from '../../helpers/combineClassNames';
import useStyles from './input-style'

const Input = ({
  className,
  containerClassName,
  children,
  defaultValue,
  error,
  errorMessage,
  formik,
  disabled,
  label,
  type,
  name,
  placeholder,
  required,
  onChange,
  uppercase,
  rows,
  component,
  nameFile,
  fileInput,
  ...others

}) => {
  const styles = useStyles({
    disabled, error, required, uppercase, nameFile, isSafari,
  })

  const InputComponent = useMemo(() => (formik ? Field : 'input'), [formik])
  const inputProps = {
    disabled: disabled ? 'disabled' : '',
    id: `input-${name}`,
    className,
    defaultValue,
    name,
    placeholder,
    component,
    type,
    ...others,
    rows,
    accept: fileInput ? 'image/jpg, image/jpeg, image/gif, image/png' : '',
  }

  // only onChange is provided and is a function, will pass to `Field
  if (typeof onChange === 'function') {
    inputProps.onChange = onChange
  }

  const classInput = type === 'file' ? styles.fileUploadStyle : styles.input
  return (
    <div className={combineClassNames([classInput, containerClassName])}>
      {label && (
        <label
          htmlFor={`input-${name}`}
          className={combineClassNames([styles.label, required && styles.labelRequired])}
        >
          {label}
        </label>
      )}
      {
        children || (
          <InputComponent
            {...inputProps}
          />
        )
      }
      { formik || fileInput ? <ErrorMessage component="p" className={styles.errorStyle} name={name} /> : '' }
      { errorMessage && <p className={styles.errorStyle}>{errorMessage}</p> }
    </div>
  )
}

Input.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  // inputFile: PropTypes.bool,
  uppercase: PropTypes.bool,
  // isTextArea: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  name: '',
  disabled: false,
  placeholder: '',
  required: false,
  width: '100%',
  // inputFile: false,
  uppercase: false,
  // isTextArea: false,
}

export default Input
