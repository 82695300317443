/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Hammer from 'react-hammerjs'
import useStyles from './image-zoom-style'
import IconZoomIn from '../../assets/icons/icon_zoom_in.svg'
import IconZoomOut from '../../assets/icons/icon_zoom_out.svg'
import combineClassNames from '../../helpers/combineClassNames'

const ImageZoomView = ({
  src,
  alt,
  disabled = false,
  scale = 1.5,
  zoom = false,
  onZoomIn = () => {},
  onZoomReset = () => {},
  onRequestZoomIn = () => {},
  onRequestZoomReset = () => {},
}) => {
  const defaultPosition = { x: 0, y: 0 }
  const [scaleTo, setScaleTo] = useState(1)
  const [prevPosition, setPrevPosition] = useState(defaultPosition)
  const [currentPosition, setCurrentPosition] = useState(defaultPosition)
  const [enableTransition, setEnableTransition] = useState(false)
  const containerRef = useRef(null)
  const wrapperRef = useRef(null)

  const styles = useStyles({ scale: scaleTo, x: currentPosition.x, y: currentPosition.y })

  const resetTransform = () => {
    onRequestZoomReset()
    onZoomReset()
  }

  const zoomIn = () => {
    onRequestZoomIn()
    onZoomIn()
  }

  const handlePan = (event) => {
    if (zoom && !disabled && containerRef) {
      const { width = 0, height = 0 } = containerRef.current.getBoundingClientRect() || {}
      const offsetX = ((width * scale) - width) / scale / 2
      const offsetY = ((height * scale) - height) / scale / 2

      const pos = {
        x: _.clamp(prevPosition.x + (event.deltaX / scale), (offsetX * -1), offsetX),
        y: _.clamp(prevPosition.y + (event.deltaY / scale), (offsetY * -1), offsetY),
      }
      setCurrentPosition(pos)
      if (event.isFinal) {
        setPrevPosition(pos)
      }
    }
  }

  useEffect(() => {
    if (disabled) {
      resetTransform()
    }
  }, [disabled])

  useEffect(() => {
    if (zoom) {
      setScaleTo(scale)
      setEnableTransition(false)
    } else {
      setEnableTransition(true)
    }
  }, [zoom])
  useEffect(() => {
    if (enableTransition) {
      setScaleTo(1)
      setCurrentPosition(defaultPosition)
      setPrevPosition(defaultPosition)
    }
  }, [enableTransition])

  return (
    <div ref={containerRef} className={combineClassNames([styles.container, zoom && styles.zoom])}>
      {
        (zoom) ? (
          <button className={styles.button} type="button" onClick={resetTransform}>
            <img src={IconZoomOut} alt="" />
          </button>
        ) : (
          <button className={styles.button} type="button" onClick={zoomIn}>
            <img src={IconZoomIn} alt="" />
          </button>
        )
      }
      <Hammer
        onPan={handlePan}
      >
        <div
          ref={wrapperRef}
          className={styles.imageWrapper}
        >
          <img
            src={src}
            alt={alt}
            className={combineClassNames([styles.image, enableTransition && styles.panEnd])}
          />
        </div>
      </Hammer>
    </div>
  )
}

export default ImageZoomView
