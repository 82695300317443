import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  orderMethod: {
    apparcence: 'none',
    border: 'none',
    background: 'none',
    padding: '1rem',
    paddingRight: '3.2rem',
    maxWidth: '80vw',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      maxWidth: '45rem',
    },
  },
  orderMethodValue: {
    fontWeight: 600,
  },
  orderMethodIcon: {
    marginLeft: '1rem',
    position: 'absolute',
    top: '50%',
    right: '1rem',
    transform: 'translateY(-50%)',
  },
}))

export default useStyles
