export default function getSocialMediaAvailable(getSystemSetting) {
  const facebookSignInEnabled = getSystemSetting('omni_auth.facebook.enable')
  const appleSignInEnabled = getSystemSetting('omni_auth.apple.enable')
  const socialAvailable = [
    {
      name: 'facebook',
      available: facebookSignInEnabled,
      iconName: 'facebookround',
      backgroundColor: '#1877f2',
    },
    {
      name: 'apple',
      available: appleSignInEnabled,
      iconName: 'apple',
      backgroundColor: '#000000',
    },
  ]
  return socialAvailable
}
