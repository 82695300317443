import { createUseStyles } from 'react-jss'
import { isSafari } from 'react-device-detect';

const useStyles = createUseStyles((theme) => ({
  container: {
    marginTop: '2.4rem',
  },
  row: {
    display: 'flex',
  },
  label: ({ required }) => ({
    textAlign: 'left',
    textTransform: 'none',
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: '-0.03em',
    paddingBottom: '0.9rem',
    display: 'block',
    position: 'relative',
    '&:after': {
      content: required ? '"*"' : '',
      color: theme.colors.alert,
      marginLeft: '0.3rem',
    },
  }),
  select: {
    maxWidth: '10rem',
    minWidth: '8rem',
    width: '25%',
    marginRight: '-1px',
    '& &__control': {
      borderColor: theme.colors.disabled,
      borderRadius: 0,
      fontSize: isSafari ? '1.6rem' : '1.4rem',
      color: theme.colors.text,
      height: '100%',
      minHeight: '4rem',
      '&--is-focused': {
        borderColor: theme.colors.disabled,
        boxShadow: 'none',
      },
    },
    '& &__value-container': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& &__placeholder': {
      whiteSpace: 'nowrap',
    },
    '& &__indicator-separator': {
      display: 'none',
    },
    '& &__dropdown-indicator img': {
      margin: 0,
    },
    '& &__menu': {
      fontSize: isSafari ? '1.6rem' : '1.4rem',
      borderRadius: 0,
    },
    '& &__option--is-focused': {
      backgroundColor: theme.colors.bgMedium,
    },
    '& &__option--is-selected': {
      backgroundColor: theme.colors.text,
      color: theme.colors.bgLight,
    },
  },
  localePhone: (props) => ({
    appearance: 'none',
    width: '100%',
    flex: 1,
    borderColor: theme.colors.disabled,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderRadius: 0,
    outline: 'none',
    fontSize: props.isSafari ? '1.6rem' : '1.4rem',
    lineHeight: 1.143,
    padding: [['0.9rem', '1em']],
  }),
  errorStyle: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '.8rem 0',
  },
}))

export default useStyles
