import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useStyles from './alert-style'
import CrossIcon from '../../assets/icons/icon_cross.svg'

const AlertView = (props) => {
  const {
    alertMessage,
    onClose,
    show,
    state,
  } = props
  const [maxHeight, setMaxHeight] = useState(0)
  const alertRef = useRef(null)
  // const theme = useTheme()

  useEffect(() => {
    setMaxHeight(_.get(alertRef, 'current.clientHeight', 0))
  }, [show])

  const modifiers = {
    maxHeight,
    show,
    state,
    ..._.pickBy(props, _.isBoolean),
  }
  const {
    alert, wrapper, message, closeButton, cross,
  } = useStyles(modifiers)

  // does not render element when it is not show
  if (!show) {
    return null
  }

  return (
    <div className={wrapper}>
      <div className={alert} ref={alertRef}>
        <div
          className={closeButton}
          role="button"
          tabIndex="0"
          onClick={onClose}
          onKeyPress={onClose}
        >
          <img alt="" className={cross} src={CrossIcon} />
        </div>
        <div>
          {!_.isEmpty(alertMessage) && <div className={message}>{alertMessage}</div>}
        </div>
      </div>
    </div>
  )
}

AlertView.propTypes = {
  alertMessage: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

AlertView.defaultProps = {
  alertMessage: '',
  show: false,
  onClose: () => {},
}

export default AlertView
