import colors from './colors'
import mediaQueries from './media-queries'
import sizes from './sizes'
import config from './config'
import typo from './typography/font-sizes'

const theme = {
  ...sizes,
  mediaQueries,
  config,
  colors,
  typo,
}

export default theme
