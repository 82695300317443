/**
 * getResetPasswordFormInitialValues
 * base on token to generate a initial value for reset password form
 *
 * @param token
 */
export default function getResetPasswordFormInitialValues(token) {
  let initialValues = {}
  if (token) {
    initialValues = {
      password: '',
      passwordConfirmation: '',
    }
  } else {
    initialValues = {
      email: '',
    }
  }
  return initialValues
}
