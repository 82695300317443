import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

export default createUseStyles((theme) => ({
  loader: {
    borderRadius: '50%',
    width: '10em',
    height: '10em',
    margin: [[60, 'auto']],
    fontSize: 10,
    position: 'relative',
    textIndent: '-9999em',
    borderTop: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderRight: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderBottom: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderLeft: `1.1em solid ${theme.colors.secondary}`,
    transform: 'translateZ(0)',
    animation: '$spinnerRotate 1.1s infinite linear',
    '&:after': {
      borderRadius: '50%',
      width: '10em',
      height: '10em',
    },
  },
  loaderSmall: {
    borderRadius: '50%',
    width: '3em',
    height: '3em',
    margin: [[10, 'auto']],
    fontSize: 10,
    position: 'relative',
    textIndent: '-9999em',
    borderTop: `0.5em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderRight: `0.5em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderBottom: `0.5em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderLeft: `0.5em solid ${theme.colors.secondary}`,
    transform: 'translateZ(0)',
    animation: '$spinnerRotate 1.1s infinite linear',
    '&:after': {
      borderRadius: '50%',
      width: '3em',
      height: '3em',
    },
  },
  loaderBox: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
  },
  '@keyframes spinnerRotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

// #fecf0a
