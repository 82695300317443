/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useCart,
  useDineInCart,
  useOrderMethod,
} from 'react-omnitech-api'
import {
  useThemeConfig,
} from '../../hook'
import OrderMethodView from './order-method-view'

export default function OrderMethodController({
  onContinue,
}) {
  const { t } = useTranslation()
  const location = useLocation()
  const {
    orderMethod,
    deliveryAddress,
    store,
  } = useOrderMethod()
  const {
    initCart: initDineInCart,
  } = useDineInCart()
  const {
    initCart,
  } = useCart()
  const {
    selectableOrderMethod,
  } = useThemeConfig()

  const [modalOpen, setModalOpen] = useState(false)
  const pickupStore = useMemo(() => (_.get(initCart, 'cartShipments.0.pickupStore', {})), [initCart])
  const storeName = useMemo(() => (
    _.get(pickupStore, 'name', _.get(store, 'name'))
  ), [pickupStore, store])
  const dineInCartMeta = useMemo(() => (_.get(initDineInCart, 'meta', {})), [initDineInCart])
  const orderMethodValue = useMemo(() => {
    const dineInTableNumber = _.get(dineInCartMeta, 'dineInTableNumber', '')
    const dineInTableSubNumber = _.get(dineInCartMeta, 'dineInTableSubNumber', '')
    const table = `${dineInTableNumber}${dineInTableSubNumber}`
    switch (_.get(orderMethod, 'selectStoreBy')) {
      case 'deliveryAddress':
        return _.get(deliveryAddress, 'name')
                || _.join(_.get(deliveryAddress, 'formattedAddressLines', []), ', ')
      default:
        return (
          !_.isEmpty(table) && _.get(orderMethod, 'code') === 'dineIn'
            ? t('ui.orderMethod.table', { table })
            : storeName
        )
    }
  }, [deliveryAddress, dineInCartMeta, orderMethod, storeName, t])

  const onClick = () => {
    setModalOpen(true)
  }
  const requestModalClose = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (
      _.has(location, 'state.preSelectedOrderMethod')
      && !_.get(location, 'state.isBlocker', true)
    ) {
      setModalOpen(true)
    }
  }, [location.state])

  const viewProps = {
    modalOpen,
    onClick,
    onContinue,
    orderMethod,
    orderMethodValue,
    requestModalClose,
    selectableOrderMethod,
  }

  return (
    <OrderMethodView {...viewProps} />
  )
}
