/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
// import PropTypes from 'prop-types'
// import Link from '../link'
import MessageScreen from '../message-screen'
import useStyles from './empty-screen-style'
// import Button from '../button'

const EmptyScreen = ({
  message,
  ...others
}) => {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <MessageScreen
        {...others}
      >
        {message && <p className={styles.message}>{message}</p>}
      </MessageScreen>
    </div>
  )
}

export default EmptyScreen
