import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  group: {
    position: 'relative',
    overflow: 'hidden',
  },
  label: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: '1.6rem',
    // '&:hover': {
    //   opacity: 0.8,
    // },
  },
  radio: {
    marginRight: 0,
    marginLeft: '2rem',
    minWidth: '2rem',
    width: '2rem',
    height: '2rem',
  },
  text: {
    width: '100%',
    fontSize: theme.typo.textBodySize,
    lineHeight: 1.25,
  },
  separator: {
    border: 'none',
    borderBottom: `0.1rem solid ${theme.colors.bgMediumDark}`,
    padding: 0,
    margin: 0,
  },
}))

export default useStyles
