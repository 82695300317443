/* eslint-disable import/no-cycle */
import React from 'react'
import PropTypes from 'prop-types'
import ContentGroupTemplate from './content-group-template'

const ContentGroupView = (props) => {
  const { contentGroup, ready, template } = props
  // when the content group is not ready, does not show the component
  if (!ready) {
    return null
  }

  return <ContentGroupTemplate contentGroup={contentGroup} template={template} {...props} />
}

ContentGroupView.propTypes = {
  contentGroup: PropTypes.object.isRequired,
  template: PropTypes.string.isRequired,
}

export default ContentGroupView
