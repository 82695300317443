import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  addressBook: {
    paddingTop: '3rem',
    textAlign: 'left',
    paddingBottom: '3rem',
    '& h4': {
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      lineHeight: 1.25,
      marginBottom: '2rem',
    },
  },
  boxAdress: {
    height: 'auto',
    overflowY: 'auto',
  },
  buttons: {
    [`@media (min-width: ${theme.tablet}px)`]: {
      marginLeft: '-1.5rem',
      marginRight: '-1.5rem',
      display: 'flex',
    },
  },
  button: {
    marginBottom: '1rem',
    [`@media (min-width: ${theme.tablet}px)`]: {
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
    },
  },
  buttonAdd: {
    [`@media (min-width: ${theme.tablet}px)`]: {
      maxWidth: '37rem',
    },
  },
  buttonCancel: {
    [`@media (min-width: ${theme.tablet}px)`]: {
      maxWidth: '17rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    boxAdress: {
      height: 'auto',
      overflowY: 'auto',
    },

  },
}))

export default useStyles
