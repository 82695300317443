/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import { useLink } from '../../hook'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './message-screen-style'
import Button from '../button'

const MessageScreen = ({
  title,
  message,
  children,
  button,
  subMessage,
  miniCartCoupon,

}) => {
  const {
    className: buttonClassName,
    navigate: buttonNavigateTo,
    onClick,
    ...buttonProps
  } = button
  const { navigate } = useLink()
  const styles = useStyles({ miniCartCoupon })

  const handleNavigate = () => {
    navigate(buttonNavigateTo)
  }

  return (
    <div className={styles.container}>
      {title && <h1 className={styles.title}>{title}</h1>}
      <div className={styles.content}>
        {message && (
          _.isArray(message)
            ? _.map(message, (line, index) => (
              <p key={`p_${index}`} className={styles.message}>{line}</p>
            ))
            : (<p className={styles.message}>{message}</p>)
        )}
        {children}
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          { button && (
            <Button
              className={combineClassNames([styles.button, buttonClassName])}
              // uppercase
              dark
              onClick={_.isEmpty(buttonNavigateTo) ? onClick : handleNavigate}
              {...buttonProps}
            />
          )}
        </div>
        {subMessage
          && (
          <span className={styles.subMessage}>
            {subMessage}
          </span>
          )}
      </div>
    </div>
  )
}

export default MessageScreen
