/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import Link from '../link'
import Frame from './frame'
import useStyles from './modal-compare-style'
import Button from '../button'
import combineClassNames from '../../helpers/combineClassNames'

const ModalCompareView = ({
  compareData,
  onAddToCompare,
  goToCompareProducts,
  t,
  isOpen,
  onClearCompare,
  maxNumberComparisonEcom,
}) => {
  const [frames, setFrames] = useState(_.range(maxNumberComparisonEcom))
  useEffect(() => {
    setFrames(_.concat(compareData, _.range(maxNumberComparisonEcom - compareData.length)))
  }, [compareData])

  const styles = useStyles({ isOpen })

  const handleRemoveCartLine = (sku) => {
    onAddToCompare(sku, true)
  }

  const handleClearCompare = () => {
    onClearCompare()
  }
  const theme = useTheme()
  return (
    <div className={
        combineClassNames(
          [styles.containerFluid,
            isOpen ? styles.containerFluidOpen : '',
            !_.isEmpty(theme.config.backgroundUrl) ? styles.hasBackgroundUrl : '',
          ],
        )
      }
    >
      <div className={styles.container}>
        <div className={styles.boxFramesText}>
          <div className={styles.containerFrames}>
            {
              _.map(frames, (frame, idx) => (
                <Frame
                  key={idx}
                  frame={frame}
                  handleRemoveCartLine={handleRemoveCartLine}
                />
              ))
            }
          </div>
          <div className={styles.containerText}>
            <p className={styles.compareText}>
              {t('ui.modalCompare.compare', { items: maxNumberComparisonEcom })}
            </p>
            <Button
              className={combineClassNames([styles.buttonAll, styles.buttonAllMobile])}
              text={t('ui.modalCompare.clearAll')}
              onClick={handleClearCompare}
            />
          </div>
        </div>
        <div className={styles.containerBtns}>
          <Button
            className={styles.buttonAll}
            text={t('ui.modalCompare.clearAll')}
            onClick={handleClearCompare}
          />
          <div
            className={styles.link}
          >
            <Button
              disabled={compareData.length <= 1}
              dark
              text={t('ui.modalCompare.compareBtn',
                {
                  items: compareData.length,
                  maxvalue: maxNumberComparisonEcom,
                })}
              margin="0"
              onClick={goToCompareProducts}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalCompareView
