import _ from 'lodash'
import isNullOrUndefined from './is-null-or-undefined'

/**
 * getUpdateProfileFormInitialValues
 * base on form config to generate a initial value for update profile form
 *
 * @param { formConfig, userData, defaultCountryCallingCode }
 */
export default function getUpdateProfileFormInitialValues({
  formConfig = {},
  userData = {},
  defaultCountryCallingCode = '',
}) {
  const countryCallingCode = (_.get(userData, 'phoneData.countryCallingCode') || defaultCountryCallingCode).replace(/^\+/, '')
  const phoneRegExpObj = RegExp(`^(?:\\+*${countryCallingCode})(?<localPhoneNumber>[0-9_*#-]+)`)
  const localPhoneNumber = _.get(phoneRegExpObj.exec(_.get(userData, 'phone', 'NoPhoneNumber')), 'groups.localPhoneNumber', '')
  return _.reduce(formConfig, (result, current, key) => {
    const newResult = {
      ...result,
      [key]: _.get(userData, key, _.includes(['meta', key]) ? {} : ''),
    }
    switch (key) {
      case 'phone':
        newResult.token = ''
        newResult.countryCallingCode = countryCallingCode
        newResult.localPhoneNumber = localPhoneNumber
        break
      case 'email':
        if (current.requireConfirmation === true) {
          newResult.emailConfirmation = ''
        }
        break
      case 'password':
        if (current.requireConfirmation === true) {
          newResult.passwordConfirmation = ''
        }
        break
      case 'screenName':
        if (isNullOrUndefined(_.get(userData, 'screenName'))) {
          newResult.screenName = _.get(userData, 'alias', '')
        }
        break
      default:
        break
    }
    return newResult
  }, {})
}
