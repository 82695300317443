import React from 'react'
import { useTheme } from 'react-jss'
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import Link from '../link'
import Alert from '../alert'
import useStyles from './header-checkout-style'
import combineClassNames from '../../helpers/combineClassNames'
import TofugearLogo from '../../assets/icons/tofugear-logo.svg'

const HeaderCheckoutView = ({
  className, breakpoints,
}) => {
  const theme = useTheme()
  const siteLogo = breakpoints.sm
    ? theme.config.logoImageMobile || TofugearLogo : theme.config.logoImageDesktop || TofugearLogo

  const styles = useStyles({ siteLogo })
  return (
    <header className={combineClassNames([styles.containerFluid, className])}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link to="/" className={styles.logoImage} />
        </div>
      </div>
      <Alert />
    </header>
  )
}

export default withBreakpoints(HeaderCheckoutView)
