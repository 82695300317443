/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({
  containerDetail: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    background: theme.colors.background,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: '-100vh',
    transition: 'bottom 300ms',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      right: -500,
      transition: 'right 300ms',
      width: 500,
    },
  },
  containerDetailOpen: {
    bottom: '0',
    transition: 'bottom 300ms',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      right: 0,
      transition: 'right 300ms',
    },
  },
  content: {
    padding: [[11, 10]],
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: [[11, 22]],
    },
  },
  footerDetail: (props) => {
    let heightButton = 0
    if (props.isAndroid) {
      heightButton = 165
    } else if (props.isIOS && props.isChrome) {
      heightButton = 265
    } else if (props.isIOS) {
      heightButton = 235
    } else {
      heightButton = 85
    }
    return {
      borderTop: `1px solid ${theme.colors.bgMediumDark}`,
      height: `${heightButton}px`,
      width: '100%',
      padding: '2rem',
    }
  },
  bodyDetail: {
    width: '100%',
    height: 'calc(100vh - 153px)',
    background: theme.colors.bgLight,
    padding: 0,
    overflowY: 'auto',
  },
  messsages: {
    minHeight: '3.6rem',
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `0 0.5px 0 0 ${theme.colors.bgMediumDark}`,
    background: theme.colors.background,
    '& p': {
      margin: 0,
      fontSize: '1.4rem',
      lineHeight: 1.18,
      fontWeight: 400,
      '& span': {
        fontWeight: 600,
        marginLeft: '0.5rem',
      },
    },
  },
  buttonDetail: ({ isSubmitDisabled }) => ({
    color: isSubmitDisabled
      ? theme.colors.background
      : theme.colors.secondary,
  }),
  error: {
    padding: '1rem',
    width: '100%',
  },
  errorBox: {
    color: theme.colors.alert,
    padding: '1rem',
    background: Color(theme.colors.alert).alpha(0.1).string(),
    textAlign: 'left',
    '& span': {
      fontSize: '1.2rem',
      lineHeight: 1.14,
    },
  },
  termsStyle: {
    padding: '3.1rem 2rem 2rem',
    '& h4': {
      fontSize: '1.6rem',
      margin: 0,
      fontWeight: 600,
      marginBottom: '2.1rem',
    },
    '& p': {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      color: Color(theme.colors.text).alpha(0.8).string(),
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    termsStyle: {
      padding: '3.1rem 1.6rem 2rem',
    },
  },
}))

export default useStyles
