/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import useStyles from './coupon-marketplace-modal-style'
import CrossIcon from '../../../../assets/icons/icon_cross.svg'
import Button from '../../../button'
import combineClassNames from '../../../../helpers/combineClassNames'

const CouponMarketplaceModalView = ({
  title,
  message,
  buttons = [],
  onCouponMarketplaceClose,
  isModalOpen,
}) => {
  const styles = useStyles()

  const buttonsProps = {
    confirm: {
      dark: true,
      uppercase: true,
      className: styles.confirmButton,
    },
    cancel: {
      border: true,
      className: styles.cancelButton,
      uppercase: true,
    },
  }

  return (
    <div className={
      combineClassNames([styles.containerModal,
        isModalOpen && styles.containerModalOpen])
      }
    >
      {/* close button */}
      <div
        className={styles.closeButton}
        onClick={onCouponMarketplaceClose}
      >
        <img alt="" className={styles.cross} src={CrossIcon} />
      </div>

      <div className={styles.boxModal}>
        <h4 className={styles.titleModal}>{title}</h4>
        <p className={styles.textModal}>{message}</p>
        <div className={styles.boxButtons}>
          {
            buttons.map((button) => {
              const buttonProps = buttonsProps[button.type] || buttonsProps.confirm
              return (
                <Button
                  text={button.text}
                  onClick={button.onClick}
                  {...buttonProps}
                />
              )
            })
          }
        </div>
      </div>
    </div>

  )
}

export default CouponMarketplaceModalView
