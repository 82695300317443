import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  breadcrumb: {
    padding: [[0, 15]],
    margin: [[0, 0, 15]],
    listStyle: 'none',
    color: theme.colors.primary,
    textAlign: 'left',
    '& li': {
      display: 'inline',
      fontSize: '1.6rem',
      fontWeight: 300,
      '& p': {
        display: 'inline-block',
      },
      '&+li:before': {
        padding: 8,
        color: theme.colors.primary,
        content: `"${String.fromCharCode(62)}"`,
      },
      '& a': {
        color: theme.colors.disabled,
        textDecoration: 'none',
      },
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    breadcrumb: {
      padding: 0,
      margin: [[0, 0, 30]],
    },
  },
}))

export default useStyles
