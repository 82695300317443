import { useContext } from 'react'
import LinkContext from './link-context'

/**
 * useLink
 * The custom hook which provided to external use via context
 */
const useLink = () => {
  const context = useContext(LinkContext);
  return context
};

export default useLink;
