/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'

function SmsTokenButtonView(props) {
  const {
    disabled,
    smsTokenCountDown,
    smsTokenDisable,
    ...rest
  } = props
  const { t } = useTranslation()

  return (
    <Button
      disabled={smsTokenDisable || disabled}
      text={t('ui.smsTokenButton.label', {
        count: smsTokenCountDown,
        context: smsTokenCountDown <= 0 ? '0' : '1', // FL: added context to i18n key since i18next's Plural function doesn't work.
      })}
      {...rest}
    />
  )
}

export default SmsTokenButtonView
