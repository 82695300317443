import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  title: (props) => ({
    fontSize: '2.4rem',
    fontWeight: 600,
    margin: props.margin,
    color: theme.colors.primary,
    lineHeight: 1.16,
    position: 'relative',
    width: 'auto',
    textAlign: 'left',
    textTransform: props.uppercase ? 'uppercase' : 'initial',
    '&:after': {
      content: '""',
      position: 'absolute',
      height: 3,
      width: 50,
      backgroundColor: theme.colors.secondary,
      left: 0,
      bottom: '-12px',
    },
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      fontSize: '3.6rem',
    },
  }),
  [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
    title: () => ({
      fontSize: '3.6rem',
      lineHeight: 1.16,
    }),
  },
}))

export default useStyles
