import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  button: ({ icon, iconButton }) => ({
    position: 'relative',
    width: '100%',
    fontWeight: 'bold',
    border: '2px solid currentColor',
    fontSize: '1.6rem',
    // padding: props.mobilePadding,
    backgroundColor: theme.colors.background,
    color: theme.colors.primary,
    textTransform: 'uppercase',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    height: 'auto',
    lineHeight: 1,
    padding: '1.5rem 2rem',
    ...(iconButton ? {
      padding: '1.5rem 2rem 1.5rem 4.2rem',
    } : {}),
    ...(icon ? {
      lineHeight: 'normal',
      border: 'none',
      width: 'auto',
      backgroundColor: 'transparent',
      padding: '1.25rem 1.1rem',
    } : {}),
  }),
  buttonDisabled: ({ icon }) => ({
    color: theme.colors.disabled,
    border: `2px solid ${theme.colors.disabled}`,
    ...(icon ? {
      border: 'none',
    } : {}),
  }),
  icon: ({ icon, like }) => ({
    margin: 0,
    display: 'block',
    position: 'absolute',
    left: '1.4rem',
    top: '50%',
    color: like ? theme.colors.secondary : theme.colors.text,
    opacity: 1,
    transform: 'translateY(-50%)',
    ...(icon ? {
      position: 'static',
      transform: 'none',
    } : {}),
  }),
  iconDisabled: () => ({
    opacity: 0.5,
  }),
}))

export default useStyles
