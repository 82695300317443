import _ from 'lodash'

/**
 *
 * @param {array} cartLineProperties an array of cartLineProperties
 */
export default function groupCartLineProperties(cartLineProperties) {
  return _.map(
    _.filter(cartLineProperties, 'isMainProperty'),
    (line) => ({
      ...line,
      addons: _.filter(
        cartLineProperties,
        ({ isMainProperty, groupUuid }) => !isMainProperty && _.isEqual(groupUuid, _.get(line, 'groupUuid')),
      ),
    }),
  )
}
