import { createContext } from 'react'

/**
 * LinkContext
 * Create Link context for custom hook
 */
const LinkContext = createContext({
  availableOrderMethod: [],
  getConfig: () => {},
  getContentGroup: () => {},
  selectableOrderMethod: [],
})

export default LinkContext
