/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyFormat from '../currency-format'
import CustomLables from '../custom-labels'
import FavouriteButton from '../favourite-button'
import Link from '../link'
import Checkbox from '../checkbox'
import Image from './components/image'
import SwatchList from './components/swatch-list'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './product-card-style'
// TODO add color carousel in Product List
// import ColorCarousel from '../color-carousel'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductCardView = (props) => {
  const {
    activeCustomLabels,
    colorName,
    colorOptionId,
    colorOptionVariantType = {},
    compareData,
    enableComparisonEcom,
    favourite,
    hasMoreThan,
    hideFavourites,
    inventoryStoreCode,
    imageUrl,
    onAddToCompare,
    onClick,
    onFavouriteChange,
    onSwatchItemClick,
    onSwatchMoreClick,
    originalPrice,
    product,
    productDetailUrl,
    productId,
    firstSkuOfSelectedColorOption,
    sellPrice,
    showSwatches,
    sku,
    stockLevel,
    title,
  } = props

  // prepare hook
  const { t } = useTranslation()

  // states
  const [noImage, setNoImage] = useState(_.isEmpty(imageUrl))

  const onSale = originalPrice !== sellPrice
  const styles = useStyles({ product })

  useEffect(() => {
    if (_.isEmpty(imageUrl) !== noImage) {
      setNoImage(_.isEmpty(imageUrl))
    }
  }, [imageUrl])
  return (
    <div className={styles.productCard}>
      <figure className={styles.figure}>
        { !hideFavourites && (
          <div className={styles.favouriteButton}>
            <FavouriteButton
              colorOptionID={colorOptionId}
              favourite={favourite}
              onChange={onFavouriteChange}
              icon
            />
          </div>
        )}
        <Link
          to={productDetailUrl}
          onClick={onClick}
          className={styles.link}
        >
          <Image src={imageUrl} alt={title} />
        </Link>
      </figure>
      <Link
        to={productDetailUrl}
        onClick={onClick}
        className={styles.link}
      >
        <div className={styles.content}>
          <div className={styles.customLabels}>
            <CustomLables
              stockLevel={stockLevel}
              customLabels={[_.head(activeCustomLabels)]}
              frontendPosition="1"
            />
          </div>

          {/* TODO: replace it with MultilineTitle, using `|` as line break */}
          <p>{title}</p>

          {/* TODO: in `p` level, show sku.product.defaultColorOption.name */}
          {/* TODO: in `pc` level, show sku.colorOption.name */}
          {!colorOptionVariantType.hideVariant && (
            <p className={styles.colorSelect}>{colorName}</p>
          )}
          <p className={styles.price}>
            {onSale && (
              <CurrencyFormat
                className={styles.originalPrice}
                value={originalPrice}
              />
            )}
            <CurrencyFormat
              className={combineClassNames([styles.sellPrice, onSale && styles.sellPriceOnSale])}
              value={sellPrice}
            />
          </p>
        </div>
      </Link>
      {
        showSwatches && (
          <SwatchList
            sku={sku}
            inventoryStoreCode={inventoryStoreCode}
            onItemClick={onSwatchItemClick}
            onMoreClick={onSwatchMoreClick}
            selectedColorOptionId={colorOptionId}
          />
        )
      }
      {
        compareData && enableComparisonEcom
          && (
          <Checkbox
            wrapperStyle={styles.checkbox}
            frontStyle={styles.front}
            checked={_.find(compareData, (obj) => obj.id === _.get(firstSkuOfSelectedColorOption, 'id', sku.id))}
            onChange={() => onAddToCompare(firstSkuOfSelectedColorOption || sku)}
            disabled={hasMoreThan && !_.find(compareData, (obj) => obj.id === sku.id)}
          >
            <p className={styles.textCheckbox}>{t('ui.productCard.compare')}</p>
          </Checkbox>
          )
      }

      {/* using in favorites and color so make logical it doesn't show in favorites */}
      {/* only show color carousel when color option is available */}
      {/* {productPage && !_.isEmpty(colorOptions) &&
        <ColorCarousel colors={colorOptions} onselect selected />} */}
    </div>
  )
}

export default ProductCardView
