import moment from 'moment'
import isExpireSoon from './is-expire-soon'
import isExpired from './is-expired'

export default function orderMonths(data, sortByType = 'activeAt', checkExpired = false) {
  const collection = {}
  for (let i = 0; i < data.length; i += 1) {
    let month = moment(data[i][sortByType]).format('YYYY-MM')
    if (checkExpired) {
      if (isExpired(data[i][sortByType])) {
        month = 'expired'
      } else if (isExpireSoon(data[i][sortByType])) {
        month = 'expireSoon'
      }
    }
    if (collection[month] === undefined) {
      collection[month] = [];
    }
    collection[month].push(data[i]);
  }
  return Object.keys(collection).map((key) => ({
    id: key,
    month: key,
    options: collection[key],
  }))
}
