/**
 * Create mini cart context for custom hook
 */

import { createContext } from 'react'

const DineInMiniCartContext = createContext({
  cart: {},
  loading: true,
  miniCartOpen: false,
  totalItems: '',
  openMiniCart: () => {},
  closeMiniCart: () => {},
  onRemoveCartLine: () => {},
})

export default DineInMiniCartContext
