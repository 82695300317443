import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  dialogContainer: {
    maxWidth: '57rem',
    margin: '0 auto',
    padding: '0.8rem 0',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '0 1.6rem',
    },
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  closeButton: {
    position: 'absolute',
    top: '0.9rem',
    right: '0.8rem',
    zIndex: 10,
    padding: '1rem',
  },
  closeButtonIcon: {
    width: '1.4rem',
    height: '1.4rem',
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '3rem',
  },
  dialogDescription: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '2rem',
  },
  dialogButtons: {
    maxWidth: '33.8rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '-1.6rem',
  },
  button: {
    marginBottom: '1.6rem',
  },
}))

export default useStyles
