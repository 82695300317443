// Only one item MUST have the "default: true" key
// Required to use CommonJS export, because it is required in gatsby-node file
// and it is running in node environment

module.exports = {
  'en-HK': {
    path: 'en-HK',
    locale: 'en-HK',
    dateFormat: 'DD/MM/YYYY',
    siteLanguage: 'en-HK',
    ogLanguage: 'en_US',
    apiLocaleCode: 'en-HK',
  },
  'zh-HK': {
    path: 'zh-HK',
    locale: 'zh-HK',
    dateFormat: 'DD.MM.YYYY',
    siteLanguage: 'zh-HK',
    ogLanguage: 'zh_HK',
    apiLocaleCode: 'zh-HK',
  },
  'zh-TW': {
    path: 'zh-TW',
    locale: 'zh-TW',
    dateFormat: 'DD.MM.YYYY',
    siteLanguage: 'zh-TW',
    ogLanguage: 'zh_TW',
    apiLocaleCode: 'zh-TW',
  },
}
