import i18next from 'i18next'

/**
 * getCartLineError
 * @param { maxSelectableQty, quantity, skuIsInactive, stockLevel}
 */
export default function getCartLineError({
  maxSelectableQty,
  quantity,
  skuIsInactive,
  stockLevel,
}) {
  let errorMessage = ''
  switch (true) {
    case stockLevel === 0:
      errorMessage = i18next.t('screens.cart.errors.cartLine', { context: 'outOfStock' })
      break
    case quantity > stockLevel:
      errorMessage = i18next.t('screens.cart.errors.cartLine', { context: 'notEnoughStock' })
      break
    case quantity > maxSelectableQty:
      errorMessage = i18next.t('screens.cart.errors.cartLine', {
        context: 'exceededMaximumQuantitiy',
        qty: maxSelectableQty,
      })
      break
    case skuIsInactive:
      errorMessage = i18next.t('screens.cart.errors.cartLine', { context: 'notAvailable' })
      break
    default:
      break
  }
  return errorMessage
}
