import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    backgroundColor: theme.colors.background,
    borderBottom: `2px solid ${theme.colors.primary}`,
    width: '100%',
  },
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    margin: [[0, 'auto']],
    minHeight: 48,
    '& h1': {
      margin: 0,
      maxWidth: 145,
      '& img': {
        margin: 0,
      },
    },
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      minHeight: 132,
      '& h1': {
        maxWidth: '100%',
        border: 'none',
      },
    },
  },
  logo: {
    width: '80%',
    height: '3.8rem',
    maxWidth: '14.4rem',
    margin: 0,
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      width: '40%',
      maxWidth: '25rem',
      height: '10rem',
    },
  },
  logoImage: {
    display: 'block',
    width: '100%',
    minHeight: '3.4rem',
    height: '100%',
    margin: 0,
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundImage: (props) => (`url(${props.siteLogo})`),
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      maxWidth: '100%',
      minHeight: '6.6rem',
    },
  },
}))

export default useStyles
