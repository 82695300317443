/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import useStyles from './avatar-image-style'
import combineClassNames from '../../helpers/combineClassNames'
import placeholderImage from '../../assets/icons/icon_avatar.svg'

const AvatarImageView = ({
  className, imgClassName, src, alt, ...others
}) => {
  // states
  const [noImage, setNoImage] = useState(_.isEmpty(src))

  const styles = useStyles()

  useEffect(() => {
    if (_.isEmpty(src) !== noImage) {
      setNoImage(_.isEmpty(src))
    }
  }, [src])

  return (
    <div className={combineClassNames([styles.container, className])}>
      <img
        role="presentation"
        className={combineClassNames([styles.img, noImage && styles.noImage, imgClassName])}
        src={noImage ? placeholderImage : src}
        alt={alt}
        {...others}
        onError={() => {
          setNoImage(true)
        }}
      />
    </div>
  )
}

export default AvatarImageView
