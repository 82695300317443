/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useCoupons,
  useCouponTokens,
  useUser,
  useSystemSettings,
} from 'react-omnitech-api'
import { useAlert } from '../use-alert'
import CouponMarketplaceContext from './coupon-marketplace-context'

/**
 * CouponMarketplaceProvider
 * Contain most logic handing coupon marketplace logic
 */
export default function CouponMarketplaceProvider({
  children,
}) {
  // prepare hook
  const alert = useAlert()
  const { t } = useTranslation()
  const {
    fetchCoupons,
    fetchCoupon,
  } = useCoupons()
  const {
    createCouponToken,
    fetchCouponToken,
    fetchCouponTokens,
  } = useCouponTokens()
  const { getSystemSetting } = useSystemSettings()
  const {
    fetchUser,
    user,
  } = useUser()

  const defaultModalContent = {
    coupon: {},
    title: '',
    message: '',
    textCancelButton: '',
    textConfirmButton: '',
  }

  // internal state
  const [hasMoreCoupons, setHasMoreCoupons] = useState(false)
  const [hasMoreCouponTokens, setHasMoreCouponTokens] = useState(false)
  const [isCouponMarketplaceOpen, setIsCouponMarketplaceOpen] = useState(false)
  const [isCouponRedeemed, setIsCouponRedeemed] = useState(false)
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [isDetailReady, setIsDetailReady] = useState(false)
  const [isCouponsReady, setIsCouponsReady] = useState(false)
  const [isCouponTokensReady, setIsCouponTokensReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [couponTokens, setCouponTokens] = useState([])
  const [coupon, setCoupon] = useState({})
  const [couponToken, setCouponToken] = useState({})
  const [detailType, setDetailType] = useState('coupon')
  const [modalContent, setModalContent] = useState(defaultModalContent)
  const [fetchNextCoupons, setFetchNextCoupons] = useState(null)
  const [fetchNextCouponTokens, setFetchNextCouponTokens] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState()
  const [popupMessage, setPopupMessage] = useState('')

  // local variables
  const isCouponDetailOpen = isDetailOpen && detailType === 'coupon'
  const isCouponTokenDetailOpen = isDetailOpen && detailType === 'couponToken'

  // useMemo
  const userCurrentPoints = useMemo(() => (
    _.get(user, 'totalLoyaltyPoints', 0)
  ), [user])

  // function
  function handleCouponMarketplaceOpen() {
    setIsCouponMarketplaceOpen(true)
  }

  function handleCouponMarketplaceClose() {
    setCoupon({})
    setCoupons([])
    setCouponToken({})
    setCouponTokens([])
    setIsCouponRedeemed(false)
    setFetchNextCoupons(null)
    setFetchNextCouponTokens(null)
    setHasMoreCoupons(false)
    setHasMoreCouponTokens(false)
    setDetailType('')
    setIsDetailOpen(false)
    setIsDetailReady(false)
    setIsModalOpen(false)
    setIsCouponMarketplaceOpen(false)
    setIsCouponsReady(false)
    setIsCouponTokensReady(false)
    handlePopupClose()
  }

  function handleCouponDetailClose() {
    setCoupon({})
    setCouponToken({})
    setIsCouponRedeemed(false)
    setIsDetailOpen(false)
    setIsDetailReady(false)
    handlePopupClose()
  }

  async function handleCouponDetailOpen({ id, type = 'coupon' }) {
    setIsDetailOpen(true)
    setDetailType(type)
    try {
      if (type === 'coupon') {
        const { coupon: data } = await fetchCoupon({
          id,
        })
        setCoupon(data)
      } else {
        const { couponToken: data } = await fetchCouponToken({
          id,
          includes: [
            'coupon',
            'coupons.cart_discount',
          ].join(','),
        })

        setCouponToken(data)
      }
      setIsDetailReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }

  async function handleFetchCoupons(fetchNextPage) {
    try {
      const options = {
        sortBy: 'end_at',
        pageSize: 5,
      }
      const { coupons: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchCoupons(options)

      // update state
      setCoupons((prevState) => prevState.concat(data))
      setHasMoreCoupons(_.isFunction(next))
      setFetchNextCoupons(() => next)
      if (_.isEmpty(fetchNextPage)) {
        setIsCouponsReady(true)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }

  function handleFetchCouponsNextPage() {
    if (!_.isFunction(fetchNextCoupons)) return

    handleFetchCoupons(fetchNextCoupons)
  }

  async function handleFetchCouponTokens(fetchNextPage) {
    try {
      // api.v2.coupon_tokens.index.my_coupons.ecom.query
      const apiQuery = getSystemSetting('api.v2.coupon_tokens.index.my_coupons.ecom.query', {})
      const options = {
        sortBy: 'expires_at',
        includes: [
          'coupon',
        ].join(','),
        ...apiQuery,
      }
      const { couponTokens: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchCouponTokens(options)

      // update state
      setCouponTokens((prevState) => prevState.concat(data))
      setHasMoreCouponTokens(_.isFunction(next))
      setFetchNextCouponTokens(() => next)
      // after fetching first page, need to update ready
      if (_.isEmpty(fetchNextPage)) {
        setIsCouponTokensReady(true)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }

  function handleFetchCouponTokensNextPage() {
    if (!_.isFunction(fetchNextCouponTokens)) return

    handleFetchCouponTokens(fetchNextCouponTokens)
  }

  function handleApplyCoupon() {
    setIsLoading(true)
  }

  function handleApplyCouponError(error) {
    const generalError = _.get(error, 'generalError', {})
    const batchActionError = _.get(error, 'batchActionErrors[0]') || {}
    // if batch action is not provided, use the general error message
    const errorMessage = _.isEmpty(batchActionError.message)
      ? generalError.message
      : handleApplyCouponErrorMessage(batchActionError)
    setIsLoading(false)
    handleModalOpen({
      title: t('ui.minicartCoupons.sorry'),
      message: errorMessage,
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.dismiss'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  function handleApplyCouponErrorMessage(error = {}) {
    switch (error.code) {
      case 20409: {
        const applyLImitPerOrder = _.get(couponToken, 'coupon.applyLimitPerOrder', '')
        return applyLImitPerOrder === ''
          ? error.message
          : t(`omnitechError.${error.code}`, { count: applyLImitPerOrder })
      }
      default:
        return error.message
    }
  }

  function handleApplyCouponSuccess() {
    setIsLoading(false)
    handleModalOpen({
      title: t('ui.minicartCoupons.couponApplied'),
      message: t('ui.minicartCoupons.couponHasBeenApplied'),
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.dismiss'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  function handleRedeemCoupon(couponData) {
    handleModalOpen({
      title: t('ui.minicartCoupons.modal.redeemCoupon'),
      message: t(
        'ui.minicartCoupons.modal.areYouSureRedeemCoupon',
        { points: couponData.redeemableRequiredLoyaltyPoints },
      ),
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.confirm'),
          onClick: () => {
            handleRedeemCouponConfirm(couponData)
          },
        },
        {
          type: 'cancel',
          text: t('ui.minicartCoupons.modal.buttons.cancel'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  async function handleRedeemCouponConfirm(couponData) {
    try {
      setIsLoading(true)
      setIsModalOpen(false)

      await createCouponToken({
        couponID: couponData.id,
        data: {
          coupon_token: {
            userId: user.id,
          },
        },
      })

      handleRedeemCouponSuccess()
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      // alert.show(generalError.message)
      handleRedeemCouponError(generalError.message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleRedeemCouponSuccess() {
    handlePopupOpen(t('ui.minicartCoupons.redeemSuccess'))
    try {
      // refresh user asynchronous, to show latest loyalty point
      fetchUser()

      // if redeemableTimesPerUser > 1
      //    - refresh coupon to check redeemableAvailableForUserRedeem
      //    - if redeemableAvailableForUserRedeem is false, show redeemed
      // else
      //    - show redeemed directly
      setIsCouponRedeemed(true)
      if (coupon.redeemableTimesPerUser > 1) {
        const { coupon: data } = await fetchCoupon({
          id: coupon.id,
        })
        if (data.redeemableAvailableForUserRedeem) {
          setIsCouponRedeemed(false)
        }
        setCoupon(data)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    } finally {
      setIsPopupOpen(true)
      handleFetchCouponTokens()
      setCouponTokens([])
      setIsCouponTokensReady(false)
    }
  }

  function handleRedeemCouponError(errorMessage) {
    handleModalOpen({
      title: t('ui.minicartCoupons.sorry'),
      message: errorMessage,
      buttons: [
        {
          type: 'confirm',
          text: t('ui.minicartCoupons.modal.buttons.dismiss'),
          onClick: handleModalClose,
        },
      ],
    })
  }

  function handleModalClose() {
    setIsModalOpen(false)
  }

  function handleModalOpen({
    title = '',
    message = '',
    buttons = [],
    ...others
  }) {
    setIsModalOpen(true)
    setModalContent({
      title,
      message,
      buttons,
      ...others,
    })
  }

  function handlePopupOpen(message) {
    setIsPopupOpen(true)
    setPopupMessage(message)
  }

  function handlePopupClose() {
    setIsPopupOpen(false)
    setPopupMessage('')
  }

  /**
   * when coupon marketplace is opened, fetch coupon and coupon tokens
   */
  useEffect(() => {
    if (isCouponMarketplaceOpen) {
      handleFetchCoupons()
      handleFetchCouponTokens()
    }

    return function cleanUp() {
      cancelRequest.cancelAll([
        'fetchCoupons',
      ])
    }
  }, [isCouponMarketplaceOpen])

  const state = {
    hasMoreCoupons,
    hasMoreCouponTokens,
    isCouponMarketplaceOpen,
    isCouponsReady,
    isCouponRedeemed,
    isCouponTokensReady,
    isCouponDetailOpen,
    isCouponTokenDetailOpen,
    isDetailOpen,
    isDetailReady,
    isLoading,
    isModalOpen,
    isPopupOpen,
    coupon,
    coupons,
    couponToken,
    couponTokens,
    detailType,
    modalContent,
    popupMessage,
    userCurrentPoints,
    onCouponMarketplaceOpen: handleCouponMarketplaceOpen,
    onCouponMarketplaceClose: handleCouponMarketplaceClose,
    onCouponDetailClose: handleCouponDetailClose,
    onCouponDetailOpen: handleCouponDetailOpen,
    onApplyCoupon: handleApplyCoupon,
    onApplyCouponError: handleApplyCouponError,
    onApplyCouponSuccess: handleApplyCouponSuccess,
    onPopupOpen: handlePopupOpen,
    onPopupClose: handlePopupClose,
    onRedeemCoupon: handleRedeemCoupon,
    onRedeemCouponConfirm: handleRedeemCouponConfirm,
    onRedeemCouponSuccess: handleRedeemCouponSuccess,
    onRedeemCouponError: handleRedeemCouponError,
    onModalClose: handleModalClose,
    onModalOpen: handleModalOpen,
    onFetchCoupons: handleFetchCoupons,
    onFetchCouponTokens: handleFetchCouponTokens,
    onFetchCouponsNextPage: handleFetchCouponsNextPage,
    onFetchCouponTokensNextPage: handleFetchCouponTokensNextPage,
  }

  return (
    <CouponMarketplaceContext.Provider value={state}>
      {children}
    </CouponMarketplaceContext.Provider>
  )
}
