import _ from 'lodash'

/**
 * validateCartMax
 * validate cart before checkout
 *
 * @param {*} cart
 */
export default function validateCartMax(cart) {
  const {
    cartLines,
    cartMaxNumOfSku,
    cartMaxNumOfQty,
  } = cart

  // within max skus per cart
  if (_.size(cartLines) > cartMaxNumOfSku) return 'cartMaxNumOfSku'

  // within max quantity per cart
  const cartQtyCount = _.reduce(cartLines, (result = 0, cartLine) => (
    result + cartLine.quantity
  ), 0)
  if (cartQtyCount > cartMaxNumOfQty) return 'cartMaxNumOfQty'

  // finally, the cart is valid
  return true
}
