/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  header: {
    height: 68,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.bgMediumDark}`,
    backgroundColor: theme.colors.background,
  },
  closeButton: {
    width: 68,
    height: 68,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      cursor: 'pointer',
      margin: 0,
      transform: 'scale(1.15)',
    },
  },
  titleHeaderDetail: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.38,
    letterSpacing: -0.39,
    color: theme.colors.text,
    textTransform: 'uppercase',
    margin: 0,
  },
  backButton: {
    extend: 'closeButton',
    '& img': {
      transform: 'scale(1.75)',
    },
  },
}))

export default useStyles
