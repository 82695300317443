import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../hook'
import Link from '../link';
import ProfileCard from '../profile-card';

// import ProfileCard from '../profile-card'
import useStyles from './account-menu-style';

const AccountMenuView = ({ handelOpenMemberCode }) => {
  const { user = {} } = useUser()
  const { t } = useTranslation()
  const {
    accountMenu, container, boxMenu, scrollStyle,
  } = useStyles()
  return (
    <div className={accountMenu}>
      <div className={container}>
        <ProfileCard t={t} user={user} handelOpenMemberCode={handelOpenMemberCode} />
        <div className={boxMenu}>
          <ScrollContainer
            vertical={false}
            className={scrollStyle}
          >
            <li>
              <Link
                gatsbyLink
                activeClassName="active"
                to="/account/profile/"
              >
                {t('screens.account.menu.account')}
              </Link>
            </li>
            <li>
              <Link
                gatsbyLink
                activeClassName="active"
                to="/account/addresses/"
              >
                {t('screens.account.menu.shipping')}
              </Link>
            </li>
            <li>
              <Link
                gatsbyLink
                activeClassName="active"
                to="/account/orders/"
              >
                {t('screens.account.menu.orders')}
              </Link>
            </li>
            <li>
              <Link
                gatsbyLink
                activeClassName="active"
                to="/account/my-coupons/"
              >
                {t('screens.account.menu.rewards')}
              </Link>
            </li>
            <li>
              <Link
                gatsbyLink
                activeClassName="active"
                to="/account/favourites/"
              >
                {t('screens.account.menu.favourites')}
              </Link>
            </li>
            <li>
              {'     '}
            </li>
          </ScrollContainer>

        </div>
      </div>
    </div>

  )
}

export default AccountMenuView
