import { useContext } from 'react'
import LoactionContext from './location-context'

/**
 * useLoaction
 * The custom hook which provided to external use via context
 */
const useLoaction = () => {
  const context = useContext(LoactionContext);
  return context
};

export default useLoaction;
