/**
 * getUrlFromLocation
 * get the path from location and usable in the website
 * @param {*} location
 * @param {*} includeLocale
 */
export default function getUrlFromLocation(location, includeLocale = false) {
  try {
    const { href, origin } = location
    let url = ''
    url = href.replace(`${origin}/`, '')

    if (includeLocale) {
      return url
    }

    // remove locale code at the beginning
    url = url.substr(url.indexOf('/'))
    return url
  } catch (error) {
    console.warn('getCurrentUrlFromLocation error: ', error)
    return '/'
  }
}
