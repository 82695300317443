/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Link from '../link'
import useStyles from './review-form-style'
import Rating from '../rating'
import Button from '../button'

const ReviewFormView = ({ onCreateReview, user }) => {
  const textAreaRef = useRef(null)
  const styles = useStyles()
  const { t } = useTranslation()
  const userId = _.get(user, 'id')
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const handleRatingChange = (r) => { setRating(r) }
  const handleCommentChange = (e) => {
    e.preventDefault()
    setComment(e.target.value)
  }
  const handleSubmit = () => {
    onCreateReview({ rating, comment })
  }
  const handleClear = () => {
    setComment('')
    return textAreaRef && textAreaRef.current.focus()
  }
  return (
    <div className={styles.reviewFrom}>
      <h6 className={styles.title}>{t('screens.product.reviews.review')}</h6>
      {
        userId ? (
          <>
            <p className={styles.signed}>
              {t('screens.product.reviews.signed', { user: _.get(user, 'alias') })}
            </p>
            <div className={styles.rating}>
              <h6 className={styles.title}>{t('screens.product.reviews.rate')}</h6>
              <Rating onChange={handleRatingChange} rate={rating} />
            </div>
            <div className={styles.comment}>
              <h6 className={styles.title}>{t('screens.product.reviews.think')}</h6>
              <form className={styles.form} action="">
                <textarea
                  ref={textAreaRef}
                  key="comment_textarea"
                  className={styles.textArea}
                  name="comment"
                  onChange={handleCommentChange}
                  value={comment}
                />
                <div className={styles.controls}>
                  <Button
                    dark
                    text={t('screens.product.reviews.buttons.submit')}
                    onClick={handleSubmit}
                    className={styles.button}
                    disabled={rating <= 0}
                  />
                  <Button
                    border
                    text={t('screens.product.reviews.buttons.clear')}
                    className={styles.button}
                    onClick={handleClear}
                  />
                </div>
              </form>
            </div>
          </>
        ) : (
          <p className={styles.login}>
            <Trans i18nKey="screens.product.reviews.login">
              <Link to="/login/">Login</Link>
              to write a review
            </Trans>
          </p>
        )
      }
    </div>
  )
}

export default ReviewFormView
