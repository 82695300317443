import { useContext } from 'react'
import CouponMarketplaceContext from './coupon-marketplace-context'

/**
 * useCouponMarketplace
 * The custom hook for coupon marketplace
 */
const useCouponMarketplace = () => {
  const context = useContext(CouponMarketplaceContext)
  return context
}

export default useCouponMarketplace
