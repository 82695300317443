import { useContext } from 'react'
import DineInMiniCartContext from './dine-in-mini-cart-context'

/**
 * useDineInMiniCart
 * The custom hook which provided to external use via context
 */
const useDineInMiniCart = () => {
  const context = useContext(DineInMiniCartContext)
  return context
};

export default useDineInMiniCart
