import _ from 'lodash'

/**
 * validateShipment
 * validate shipment is valid to checkout
 *
 * @param {*} shipment
 */
export default function validateShipment(shipment) {
  try {
    const { requiredFields } = shipment

    // check each required
    const valid = _.reduce(requiredFields, (result, field) => {
      const key = _.camelCase(field)
      const objKey = _.camelCase(field.replace(/_id$/, ''))
      return result && (!_.isNil(shipment[key]) || _.has(shipment, `${objKey}.id`))
    }, true)

    return valid
  } catch (error) {
    return false
  }
}
