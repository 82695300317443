/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { useLocation } from '@reach/router'
import { cancelRequest, useAuth, useFavourites } from 'react-omnitech-api'
import { useAlert, useLink } from '../../hook'
import { getUrlFromLocation } from '../../helpers'
import FavouriteButtonView from './favourite-button-view'

function FavouriteButtonController({
  colorOptionID,
  favourite,
  icon,
  iconButton,
  label,
  className,
  onChange,
}) {
  // prepare hook
  const alert = useAlert()
  const { auth } = useAuth()
  const location = useLocation()
  const { navigate } = useLink()
  const { createFavourite, deleteFavourite } = useFavourites()

  // internal state
  const [like, setLike] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!auth.userId) {
      navigate(
        '/login/',
        {
          replace: true,
          state: {
            redirectUrl: getUrlFromLocation(location),
          },
        },
      )
      return
    }

    // TODO: check it favourite, it is add or remove
    const postFunction = like ? deleteFavourite : createFavourite

    try {
      setLoading(true)
      const data = await postFunction(colorOptionID)
      if (_.isFunction(onChange)) {
        onChange(like ? { favourite: null } : data)
      }
      setLike(!like)
    } catch (err) {
      alert.show(err)
    } finally {
      setLoading(false)
    }
  }, [alert, colorOptionID, createFavourite, deleteFavourite, like])

  useEffect(() => () => {
    cancelRequest.cancelAll([
      'createFavourite',
      'deleteFavourite',
    ])
  }, [])

  useEffect(() => {
    setLike(_.has(favourite, 'id'))
  }, [favourite])

  const viewProps = {
    favourite,
    icon,
    iconButton,
    like,
    loading,
    onClick: handleClick,
    label,
    className,
  }

  return (
    <FavouriteButtonView {...viewProps} />
  )
}

export default FavouriteButtonController
