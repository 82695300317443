import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  group: {
    position: 'relative',
    overflow: 'hidden',
    userSelect: 'none',
  },
  item: ({ disabled, showHeader } = {}) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: showHeader ? '1.2rem 1.6rem 1.2rem 3.2rem' : '1.6rem',
    margin: 0,
    maxHeight: 'none',
    cursor: 'pointer',
    ...disabled && {
      opacity: 0.5,
    },
    // '&:hover': {
    //   opacity: 0.8,
    // },
  }),
  checkbox: {
    marginRight: 0,
    marginLeft: '2rem',
    minWidth: '2rem',
    width: '2rem',
    height: '2rem',
    border: `1px solid ${theme.colors.bgMediumDark}`,
    position: 'relative',
  },
  checkboxInput: {
    position: 'absolute',
    opacity: 0,
    '&:checked + $checkboxIndicator': {
      opacity: 1,
    },
    '&:indeterminate + $checkboxIndicator': {
      opacity: 1,
      height: '10%',
    },
  },
  checkboxIndicator: {
    display: 'block',
    width: '1.2rem',
    height: '1.2rem',
    backgroundColor: theme.colors.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
  },
  text: {
    width: '100%',
    margin: 0,
    lineHeight: 1.25,
  },
  separator: {
    border: 'none',
    borderBottom: `0.1rem solid ${theme.colors.bgMediumDark}`,
    padding: 0,
    margin: 0,
  },
}))

export default useStyles
