import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  popup: {
    color: theme.colors.background,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '50%',
    top: '50%',
    transform: ' translate(-50%, -50%)',
    width: 335,
    height: 100,
    borderRadius: 5,
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 10,
    '& span': {
      fontSize: '2rem',
      fontWeight: 600,
    },
  },
}))

export default useStyles
