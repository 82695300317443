/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import moment from 'moment'
import { navigate } from 'gatsby'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useBrands,
  useCart,
  useCategories,
  useCurrencies,
  useDepartments,
  useDineInCart,
  useMetaDefinitions,
  useOrderMethod,
  useStores,
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import ReactGA from 'react-ga';
import {
  AlertProvider,
  CouponMarketplaceProvider,
  DineInMiniCartProvider,
  GoogleMapsProvider,
  LocationProvider,
  LinkProvider,
  MenuProvider,
  MiniCartProvider,
  MobileMenuProvider,
  ModalProvider,
  CompareProvider,
  ThemeConfigProvider,
} from '../../hook'
import ThemeProvider from '../theme-provider'

/**
 * Application
 * Component to handle app init, preload required data from API
 * @param param0
 */
export default function Application({
  pageContext = {},
  config,
  children,
  location,
}) {
  const { locale } = pageContext
  const [ready, setReady] = useState(false)
  const [theme, setTheme] = useState({})
  // preload required data before app ready
  const { initCartForCartBadge } = useCart()
  const { initCartForCartBadge: initDineInCart } = useDineInCart()
  const { brandsReady } = useBrands()
  const { categoriesReady } = useCategories()
  const { currenciesReady } = useCurrencies()
  const { departmentsReady } = useDepartments()
  const { metaDefinitionsReady } = useMetaDefinitions()
  const { getSystemSetting, systemSettingsReady } = useSystemSettings()
  const { userReady } = useUser()
  const { orderMethod, store, setStore } = useOrderMethod()
  const { fetchStore } = useStores()
  const trackingCode = getSystemSetting('analytics.google.tracking_code.ecom')
  const trackingGTMCode = getSystemSetting('analytics.google.tag_manager_code.ecom')
  const fnbEnabled = getSystemSetting('features.fnb.enable')

  // ready when required data is loaded
  const isReady = useMemo(
    () => (
      !_.includes([
        brandsReady, categoriesReady, currenciesReady,
        departmentsReady, systemSettingsReady, userReady,
        metaDefinitionsReady,
      ], false)
    ),
    [
      brandsReady, categoriesReady, currenciesReady,
      departmentsReady, systemSettingsReady, userReady,
      metaDefinitionsReady,
    ],
  )

  const getThemeConfig = useCallback(() => {
    const themeConfig = getSystemSetting('theme.ecom.config', {})
    setTheme(themeConfig)
  }, [getSystemSetting, setTheme])

  const updateStore = async (id) => {
    try {
      const includes = [
        'meta',
        'today_open_time_slots',
      ]
      const { store: updatedStore } = await fetchStore({ id, includes })
      setStore(updatedStore)
    } catch (error) {
      // do nothing
    }
  }

  useEffect(() => {
    if (!ready) return
    // Do nothing when scanning NFC
    if (/\/dine-in\/$/.test(_.get(location, 'pathname', ''))) return
    if (_.get(orderMethod, 'commerceType') === 'dineIn') {
      // FL: Only fetch dine-in cart in dine-in mode
      if (_.get(orderMethod, 'code') === 'dineIn') {
        // fetch dineInCart
        initDineInCart({
          includes: [
            'await_confirm_order',
            'cart_shipments',
            'cart_shipments.inventory_store',
          ],
        })
          .catch((error) => {
            // Nav to dine-in and show Dine-in completed message
            navigate(`/${locale}/dine-in/`)
          })
      }
      return
    }
    // check store is update to date in local storage
    const storeOpenTimeStart = _.get(store, 'todayOpenTimeSlots.0.start')
    if (
      !_.isEmpty(storeOpenTimeStart)
      && !moment(storeOpenTimeStart || '1900-01-01').isSame(new Date(), 'day')
    ) {
      updateStore(_.get(store, 'id'))
    }

    initCartForCartBadge({
      includes: [
        'await_confirm_order',
        'cart_shipments',
        'cart_shipments.inventory_store',
        // FL: Add Includes pickup store for store name on order method button
        ...(fnbEnabled ? [
          'cart_shipments.delivery_address',
          'cart_shipments.pickup_store',
          'available_delivery_types',
        ] : []),
      ],
    })
  }, [ready])

  // get theme config from system setting after it is ready
  useEffect(() => {
    if (!systemSettingsReady) return
    getThemeConfig()
  }, [getThemeConfig, systemSettingsReady])

  // load required data required by Omnitech
  useEffect(() => {
    if (isReady && !ready) {
      setReady(isReady)
    }
  }, [isReady, ready])

  useEffect(() => {
    if (!_.isEmpty(trackingCode)) {
      ReactGA.initialize(trackingCode);
      ReactGA.pageview(window.location.pathname + window.location.search)
      ReactGA.plugin.require('ec')
    }
  }, [trackingCode, trackingGTMCode])

  return ready
    ? (
      <ThemeProvider theme={theme}>
        {/*
          // Using Link hook to support navigate with locale prepended in link
        */}
        <LinkProvider value={{ locale }}>
          <LocationProvider value={{ location, locale }}>
            <ThemeConfigProvider>
              <MenuProvider>
                <AlertProvider>
                  <ModalProvider>
                    <DineInMiniCartProvider>
                      <MiniCartProvider>
                        <MobileMenuProvider>
                          <CouponMarketplaceProvider>
                            <CompareProvider>
                              <GoogleMapsProvider apiKey={_.get(config, 'service.googleMapsApiKey')}>
                                {children}
                              </GoogleMapsProvider>
                            </CompareProvider>
                          </CouponMarketplaceProvider>
                        </MobileMenuProvider>
                      </MiniCartProvider>
                    </DineInMiniCartProvider>
                  </ModalProvider>
                </AlertProvider>
              </MenuProvider>
            </ThemeConfigProvider>
          </LocationProvider>
        </LinkProvider>
      </ThemeProvider>
    )
    : null
}
