import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './title-detail-style'
import { combineClassNames } from '../../helpers'

const TitleView = ({
  text, uppercase, margin, classTitle,
}) => {
  const { title } = useStyles({
    uppercase, margin,
  })
  return (
    <div className={combineClassNames([title, classTitle])}>{text}</div>
  )
}

TitleView.propTypes = {
  text: PropTypes.string.isRequired,
  uppercase: PropTypes.bool,
  margin: PropTypes.string,
}
TitleView.defaultProps = {
  uppercase: false,
  margin: '0 0 2rem 0',
}

export default TitleView
