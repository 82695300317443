import _ from 'lodash'

export default function urlBuilder(item, url) {
  switch (true) {
    case !_.isEmpty(item.code):
      return `${url}/${item.code}/`
    case _.isString(item):
      return `${url}/${item}/`
    default:
      return item.url
  }
}
