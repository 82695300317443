import { createContext } from 'react'

/**
 * LocationContext
 * Create Location context for custom hook
 */
const LocationContext = createContext({
  location: {},
})

export default LocationContext
