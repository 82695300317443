import React from 'react'
import useStyles from './content-group-style'
import Carousel from '../../carousel'

function TemplateBanner4Col(props) {
  const { contentGroup, onClickTrackEvent, template } = props
  const { contentGroupLines } = contentGroup
  const {
    row, col, imageBoxFourImage, carouselBanner,
  } = useStyles()
  const onClick = (label) => {
    onClickTrackEvent(template, label)
  }
  return (
    <div className={row}>
      <div className={col}>
        <Carousel
          autoplay={false}
          images={contentGroupLines}
          slidesToShowDesktop={4}
          slidesToShowMovil={1}
          imageBoxClassName={imageBoxFourImage}
          classNaneCarousel={carouselBanner}
          marginCarousel="0"
          marginCarouselDesktop="0"
          postionDots={-15}
          darkDots
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default TemplateBanner4Col
