import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    html: {
      fontSize: '10px',
      boxSizing: 'border-box',
    },
    // '*': {
    //   boxSizing: 'border-box',
    //   '&:after, &:before': {
    //     boxSizing: 'border-box',
    //   },
    // },
    img: {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      marginBottom: '1.687rem',
    },
    body: {
      // overflow: 'hidden',
      backgroundImage: `url(${theme.config.backgroundUrl})`,
      backgroundColor: '#fff',
      backgroundSize: 'cover !important',
      backgroundAttachment: 'fixed !important',
      fontSize: 10,
      fontFamily: [`${theme.typo.fontFamilyBody}`, 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
      color: theme.colors.text,
      fontSTretch: 'normal',
      lineHeight: 1.678,
      fontVariantLigatures: 'normal',
      fontVariantCaps: 'normal',
      fontVariantNumeric: 'normalize',
      fontVariantEastAsian: 'normal',
      fontFeatureSettings: 'kern, liga, clig, calt',
      '& > *': {
        fontSize: theme.typo.textBodySize,
        lineHeight: '2.84597rem',
        fontWeight: 400,
        textRendering: 'optimizeLegibility',
      },
      '& :link': {
        color: 'currentColor',
      },
      '& p, & li, & span': {
        margin: 0,
        padding: 0,
      },
      '& ul': {
        margin: 0,
        padding: 0,
      },
      '& li': {
        marginBottom: 'calc(1.687rem / 2)',
      },
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: '1.687rem',
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        textRendering: 'optimizeLegibility',
        fontWeight: 600,
      },
      '& h1': {
        fontSize: '4.8rem',
        lineHeight: '3.374rem',

      },
      '& h2': {
        fontSize: '3.6rem',
        lineHeight: '3.0366rem',

      },
      '& h3': {
        fontSize: '2.8rem',
        lineHeight: '2.6992rem',

      },
      '& h4': {
        fontSize: '2.4rem',
        lineHeight: '2.3618rem',

      },
      '& h5': {
        fontSize: '1.8rem',
        lineHeight: '2.0244rem',

      },
      '& h6': {
        fontSize: '1.6rem',
        lineHeight: '2.5305rem',
      },
    },
  },
  containerFluid: {
    padding: 0,
    width: '100%',
    overflow: 'hidden',
    // Jeri: Because function values inside function rules are not supported
    // need to set the padding top separately

    '&:before': {
      display: 'block',
      content: '""',
      backgroundColor: Color(theme.colors.overlay).alpha(0.4).string(),
      opacity: 0,
      position: 'absolute',
      zIndex: 1000,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      pointerEvents: 'none',
      visibility: 'hidden',
      transition: 'opacity 700ms ease, visibility 700ms ease',
      [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
        paddingTop: 0,
        width: 'auto',
      },
    },
  },
  contianerFluidOpen: {
    '&:before': {
      opacity: 1,
      transition: 'opacity 700ms ease, visibility 700ms ease',
      visibility: 'visible',
    },
  },
  contianerWrapper: {
    display: 'block',
    position: 'relative',
    transition: 'left 300ms',
    left: 0,
    // Fix for JSS render issue
    [`@media (max-width: ${theme.mediaQueries.tabletLandscape - 1}px)`]: {
      paddingTop: (props) => (props.headerHeight),
      left: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      paddingTop: 0,
    },
  },
  contianerWrapperOpen: {
    left: 'calc(100vw - 60px)',
    transition: 'left 300ms',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      left: 420,
    },
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      left: 0,
    },
  },
  container: {
    maxWidth: theme.mediaQueries.desktop,
    backgroundColor: '#fff',
    minHeight: '50rem',
    margin: 0,
    '& [role=button], p, a, span, img, li, area, button, input, label, select, summary, textarea': {
      outline: 'none',
      tapHighlightColor: 'rgba(0,0,0,0)',
      touchAction: 'manipulation',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      margin: [[0, 'auto']],
      padding: '0 20px',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      maxWidth: `${theme.mediaQueries.desktop + 40}px`,
      width: '100%',
    },
  },
  header: {
    position: 'fixed',
    zIndex: 12,
    top: 0,
    left: 0,
    transition: 'left 300ms',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      position: 'static',
    },
  },
  headerOpen: {
    left: 'calc(100vw - 6rem)',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      left: '42rem',
    },
  },
  headerWithBackground: {
    maxWidth: `${theme.mediaQueries.desktop + 40}px`,
  },
  footerWithBackground: {
    maxWidth: `${theme.mediaQueries.desktop + 40}px`,
  },
  dialogModal: {
    paddingBottom: '3rem',
  },
  dialogModalOverlay: {
    zIndex: 99999,
  },
  dialogContainer: {
    margin: '0 auto',
    padding: '0.8rem 0',
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '3rem',
  },
  dialogDescription: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    margin: '3rem 0 0.4rem',
    '&:first-child': {
      marginTop: 0,
    },
  },
  dialogButtons: {
    maxWidth: '33.8rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '-1.6rem',
  },
  button: {
    marginBottom: '1.6rem',
  },
  memberNumber: {
    fontSize: '1.4rem',
    lineHeight: 1.25,
    marginTop: '1rem',
  },
  // FL: react-jss Asterisk selector nested under `@global` will make styles disapper sometime
  '@global *': {
    boxSizing: 'border-box',
  },
  '@global *:after': {
    boxSizing: 'border-box',
  },
  '@global *:before': {
    boxSizing: 'border-box',
  },
}))

export default useStyles
